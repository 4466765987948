import React from "react";
import dayjs from "dayjs";
import { Typography, Box } from "@mui/material";

import BackdropLoading from "../../../../components/BackdropLoading/BackdropLoading"
import { PageTitle } from "../../../../components/page-title"
import { useBankFeedsHistoryAccounts } from "../api/bank-feeds-history-account-list"
import { useParams, useSearchParams } from "react-router-dom";
import { useBankFeedsHistoryTransactions } from "../api/bank-feeds-history-transactions-history";
import BankFeedsHistoryForm from "../components/bank-feeds-history-form";
import { useSnackbar } from "notistack";
import BankFeedsHistoryActions from "../components/bank-feeds-history-actions";
import BankFeedsHistoryGrid from "../components/bank-feeds-history-grid";
import { useMarkTransactionAsEod } from "../api/bank-feeds-history-mark-transactions-as-eod";
import { useUnmarkTransactionAsEod } from "../api/bank-feeds-history-unmark-transactions-as-eod";

export default function BankFeedsHistoryPage() {
  const { dbId, lang } = useParams();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const accountId = searchParams.get("accountId");

  // Dates states
  const [startDate, setStartDate] = React.useState<dayjs.Dayjs>(
    dayjs().subtract(1, 'day').startOf('month')
  );
  const [endDate, setEndDate] = React.useState<dayjs.Dayjs>(
    dayjs().subtract(1, 'day')
  );

  // Selected account state
  const [selectedAccount, setSelectedAccount] = React.useState<any>(null);

  // errors
  const [errors, setErrors] = React.useState<any>({
    account: "",
  });


  const { data: accounts, isLoading: isAccountsLoading, isSuccess: isAccountsSuccess } = useBankFeedsHistoryAccounts();
  const { data: transactions, isLoading: isTransactionsLoading, refetch: refetchTransactions } = useBankFeedsHistoryTransactions(accountId, selectedAccount?.BfaID, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
  const { mutate: markTransactionAsEod, isPending: isMarkTransactionAsEodLoading } = useMarkTransactionAsEod({ accountId: accountId ?? undefined, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') });  
  const { mutate: unmarkTransactionAsEod, isPending: isUnmarkTransactionAsEodLoading } = useUnmarkTransactionAsEod({ accountId: accountId ?? undefined, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') });  

  const handleAccountChange = (account: any) => {
    setSelectedAccount(account);
    setErrors({ ...errors, account: "" });
    searchParams.delete("accountId");
  }

  const handleStartDateChange = (date: dayjs.Dayjs) => {
    setStartDate(date);
  }

  const handleEndDateChange = (date: dayjs.Dayjs) => {
    setEndDate(date);
  }

  const handleShowClick = () => {
    console.log("show");

    if (!selectedAccount) {
      enqueueSnackbar("Please select an account", { variant: "error" });
      setErrors({ ...errors, account: "Please select an account" });
      return;
    }

    refetchTransactions();
  }

  const handleBackClick = () => {
    enqueueSnackbar("Back not implemented", { variant: "info" });
  }

  const handleGetCsvClick = () => {
    enqueueSnackbar("Get CSV not implemented", { variant: "info" });
  }

  const handleOpenRulesClick = () => {
    enqueueSnackbar("Open Rules not implemented", { variant: "info" });
  }

  const handleApplyRulesClick = () => {
    enqueueSnackbar("Apply Rules not implemented", { variant: "info" });
  }

  React.useEffect(() => {
    if (isAccountsSuccess && accountId) {
      const currAccount = accounts?.AccountsList.find((account: any) => account.BfaID === accountId);

      if (!currAccount) {
        enqueueSnackbar("Account not found in the account list!", { variant: "error" });
        return;
      }

      setSelectedAccount(currAccount);
    }
  }, [isAccountsSuccess, accounts]);

  console.log("accounts", accounts);
  console.log("accountId", accountId);
  console.log("transactions", transactions);
  console.log("isTransactionsLoading", isTransactionsLoading);
  console.log("selectedAccount", selectedAccount);
  console.log("startDate", startDate);
  console.log("endDate", endDate);

  const accountsOptions = accounts?.AccountsList || [];
  const transactionsColumn = transactions?.ColumnsList || [];
  const transactionsData = transactions?.DataDT || [];
  const transactionsTooltips = transactions?.TooltipsList || [];
  const transactionsActions = transactions?.ActionsList || [];

  return (
    <>
      <BackdropLoading open={isAccountsLoading || isTransactionsLoading} />
      <PageTitle title="Bank Feeds History" />
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={{ xs: 0, md: 4 }}
        boxShadow={{
          xs: "none",
          md: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        }}
        borderRadius={2}
        marginY={4}
      >
        <Typography variant="h6">Account Transactions Filter</Typography>
        <BankFeedsHistoryForm
          accountsOptions={accountsOptions}
          selectedAccount={selectedAccount}
          onAccountChange={handleAccountChange}
          startDate={startDate}
          onStartDateChange={handleStartDateChange}
          endDate={endDate}
          onEndDateChange={handleEndDateChange}
          errors={errors}
        />
        <BankFeedsHistoryActions
          onShowClick={handleShowClick}
          onBackClick={handleBackClick}
          onGetCsvClick={handleGetCsvClick}
          onOpenRulesClick={handleOpenRulesClick}
          onApplyRulesClick={handleApplyRulesClick}
        />
        <BankFeedsHistoryGrid
          columns={transactionsColumn}
          data={transactionsData}
          tooltips={transactionsTooltips}
          actions={transactionsActions}
          markTransactionAsEod={markTransactionAsEod}
          unmarkTransactionAsEod={unmarkTransactionAsEod}
        />
      </Box>
    </>
  )
}
