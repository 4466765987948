import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { axiosService } from "../../../api/axios";
import envConfig from "../../../config";
import { useLoginPopup } from "../../../context/LoginPopupContext";
import { DailyTimesheetResponse } from "../types";

export interface TimesheetDeleteResponse {
  SuccessYN: "Y" | "N";
  ErrorMessage: string;
  ActivityId: string;
}

export interface UseTimesheetDeleteProps {
  onSuccess?: (data: TimesheetDeleteResponse) => void;
  onError?: (error: string) => void;
}

export const useTimesheetDelete = ({}: UseTimesheetDeleteProps = {}) => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      if (process.env.NODE_ENV === "development") {
        return Promise.resolve({
          SuccessYN: "Y",
          ErrorMessage: "",
          ActivityId: id,
        });
      }

      const response = await axiosService.request<TimesheetDeleteResponse>({
        dbId: dbId,
        method: 'POST',
        baseURL: envConfig.apiDev1Exacc,
        endpoint: `/api/v2/${lang}/timesheet/delete-async?id=${id}&BaseHostURL=${envConfig.mainServiceUrl}`,
        reAuthenticate: reAuthenticate,
      });

      return response;
    },
    onMutate: async (id) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['timesheet-daily-list'] });

      // Snapshot the previous value
      const previousTimesheets = queryClient.getQueryData<DailyTimesheetResponse>(['timesheet-daily-list']);

      // Optimistically remove the timesheet
      if (previousTimesheets) {
        queryClient.setQueryData<DailyTimesheetResponse>(['timesheet-daily-list'], {
          ...previousTimesheets,
          ListOfDailyTimesheet: previousTimesheets.ListOfDailyTimesheet.filter(
            timesheet => timesheet.acvID !== id
          )
        });
      }

      return { previousTimesheets };
    },
    onError: (_, __, context) => {
      // Rollback to the previous value if mutation fails
      if (context?.previousTimesheets) {
        queryClient.setQueryData(['timesheet-daily-list'], context.previousTimesheets);
      }
    },

    onSettled: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['timesheet-daily-list'] });
    },
  });
};
