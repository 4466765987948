import React, { useRef } from "react";
import dayjs from "dayjs";
import {
  Autocomplete,
  TextField,
  Button,
  Grid,
  Box,
  Typography,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { PaymentMethod, PostableAccount } from "../types/invoice-payment";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { VirtualizedAutocomplete } from "../../../components/ui/autocomlete/virtualized-autocomplete";
import NumberField from "../../../components/ui/inputs/number-input";
import { IconTrash } from "../../../components/icons";
import { Dayjs } from "dayjs";
import { PaymentNote } from "./payment-note";

interface PaymentFormProps {
  clients?: any[];
  paymentMethods?: PaymentMethod[];
  postableAccounts?: PostableAccount[];
  selectedClient?: any | null;
  selectedPaymentMethod?: PaymentMethod | null;
  selectedAccount?: PostableAccount | null;
  selectedDate?: Date | null;
  amount?: number;
  amountError?: string | null;
  note?: string;
  // error handling
  clientError?: string | null;
  dateError?: string | null;
  paymentMethodError?: string | null;
  accountError?: string | null;
  totalDueAmount: number;
  // methods
  onClientChange: (client: any | null) => void;
  onPaymentMethodChange: (method: PaymentMethod | null) => void;
  onAccountChange: (account: PostableAccount | null) => void;
  onDateChange: (date: Date | null) => void;
  onAmountChange: (amount: number) => void;
  onNoteChange: (note: string) => void;
  onDetailedSearch: () => void;
  onPaidAllInFull: () => void;
  onAllocateInOrder: () => void;
  onClearAll: () => void;
  // other
  disableFeedIdRelatedFields?: boolean;
}

export const NewPaymentDetailsForm: React.FC<PaymentFormProps> = ({
  clients = [],
  paymentMethods = [],
  postableAccounts = [],
  selectedClient,
  selectedPaymentMethod,
  selectedAccount,
  selectedDate,
  amount = "",
  amountError,
  note = "",
  clientError,
  dateError,
  paymentMethodError,
  accountError,
  totalDueAmount,
  onClientChange,
  onPaymentMethodChange,
  onAccountChange,
  onDateChange,
  onAmountChange,
  onNoteChange,
  onDetailedSearch,
  onPaidAllInFull,
  onAllocateInOrder,
  onClearAll,
  disableFeedIdRelatedFields = false,
}) => {
  const clientVirtualizedRef = useRef<HTMLDivElement>(null);

  const options = clients || [];

  const client = selectedClient?.cntID
    ? clients.find((clt) => selectedClient.cntID === clt.cntID)
    : null;

  const handleDateChange = (newValue: Dayjs | null) => {
    onDateChange(newValue?.toDate() ?? null);
  };

  const clientVirtualizedWidth = clientVirtualizedRef.current?.offsetWidth;

  console.log("selectedClient", selectedClient);
  console.log("options: ", options)

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      padding={4}
      boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
      borderRadius={2}
      marginY={4}
    >
      <Typography variant="subtitle1" color={"secondary"} marginBottom={3.5}>
        New Invoice Payment
      </Typography>
      <Grid container spacing={3.5}>
        <Grid container item spacing={3.5}>
          <Grid item xs={12} md={4}>
            <Box ref={clientVirtualizedRef}>
              <VirtualizedAutocomplete
                width={"100%"}
                listWidth={clientVirtualizedWidth}
                options={options}
                value={client}
                onChange={(_, newValue) => onClientChange(newValue)}
                getOptionLabel={(option) =>
                  `${option.cntCode} ${option.cntName} | Due: ${option.DueAmt?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || "0.00"}`
                }
                label="Client"
                error={!!clientError}
                helperText={clientError ?? ""}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              variant="text"
              size="large"
              fullWidth
              onClick={onDetailedSearch}
            >
              Detailed Search
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                format="DD/MM/YYYY"
                value={selectedDate ? dayjs(selectedDate) : null}
                onChange={handleDateChange}
                disabled={disableFeedIdRelatedFields}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!dateError,
                    helperText: dateError ?? "",
                    disabled: disableFeedIdRelatedFields,
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: dateError ? "error" : "inherit",
                        },
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <NumberField
              fullWidth
              label="Amount"
              value={amount}
              onChange={onAmountChange}
              min={0}
              error={!!amountError}
              helperText={amountError ?? ""}
              disabled={disableFeedIdRelatedFields}
            />
          </Grid>
        </Grid>

        {/* Second Row */}
        <Grid container item spacing={3.5}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              // disabled={disableFeedIdRelatedFields}
              options={paymentMethods}
              value={selectedPaymentMethod}
              onChange={(_, newValue) => onPaymentMethodChange(newValue)}
              getOptionLabel={(option) => option.pmmName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Method"
                  error={!!paymentMethodError}
                  helperText={paymentMethodError ?? ""}
                // disabled={disableFeedIdRelatedFields}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              disabled={disableFeedIdRelatedFields}
              options={postableAccounts}
              value={selectedAccount}
              onChange={(_, newValue) => onAccountChange(newValue)}
              getOptionLabel={(option) => `${option.accNo} - ${option.accName}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Account"
                  error={!!accountError}
                  helperText={accountError ?? ""}
                  disabled={disableFeedIdRelatedFields}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PaymentNote value={note} onChange={onNoteChange} disabled={disableFeedIdRelatedFields} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", gap: 3.5, justifyContent: "flex-start" }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onPaidAllInFull}
            >
              Paid all in full (
              {totalDueAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              )
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onAllocateInOrder}
            >
              Allocate in Order
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={onClearAll}
              startIcon={
                <IconTrash color="inherit" paletteType="contrastText" />
              }
            >
              Clear All
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
