import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { axiosService } from "../../../../api/axios";
import envConfig from "../../../../config";
import { useLoginPopup } from "../../../../context/LoginPopupContext";
import { BANK_FEEDS_HISTORY_ACCOUNTS_EXAMPLE_RESPONSE } from "./example-response";

export interface BankFeedsHistoryAccount {
  BfaID: string;
  AccName: string;
  LastTransactionDate: string;
  LastTransactionText: string;
}

export interface BankFeedsHistoryAccountsResponse {
  SuccessYN: "Y" | "N";
  ErrorMessage: string | null;
  AccountsList: BankFeedsHistoryAccount[];
}

export const useBankFeedsHistoryAccounts = () => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();

  return useQuery({
    queryKey: ['bank-feeds-history-accounts', dbId, lang],
    staleTime: 1000 * 60,
    queryFn: async () => {

      if (process.env.NODE_ENV === "development") {
        return BANK_FEEDS_HISTORY_ACCOUNTS_EXAMPLE_RESPONSE;
      }

      const searchParams = new URLSearchParams();
      searchParams.append("BaseHostURL", envConfig.mainServiceUrl);

      const response = await axiosService.request<BankFeedsHistoryAccountsResponse>({
        dbId: dbId,
        method: 'POST',
        baseURL: envConfig.apiDev2,
        endpoint: `/api/${lang}/bank-feeds/get-accounts-list-v2?${searchParams.toString()}`,
        reAuthenticate: reAuthenticate,
      });

      return response;
    }
  });
};
