import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { axiosService } from "../../../../api/axios";
import envConfig from "../../../../config";
import { useLoginPopup } from "../../../../context/LoginPopupContext";
import { BankFeedsHistoryTransactionsResponse } from "../types";
import { getBankFeedsHistoryQueryKey } from "./bank-feeds-history-transactions-history";

export interface MarkTransactionAsEodPayload {
  FeedID: string;
}

export interface MarkTransactionAsEodResponse {
  // TODO: Add proper response type
  [key: string]: any;
}

export const useMarkTransactionAsEod = ({
  accountId,
  startDate,
  endDate,
}: {
  accountId?: string;
  startDate?: string;
  endDate?: string;
}) => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: MarkTransactionAsEodPayload) => {
      console.log("payload: ", payload);

      if (process.env.NODE_ENV === "development") {
        return Promise.resolve({
          SuccessYN: "Y",
          ErrorMessage: "",
        });
      }

      const response = await axiosService.request<MarkTransactionAsEodResponse>({
        dbId: dbId,
        method: 'POST',
        baseURL: envConfig.apiDev2,
        endpoint: `/api/${lang}/bank-feeds/mark-transaction-as-eod`,
        data: payload,
        reAuthenticate: reAuthenticate,
      });

      return response;
    },
    onMutate: async (payload) => {
      console.log("payload: ", payload);

      await queryClient.cancelQueries({ queryKey: getBankFeedsHistoryQueryKey(dbId, lang, accountId, startDate, endDate) });

      const prevBankFeedHistory = queryClient.getQueryData<BankFeedsHistoryTransactionsResponse>(getBankFeedsHistoryQueryKey(dbId, lang, accountId, startDate, endDate));

      if (prevBankFeedHistory) {
        queryClient.setQueryData<BankFeedsHistoryTransactionsResponse>(getBankFeedsHistoryQueryKey(dbId, lang, accountId, startDate, endDate), {
          ...prevBankFeedHistory,
          DataDT: [
            ...prevBankFeedHistory.DataDT.map((transactions) => {
              if (transactions.bftID === payload.FeedID) {
                return {
                  ...transactions,
                  EOD: "Yes",
                }
              }
              return transactions;
            })
          ]
        });
      }

      return { prevBankFeedHistory };
    },
    onError: (_, __, context) => {
      if (context?.prevBankFeedHistory) {
        queryClient.setQueryData<BankFeedsHistoryTransactionsResponse>(getBankFeedsHistoryQueryKey(dbId, lang, accountId, startDate, endDate), context.prevBankFeedHistory);
      }
    },
    onSettled: () => {
      // Invalidate relevant queries after mutation
      queryClient.invalidateQueries({ queryKey: getBankFeedsHistoryQueryKey(dbId, lang, accountId, startDate, endDate) });
    },
  });
};
