import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useLoginPopup } from "../../../../context/LoginPopupContext";
import { InitialData } from "../types";
import { axiosService } from "../../../../api/axios";
import envConfig from "../../../../config";

export const EXAMPLE_INITIAL_DATA: InitialData = {
    SuccessYN: "Y",
    ErrorMessage: null,
    AccountsList: [
      {
        ID: "C2",
        Label: "CSV Import: 1010 Savings Account (194 to do",
        AccNo: 1010,
      },
      {
        ID: "C1",
        Label: "CSV Import: 1030 Clearing account (194 to do",
        AccNo: 1030,
      },
    ],
    BankFeedAccNotAssigned: ["019d805e-dfb6-481f-a021-16e86840ff47"],
  };

export const useProcessTransactionsInitialData = () => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();

  return useQuery({
    queryKey: ['process-transactions-initial-data', dbId, lang],
    queryFn: async () => {
      if (process.env.NODE_ENV === 'development') {
        return Promise.resolve(EXAMPLE_INITIAL_DATA);
      }

      const response = await axiosService.request<InitialData>({
        dbId: dbId,
        method: 'GET',
        baseURL: envConfig.apiDev2,
        endpoint: `/api/${lang}/bank-feeds/get-initial-data-for-process-tr`,
        params: {
          BaseHostURL: envConfig.mainServiceUrl
        },
        reAuthenticate: reAuthenticate,
      });

      return response;
    }
  });
};
