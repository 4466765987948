// BusinessDetailsForm.js

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Tabs,
  Tab,
  TextField,

  Typography,
  Avatar,
  Container,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams, useSearchParams } from "react-router-dom";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { FormTextInput } from "./components/TextInput";
import { FormDropdown } from "./components/Dropdown";
import { FormRadio } from "./components/Radio";
import {
  EXAMPLE_RESPONSE_BUSINESS_DETAILS,
} from "../../features/business-details/api/get-business-details";
import envConfig from "../../config";
import useApi from "../../hooks/useApi";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormattedTextInput } from "./components/FormattedTextInput";
import { useBusinessActivities } from "../../features/business-details/api/get-business-activities";
import { useStripeDisableAccount } from "../../features/stripe/api/stripe-disable-account";
import { useStripeEnableAccount } from "../../features/stripe/api/stripe-enable-account";
import { useStripeCreateAccount } from "../../features/stripe/api/stripe-activate-account";
import { useNavigateInternal } from "../../hooks/use-navigate-internal";

export function isValidABN(abn) {
  // Remove any spaces or non-digit characters
  abn = abn.replace(/[^0-9]/g, "");

  // Check if the ABN is 11 digits long
  if (abn.length !== 11) {
    return false;
  }

  // Weights for each digit
  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

  // Subtract 1 from the first digit
  let sum = 0;
  for (let i = 0; i < 11; i++) {
    let digit = parseInt(abn[i]);
    if (i === 0) {
      digit -= 1;
    }
    sum += digit * weights[i];
  }

  // Check if the sum is divisible by 89
  return sum % 89 === 0;
}

const LARGE_CUSTOM_ENTRY_WIDTH = 480;

export const BusinessDetailsForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { businessId } = useParams();
  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab');

  const { apiCall } = useApi();

  const [activeTab, setActiveTab] = useState(() => {
    if (tabParam === 'stripe') return 3;
    if (tabParam === 'banking') return 2;
    if (tabParam === 'contact') return 1;
    return 0;
  });
  const [formData, setFormData] = useState({});
  const [validation, setValidation] = useState({});
  const [logoData, setLogoData] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data, isLoading, isError, isSuccess, refetch } = useQuery({
    queryKey: ["business-details"],
    queryFn:
      process.env.NODE_ENV === "development"
        ? () => Promise.resolve(EXAMPLE_RESPONSE_BUSINESS_DETAILS)
        : () =>
          apiCall({
            url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/business-details/get?BaseHostURL=${envConfig.mainServiceUrl}`,
          }),
    refetchOnWindowFocus: false,
  });

  console.log("data: ", data);

  useEffect(() => {
    if (data?.Details && isSuccess) {
      const meeStripePmtMethod_pmmID = data?.ListOfPaymentMethods.find(
        (pm) => pm.pmmID === data?.Details.meeStripePmtMethod_pmmID,
      );
      const meeStripePmtAccountNo_accNo = data?.ListOfCurrentAssetAccounts.find(
        (ea) => ea.accNo === data?.Details.meeStripePmtAccountNo_accNo,
      );

      setFormData({
        ...data.Details,
        meeStripePmtMethod_pmmID: meeStripePmtMethod_pmmID,
        meeStripePmtAccountNo_accNo: meeStripePmtAccountNo_accNo,
      });
      setLogoData(data.Details.meeLogo?.meeImageLogoBase64);
    }
  }, [isSuccess]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleStateChange = (key, value) => {
    console.log("key: ", key, " value: ", value);
    setValidation((prevState) => ({
      ...prevState,
      [key]: "",
    }));
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    handleStateChange(name, value);
  };

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Convert file to base64
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogoData(reader.result);
        setFormData((prevState) => ({
          ...prevState,
          meeLogo: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoRemove = async () => {
    setLogoData(null);
    setFormData((prevState) => ({
      ...prevState,
      meeLogo: null,
    }));

    await apiCall({
      url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/business-details/remove-logo?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      onSuccess: () => {
        enqueueSnackbar("Logo removed successfully", { variant: "success" });
        refetch();
      },
      onError: (e) => {
        console.error(e);
        enqueueSnackbar("Something went wrong!", { variant: "error" });
      },
    });
  };

  const validateForm = () => {
    const newErrors = {};

    const isNumeric = (value) => {
      return /^\d+$/.test(value);
    };

    const isValidPostcode = (postcode) => {
      const pc = parseInt(postcode, 10);
      return pc >= 200 && pc <= 9999;
    };

    // STP Validation
    if (formData.meeTaxCode && formData.meeTaxCode.trim() !== "") {
      if (!isValidABN(formData.meeTaxCode)) {
        newErrors.meeTaxCode = "ABN is incorrect";
      }
    }

    if (formData.meeBranchCode && formData.meeBranchCode.trim() !== "") {
      if (!isNumeric(formData.meeBranchCode) || Number(formData.meeBranchCode) <= 0) {
        newErrors.meeBranchCode = "ABN Branch Code must be numeric and greater than 0";
      }
    }

    if (!formData.meeName || formData.meeName.trim() === "") {
      newErrors.meeName = "Payer name is missing";
    }

    // Address validation - only if any address field is filled
    if (formData.meeAddress1 || formData.meeSuburb || formData.meeState?.value || formData.meePostCode) {
      if (formData.meeAddress1 && formData.meeAddress1.length < 2) {
        newErrors.meeAddress1 = "Enter Street address of the business.";
      }
      if (formData.meeSuburb && formData.meeSuburb.length < 2) {
        newErrors.meeSuburb = "Enter Suburb in address of the business.";
      }
      if (formData.meeState?.value && formData.meeState.value.length < 2) {
        const validStates = ["AAT", "ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];
        if (!validStates.includes(formData.meeState.value)) {
          newErrors.meeState = "Enter correct State in address of the business.";
        }
      }
      if (formData.meePostCode && (!isNumeric(formData.meePostCode) || !isValidPostcode(formData.meePostCode))) {
        newErrors.meePostCode = "Payer Address postcode is incorrect";
      }
    }

    // ABA Details Validation
    if (!formData.meeBankCode || formData.meeBankCode.length < 6) {
      newErrors.meeBankCode = "BSB is missing or incorrect";
    } else {
      const bsb = formData.meeBankCode.replace("-", "");
      if (bsb.length !== 6 || !isNumeric(bsb)) {
        newErrors.meeBankCode = "BSB is incorrect.";
      }
    }

    if (
      !formData.meeBankAcct ||
      formData.meeBankAcct.length < 6 ||
      formData.meeBankAcct.length > 9 ||
      !isNumeric(formData.meeBankAcct)
    ) {
      newErrors.meeBankAcct = "Bank Account number is incorrect";
    }

    if (!formData.meeBankAcName || formData.meeBankAcName.length < 2) {
      newErrors.meeBankAcName = "Bank Account name is incorrect";
    }

    if (!formData.meeBankRef || formData.meeBankRef.length !== 3) {
      newErrors.meeBankRef =
        "Bank Account - bank code is incorrect - must be 3 characters.";
    }

    if (
      !formData.meeBankUserID ||
      formData.meeBankUserID.length !== 6 ||
      !isNumeric(formData.meeBankUserID)
    ) {
      newErrors.meeBankUserID = "Bank Account - bank User ID is incorrect.";
    }

    if (!formData.meeActivity_xbaCode || formData.meeActivity_xbaCode === "n/a") {
      newErrors.meeActivity_xbaCode = "Activity Type is required";
    }

    setValidation(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // const mutation = useMutation({
  //   mutationFn: (data) =>
  //     apiCall({
  //       url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/business-details/save?BaseHostURL=${envConfig.mainServiceUrl}`,
  //       method: "POST",
  //       body: data,
  //     }),
  //   onSuccess: () => {
  //     enqueueSnackbar("Form submitted successfully!", {
  //       variant: "success",
  //     });
  //   },
  //   onError: (error) => {
  //     enqueueSnackbar(error || "Error saving data", {
  //       variant: "error",
  //     });
  //   },
  // });

  const handleOnSubmit = async () => {
    if (validateForm()) {
      const body = {
        meeID: formData.meeID,
        meeName: formData.meeName,
        meeRef: formData.meeRef,
        meeTaxCode: formData.meeTaxCode,
        meePh1No: formData.meePh1No,
        meePh1Desc: formData.meePh1Desc,
        meePh2No: formData.meePh2No,
        meePh2Desc: formData.meePh2Desc,
        meePh3No: formData.meePh3No,
        meePh3Desc: formData.meePh3Desc,
        meeEmail: formData.meeEmail,
        meeAddress1: formData.meeAddress1,
        meeAddress2: formData.meeAddress2,
        meeSuburb: formData.meeSuburb,
        meeState: formData.meeState?.value || "",
        meePostCode: formData.meePostCode,
        meeAddressCountry: formData.meeAddressCountry,
        meeBankCode: formData.meeBankCode,
        meeBankAcct: formData.meeBankAcct,
        meeBankAcName: formData.meeBankAcName,
        meeBankBIC: formData.meeBankBIC,
        meeBankRef: formData.meeBankRef,
        meeBankCountry: formData.meeBankCountry,
        meeContactName: formData.meeContactName,
        meeBranchCode: formData.meeBranchCode,
        meeBankUserID: formData.meeBankUserID,
        meePayPalBusinessID: formData.meePayPalBusinessID,
        meeCompanyWebsite: formData.meeCompanyWebsite,
        meeDebug: formData.meeDebug,
        meeEntityType_cnfFrmID: formData.meeEntityType_cnfFrmID,
        meeRegisteredVAT: formData.meeRegisteredVAT,
        meeActivity_xbaCode: formData.meeActivity_xbaCode,
        meeAbaBalancingYN: formData.meeAbaBalancingYN,
        meePayID: formData.meePayID,
        meeStripeCharge: formData.meeStripeCharge,
        meeStripeAccId: formData.meeStripeAccId || "",
        meeStripePmtMethod_pmmID: formData.meeStripePmtMethod_pmmID.pmmID || "",
        meeStripePmtAccountNo_accNo: formData.meeStripePmtAccountNo_accNo.accNo || "",
        meeStripeStatus: formData.meeStripeStatus || "",
      };

      setIsSubmitting(true);

      // if (hadLogoBefore) {
      //   await apiCall({
      //     url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/business-details/remove-logo?BaseHostURL=${envConfig.mainServiceUrl}`,
      //     method: "POST",
      //     onSuccess: () => {
      //       enqueueSnackbar("Logo removed successfully", { variant: "success" });
      //       refetch();
      //     },
      //     onError: (e) => {
      //       console.error(e);
      //       enqueueSnackbar("Something went wrong!", { variant: "error" });
      //     },
      //   });
      // }

      apiCall({
        url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/business-details/save?BaseHostURL=${envConfig.mainServiceUrl}`,
        method: "POST",
        body: body,
        onSuccess: () => {
          enqueueSnackbar("Form submitted successfully!", {
            variant: "success",
          });
          setIsSubmitting(false);
        },
        onError: (error) => {
          enqueueSnackbar(error || "Error saving data", {
            variant: "error",
          });
          setIsSubmitting(false);
        },
      });
    } else {
      enqueueSnackbar("Please fix validation errors", { variant: "warning" });
    }
  };

  console.log("FormData: ", formData);

  const isFieldValid = (field) => {
    return !validation[field];
  };

  const errorMessage = (field) => {
    return validation[field] ? validation[field] : "";
  };

  const hadLogoBefore = !!data?.Details?.meeLogo;

  if (isLoading) {
    return <BackdropLoading open={isLoading} />;
  }

  if (isError) {
    return (
      <Container>
        <Box
          p={4}
          borderRadius={"8px"}
          boxShadow={"0px 0px 10px rgba(69, 90, 100, 0.1)"}
          sx={{ minHeight: "90vh" }}
        >
          <Box
            bgcolor={"#FF9592"}
            color={"white"}
            padding={2}
            border={"2px solid #E5484D"}
            borderRadius={1}
          >
            <Typography fontSize={16} fontWeight={"medium"} component={"h4"}>
              Something went wrong.
            </Typography>
            <Typography fontSize={14} fontWeight={"medium"} component={"h4"}>
              {/* {error?.message} */}
            </Typography>
          </Box>
        </Box>
      </Container>
    );
  }

  return (
    <Box borderRadius={"8px"}>
      <Box>
        <BackdropLoading open={isSubmitting} />
        <Box mt={2} mb={4}>
          <Typography variant="h4" component="h1">
            {data?.Details?.meeName ? data.Details.meeName : "New Business"}
          </Typography>
        </Box>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Main Info" />
          <Tab label="Contact" />
          <Tab label="Banking" />
          <Tab label="Online Payments" />
        </Tabs>
        {activeTab === 0 && (
          <MainInfoTab
            formData={formData}
            hadLogoBefore={hadLogoBefore}
            handleStateChange={handleStateChange}
            handleRadioChange={handleRadioChange}
            isFieldValid={isFieldValid}
            errorMessage={errorMessage}
            logoData={logoData}
            handleLogoChange={handleLogoChange}
            handleLogoRemove={handleLogoRemove}
          />
        )}
        {activeTab === 1 && (
          <ContactTab
            formData={formData}
            handleStateChange={handleStateChange}
            isFieldValid={isFieldValid}
            errorMessage={errorMessage}
          />
        )}
        {activeTab === 2 && (
          <BankingTab
            formData={formData}
            handleStateChange={handleStateChange}
            handleRadioChange={handleRadioChange}
            isFieldValid={isFieldValid}
            errorMessage={errorMessage}
          />
        )}
        {activeTab === 3 && (
          <StripeTab
            formData={formData}
            listOfPaymentMethods={data?.ListOfPaymentMethods}
            listOfCurrentAssetAccounts={data?.ListOfCurrentAssetAccounts}
            listOfExpensesAccounts={data?.ListOfExpensesAccounts}
            handleInputChange={handleStateChange}
            handleRadioChange={handleRadioChange}
            isFieldValid={isFieldValid}
            errorMessage={errorMessage}
            refetchBusinessDetails={refetch}
          />
        )}
        <Box my={8}>
          <Button
            type="button"
            size={"large"}
            variant={"outlined"}
            onClick={handleOnSubmit}
            sx={{
              // borderColor: mutation.isError ? "#FF7479" : "#00AB55",
              // color: mutation.isError ? "#FF7479" : "#00AB55",
              ":hover": {
                borderColor: "#00AB55",
                color: "#00AB55",
              },
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const MainInfoTab = ({
  formData,
  handleStateChange,
  handleRadioChange,
  isFieldValid,
  errorMessage,
  logoData,
  handleLogoChange,
  handleLogoRemove,
  hadLogoBefore,
}) => {
  const { activities, isLoading: isLoadingActivities } =
    useBusinessActivities();

  const getLegalForm = (id) => {
    const forms = {
      1: "Sole Trader",
      2: "Partnership",
      3: "Company",
      4: "SMSF",
      5: "Trust",
      6: "Other type",
    };
    return forms[id] || "Unknown";
  };

  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography fontSize={20} fontWeight={"medium"} component={"h2"}>
        Main Info
      </Typography>
      <Typography>
        Used on invoices, payslips and other reports, including reports you send
        to ATO
      </Typography>
      <Box mt={6} display="flex" flexDirection="column" gap={4}>
        <FormTextInput
          label="Company Name (Legal Name)"
          valueKey="meeName"
          value={formData.meeName}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meeName")}
          errorMessage={errorMessage("meeName")}
          required
        />
        <FormTextInput
          label="Legal Form"
          value={getLegalForm(formData.meeEntityType_cnfFrmID)}
          valueKey="meeEntityType_cnfFrmID"
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          disabled
        />
        <Box display={"flex"} alignItems="center">
          <FormTextInput
            label="Short Code"
            valueKey="meeRef"
            value={formData.meeRef}
            onChange={handleStateChange}
            maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
            tooltip="Used in browser page title and various cumulative reports."
            isFieldValid={isFieldValid("meeRef")}
            errorMessage={errorMessage("meeRef")}
          />
          <Tooltip
            title={"Used in browser page title and various cumulative reports."}
            arrow
            placement="top"
          >
            <HelpOutlineIcon style={{ marginLeft: "8px", cursor: "pointer" }} />
          </Tooltip>
        </Box>
        <FormTextInput
          label="ABN"
          valueKey="meeTaxCode"
          value={formData.meeTaxCode}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meeTaxCode")}
          errorMessage={errorMessage("meeTaxCode")}
        />
        <FormTextInput
          label="ABN Branch Code"
          valueKey="meeBranchCode"
          value={formData.meeBranchCode}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meeBranchCode")}
          errorMessage={errorMessage("meeBranchCode")}
        />
        <Box display={"flex"} alignItems="center" gap={4}>
          <Typography>Registered for GST</Typography>
          <FormRadio
            label="Registered for GST"
            options={[
              { value: "Y", label: "Yes" },
              { value: "N", label: "No" },
            ]}
            value={formData.meeRegisteredVAT || "N"}
            valueKey="meeRegisteredVAT"
            onChange={handleStateChange}
            optionLabel="label"
            optionAccessorKey="value"
          />
        </Box>
        <Autocomplete
          options={activities}
          getOptionLabel={(option) =>
            typeof option === 'string'
              ? option
              : `${option.Display}`
          }
          value={activities.find(a => `${a.Value}` === `${formData.meeActivity_xbaCode}`) || null}
          onChange={(event, newValue) => {
            handleStateChange('meeActivity_xbaCode', newValue?.Value || '');
          }}
          loading={isLoadingActivities}
          renderInput={(params) => (
            <TextField
              size="large"
              {...params}
              label="Activity Type"
              required
              error={!isFieldValid("meeActivity_xbaCode")}
              helperText={errorMessage("meeActivity_xbaCode")}
              sx={{ maxWidth: LARGE_CUSTOM_ENTRY_WIDTH }}
            />
          )}
        />
        {/* Logo */}
        <Box mt={2}>
          {logoData ? (
            <Box display="flex" alignItems="center" gap={2}>
              <Avatar
                src={logoData}
                alt="Logo"
                sx={{ width: 100, height: 100, mr: 2 }}
              />
              <Button variant="secondary" component="label">
                Change Logo
                <input type="file" hidden onChange={handleLogoChange} />
              </Button>
              {hadLogoBefore && (
                <Button
                  variant="secondary"
                  color="secondary"
                  onClick={handleLogoRemove}
                >
                  Remove Previous Logo
                </Button>
              )}
            </Box>
          ) : (
            <Button variant="secondary" component="label">
              Add Logo
              <input type="file" hidden onChange={handleLogoChange} />
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const ContactTab = ({
  formData,
  handleStateChange,
  isFieldValid,
  errorMessage,
}) => {
  const states = ["AAT", "ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography fontSize={20} fontWeight={"medium"} component={"h2"}>
        Contact
      </Typography>
      <Typography>
        Used on invoices, payslips and other reports, including reports you send
        to ATO
      </Typography>
      <Box mt={6} display="flex" flexDirection="column" gap={4}>
        <FormTextInput
          label="Contact Person"
          valueKey="meeContactName"
          value={formData.meeContactName}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
        />
        <FormTextInput
          label="Phone"
          valueKey="meePh1No"
          value={formData.meePh1No}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meePh1No")}
          errorMessage={errorMessage("meePh1No")}
        />
        <FormTextInput
          label="E-mail"
          valueKey="meeEmail"
          value={formData.meeEmail}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
        />
        <FormTextInput
          label="Website"
          valueKey="meeCompanyWebsite"
          value={formData.meeCompanyWebsite}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
        />
        <FormTextInput
          label="Street - Line 1"
          valueKey="meeAddress1"
          value={formData.meeAddress1}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meeAddress1")}
          errorMessage={errorMessage("meeAddress1")}
        />
        <FormTextInput
          label="Street - Line 2"
          valueKey="meeAddress2"
          value={formData.meeAddress2}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
        />
        <FormTextInput
          label="Suburb"
          valueKey="meeSuburb"
          value={formData.meeSuburb}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meeSuburb")}
          errorMessage={errorMessage("meeSuburb")}
        />
        <FormDropdown
          label="State"
          valueKey="meeState"
          value={formData.meeState}
          onChange={handleStateChange}
          options={states.map((state) => ({ value: state, label: state }))}
          optionLabel="label"
          optionAccessorKey="value"
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meeState")}
          errorMessage={errorMessage("meeState")}
        />
        <FormTextInput
          label="Post Code"
          valueKey="meePostCode"
          value={formData.meePostCode}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meePostCode")}
          errorMessage={errorMessage("meePostCode")}
        />
      </Box>
    </Box>
  );
};

const BankingTab = ({
  formData,
  handleStateChange,
  handleRadioChange,
  isFieldValid,
  errorMessage,
}) => {
  const { apiCall } = useApi();

  const BSBcode = formData.meeBankCode;
  const bankAbaSettings = useMutation({
    mutationKey: ["bankAbaSettings", { BSBcode }],
    mutationFn:
      process.env.NODE_ENV === "development"
        ? () =>
          Promise.resolve({
            SuccessYN: "Y",
            ErrorMessage: null,
            BankRefCode: BSBcode,
            UserID: "",
            BalancingYN: "",
          })
        : () =>
          apiCall({
            url: `https://${envConfig.apiDev2}/api/en-au/front-end/get-bank-aba-settings?Bsb=${BSBcode}&BaseHostURL=${envConfig.mainServiceUrl}`,
          }),
    // enabled: !!BSBcode,
    refetchOnWindowFocus: false,
  });

  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography fontSize={20} fontWeight={"medium"} component={"h2"}>
        Banking
      </Typography>
      <Typography>
        Used on invoices and to prepare ABA files (Payroll and Bills)
      </Typography>
      <Box mt={6} display="flex" flexDirection="column" gap={4}>
        <Box display={"flex"} alignItems={"center"}>
          <FormTextInput
            label="Top Memo"
            valueKey="meePayID"
            value={formData.meePayID}
            onChange={handleStateChange}
            maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          />
          <Tooltip
            title={"Preferred payment option, such as PayID."}
            arrow
            placement="top"
          >
            <HelpOutlineIcon style={{ marginLeft: "8px", cursor: "pointer" }} />
          </Tooltip>
        </Box>
        <FormTextInput
          label="Account Name"
          valueKey="meeBankAcName"
          value={formData.meeBankAcName}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meeBankAcName")}
          errorMessage={errorMessage("meeBankAcName")}
        />
        <Box display={"flex"} flexDirection="column">
          <FormattedTextInput
            label="BSB"
            valueKey="meeBankCode"
            value={BSBcode}
            onChange={handleStateChange}
            onBlur={(key, value) => bankAbaSettings.mutate({ BSBcode: value })}
            maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
            isFieldValid={isFieldValid("meeBankCode")}
            errorMessage={errorMessage("meeBankCode")}
            InputProps={{
              inputComponent: BsbFormatCustom,
            }}
          />
          {bankAbaSettings?.isLoading ? (
            <Typography>Loading...</Typography>
          ) : (
            bankAbaSettings.isSuccess && (
              <Box mt={2}>
                <Typography>Fetched details: </Typography>
                <Typography>
                  Bank Reference Code: {bankAbaSettings.data?.BankRefCode}
                </Typography>
                <Typography>
                  User ID: {bankAbaSettings?.data?.UserID}
                </Typography>
                <Typography>
                  Produce balancing ABA file:{" "}
                  {bankAbaSettings?.data?.meeAbaBalancingYN}
                </Typography>
              </Box>
            )
          )}
        </Box>

        <FormTextInput
          label="Account Number"
          valueKey="meeBankAcct"
          value={formData.meeBankAcct}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meeBankAcct")}
          errorMessage={errorMessage("meeBankAcct")}
        />
        <Box display={"flex"} alignItems={"center"}>
          <FormTextInput
            label="Additional Memo"
            valueKey="meeBankBIC"
            value={formData.meeBankBIC}
            onChange={handleStateChange}
            maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
            tooltip="Displayed on invoices and account statements, e.g. BIC/SWIFT code. You can use basic HTML tags to make the text bold or break into lines."
          />
          <Tooltip
            title={
              "Displayed on invoices and account statements, e.g. BIC/SWIFT code."
            }
            arrow
            placement="top"
          >
            <HelpOutlineIcon style={{ marginLeft: "8px", cursor: "pointer" }} />
          </Tooltip>
        </Box>
        <FormTextInput
          label="Bank Reference Code"
          valueKey="meeBankRef"
          value={formData.meeBankRef}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meeBankRef")}
          errorMessage={errorMessage("meeBankRef")}
        />
        <FormTextInput
          label="User ID"
          valueKey="meeBankUserID"
          value={formData.meeBankUserID}
          onChange={handleStateChange}
          maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
          isFieldValid={isFieldValid("meeBankUserID")}
          errorMessage={errorMessage("meeBankUserID")}
        />
        <Box display={"flex"} alignItems="center" gap={4}>
          <Typography>Produce balancing ABA file</Typography>
          <FormRadio
            label="Produce balancing ABA file"
            options={[
              { value: "Y", label: "Yes" },
              { value: "N", label: "No" },
            ]}
            value={formData.meeAbaBalancingYN || "N"}
            valueKey="meeAbaBalancingYN"
            onChange={handleStateChange}
            optionLabel="label"
            optionAccessorKey="value"
          />
        </Box>
      </Box>
    </Box>
  );
};

const BsbFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <input
      {...other}
      ref={inputRef}
      onChange={(event) => {
        const value = event.target.value.replace(/[^\d-]/g, "");
        onChange(value);
      }}
    />
  );
};

const StripeTab = ({
  formData,
  listOfPaymentMethods = [],
  listOfExpensesAccounts = [],
  listOfCurrentAssetAccounts = [],
  handleInputChange,
  isFieldValid,
  errorMessage,
  refetchBusinessDetails,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { dbId, lang, navigateExternal } = useNavigateInternal();

  const { isLoading: isLoadingDeactive, disableAccount } = useStripeDisableAccount({
    onSuccessCallback: React.useCallback(() => {
      refetchBusinessDetails();
    }, [refetchBusinessDetails]),
    onErrorCallback: React.useCallback(() => {
    }, [])
  });

  const { isLoading: isLoadingEnable, enableAccount } = useStripeEnableAccount({
    onSuccessCallback: React.useCallback(() => {
      refetchBusinessDetails();
    }, [refetchBusinessDetails]),
  });

  const { isLoading: isLoadingCreate, createAccount } = useStripeCreateAccount({
    onSuccess: React.useCallback((response) => {
      if (response.StripeAccountUrl) {
        navigateExternal(response.StripeAccountUrl);
      } else {
        enqueueSnackbar("Invalid Stripe account URL received", { variant: "error" });
      }
    }, [enqueueSnackbar]),
  });

  const stripeStatus =
    formData.meeStripeStatus === "A" || formData.meeStripeStatus === "Y"
      ? "Active"
      : "Inactive";

  const isStripeAccountCreated = formData.meeStripeAccId !== null && formData.meeStripeAccId !== "";
  const isStripeActive = stripeStatus === "Active";
  const isDisabled = isLoadingDeactive || isLoadingEnable || isLoadingCreate;

  console.log("formData: ", formData);

  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography fontSize={20} fontWeight={"medium"} component={"h2"}>
        Stripe
      </Typography>
      <Typography>
        Connect to online merchant so your customers can pay you online using a
        credit card.
      </Typography>

      <Box mt={6} display="flex" flexDirection="column" gap={4}>
        {isStripeActive && (
          <>
            <Box display={"flex"} alignItems="center" gap={4}>
              <Typography>Payments will be recorded in: </Typography>
              <FormDropdown
                label="Payments will be recorded as"
                valueKey="meeStripePmtAccountNo_accNo"
                value={formData.meeStripePmtAccountNo_accNo}
                onChange={handleInputChange}
                options={listOfCurrentAssetAccounts || []}
                optionLabel="accName"
                maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
              />
            </Box>
            <Box display={"flex"} alignItems="center" gap={4}>
              <Typography>Merchant Fees will be recorded in: </Typography>
              <FormDropdown
                label="Merchant Fees will be recorded in"
                valueKey="meeStripePmtAccountNo_accNo"
                value={formData.meeStripePmtAccountNo_accNo}
                onChange={handleInputChange}
                options={listOfExpensesAccounts || []}
                optionLabel="accName"
                maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
              />
            </Box>
            <Box display={"flex"} alignItems="center" gap={4}>
              <Typography>Payments will be recorded as: </Typography>
              <FormDropdown
                label="Payments will be recorded as"
                valueKey="meeStripePmtMethod_pmmID"
                value={formData.meeStripePmtMethod_pmmID}
                onChange={handleInputChange}
                options={listOfPaymentMethods || []}
                optionLabel="pmmName"
                maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
              />
            </Box>
            {/* <FormTextInput
              label="Mark payment method as"
              valueKey="meeStripePmtMethod_pmmID"
              value={formData.meeStripePmtMethod_pmmID}
              onChange={handleInputChange}
              maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
              tooltip="Default: ID=7 (if model is null or empty or 0)"
            />
            <FormTextInput
              label="Clear Stripe payments through account"
              valueKey="meeStripePmtAccountNo_accNo"
              value={formData.meeStripePmtAccountNo_accNo}
              onChange={handleInputChange}
              maxWidth={LARGE_CUSTOM_ENTRY_WIDTH}
              tooltip="List of accounts, Postable=Y; default: 1030 (if model is null or empty or 0)"
            /> */}
          </>
        )}
        {isStripeAccountCreated && !isStripeActive && <Typography>Stripe account with id {formData.meeStripeAccId} is created but inactive.</Typography>}
        <Box display="flex" gap={2}>
          {isStripeAccountCreated && !isStripeActive && (
            <Button variant="contained" color="primary" onClick={enableAccount} disabled={isDisabled}>
              {isLoadingEnable ? "Enabling..." : "Enable"}
            </Button>
          )}
          {isStripeAccountCreated && (
            <Button variant="contained" color="error" onClick={disableAccount} disabled={isDisabled}>
              {isLoadingDeactive ? "Deactivating..." : "Deactivate"}
            </Button>
          )}
          {!isStripeAccountCreated && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const successUrl = `${window.location.origin}/${dbId}/${lang}/stripe/success`;
                const refreshUrl = `${window.location.origin}/${dbId}/${lang}/stripe/refresh`;
                createAccount({ "SuccessUrl": successUrl, "RefreshUrl": refreshUrl });
              }}
              disabled={isDisabled}
            >
              {isLoadingCreate ? "Activating..." : "Activate"}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BusinessDetailsForm;
