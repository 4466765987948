import { useQuery } from "@tanstack/react-query";
import { axiosService } from "../../../api/axios";
import envConfig from "../../../config";
import { useLoginPopup } from "../../../context/LoginPopupContext";
import { useParams } from "react-router-dom";

export interface BankFeedResponse {
  SuccessYN: string;
  ErrorMessage: string | null;
  AccNo: number;
  TrDate: string;
  Amount: number;
  Description: string;
}

export const EXAMPLE_RESPONSE_BANK_FEED: BankFeedResponse = {
  SuccessYN: "Y",
  ErrorMessage: null,
  AccNo: 1000,
  TrDate: "2022-08-23T00:00:00",
  Amount: 57.0000,
  Description: "CALTEX ARCHER RIVER      ARCHER RIVER AU"
};

export interface UseBankFeedsByFeedIdProps {
  feedId?: string;
}

export const getBankFeedsByIdQueryKey = (dbId?: string, lang?: string, feedId?: string) => {
  return ['bank-feeds-by-id', dbId, lang, feedId];
}

export const useBankFeedsByFeedId = ({ feedId }: UseBankFeedsByFeedIdProps) => {
  const { reAuthenticate } = useLoginPopup();
  const { dbId, lang } = useParams();

  return useQuery({
    queryKey: getBankFeedsByIdQueryKey(dbId, lang, feedId),
    enabled: !!feedId,
    queryFn: async () => {
      const searchParams = new URLSearchParams();

      if (feedId) {
        searchParams.append("FeedID", feedId);
      }

      searchParams.append("BaseHostURL", "s1.ssx.au");

      if (process.env.NODE_ENV === "development") {
        return Promise.resolve(EXAMPLE_RESPONSE_BANK_FEED);
      }

      const response = await axiosService.request<BankFeedResponse>({
        method: 'GET',
        baseURL: envConfig.apiDev2,
        endpoint: `/api/${lang}/bank-feeds/get-feed-details?${searchParams.toString()}`,
        reAuthenticate: reAuthenticate,
      });

      return response;
    }
  });
};
