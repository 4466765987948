import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { axiosService } from "../../../../api/axios";
import envConfig from "../../../../config";
import { useLoginPopup } from "../../../../context/LoginPopupContext";
import { BANK_FEEDS_HISTORY_TRANSACTIONS_HISTORY_EXAMPLE_RESPONSE } from "./example-response";
import { BankFeedsHistoryTransactionsResponse } from "../types";

export const getBankFeedsHistoryQueryKey = (dbId?: string, lang?: string, accountId?: string, startDate?: string, endDate?: string) => {
  return ['bank-feeds-history-transactions', dbId, lang, accountId, startDate, endDate];
}

export const useBankFeedsHistoryTransactions = (
  accountIdParam: string | null,
  accountId: string | null,
  startDate: string,
  endDate: string
) => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();

console.log("inside useBankFeedsHistoryTransactions startDate: ", startDate, " endDate: ", endDate);

  return useQuery({
    queryKey: getBankFeedsHistoryQueryKey(dbId, lang, accountId ?? undefined, startDate, endDate),
    enabled: !!accountIdParam || false,
    staleTime: 1000 * 60,
    queryFn: async () => {

      if (process.env.NODE_ENV === 'development') {
        return BANK_FEEDS_HISTORY_TRANSACTIONS_HISTORY_EXAMPLE_RESPONSE;
      }

      console.log("transactions history dates -> startDate: ", startDate, " endDate: ", endDate);

      const searchParams = new URLSearchParams();
      const finalAccountId = accountId ? accountId : accountIdParam;

      searchParams.append("BaseHostURL", envConfig.mainServiceUrl);
      searchParams.append("AccountID", finalAccountId as string);
      searchParams.append("StartDate", startDate);
      searchParams.append("EndDate", endDate);

      const response = await axiosService.request<BankFeedsHistoryTransactionsResponse>({
        dbId: dbId,
        method: 'GET',
        baseURL: envConfig.apiDev2,
        endpoint: `/api/${lang}/bank-feeds/get-transactions-history?${searchParams.toString()}`,
        reAuthenticate: reAuthenticate,
      });

      return response;
    }
  });
};
