import React from "react";
import { PageTitle } from "../../../components/page-title";
import BackdropLoading from "../../../components/BackdropLoading/BackdropLoading";
import { useDashboardData } from "../api/dashboard-main-data";


export default function DashboardPage() {
  const { data: dashboardData, isLoading: isDashboardLoading } = useDashboardData();


  console.log("dashboardData", dashboardData);

  return (
    <>
      <BackdropLoading open={isDashboardLoading} />
      <PageTitle title="Dashboard" />
    </>
  )
}