import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";

const ELEMENT_BORDER_RADIUS = "8px";

export const TimesheetDescription = ({
    description,
    onChange,
    error,
    helperText,
    disabled = false
}) => {
    const [value, setValue] = useState(description);

    useEffect(() => {
        console.log("description", description);
        setValue(description);
    }, [description]);

    return (
        <TextField
            label="Description *"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => onChange(value)}
            size="small"
            fullWidth
            error={!!error}
            helperText={helperText ?? ""}
            sx={{
                flex: { md: 1 },
                bgcolor: "background.paper", 
                borderRadius: ELEMENT_BORDER_RADIUS,
                "& .MuiOutlinedInput-root": {
                    borderRadius: ELEMENT_BORDER_RADIUS,
                },
            }}
            disabled={disabled}
        />
    );
}