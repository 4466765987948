import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { axiosService } from "../../../../api/axios";
import envConfig from "../../../../config";
import { useLoginPopup } from "../../../../context/LoginPopupContext";
import {
  GetUnprocessedTransactionsRS1Response,
  UnprocessedTransactions,
} from "../types";
import { EXAMPLE_GET_UNPROCESSED_TRANSACTIONS_RS1_RESPONSE } from "./example-response";

export const useProcessTransactionsGridData = (accountId: string | undefined) => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();

  return useQuery({
    queryKey: ['process-transactions-grid-data', dbId, lang, accountId],
    queryFn: async () => {
      const searchParams = new URLSearchParams();
      if (process.env.NODE_ENV === 'development') {
        return Promise.resolve(EXAMPLE_GET_UNPROCESSED_TRANSACTIONS_RS1_RESPONSE);
      }

      searchParams.append("BaseHostURL", envConfig.mainServiceUrl);

      const response = await axiosService.request<GetUnprocessedTransactionsRS1Response>({
        dbId: dbId,
        method: 'POST',
        baseURL: envConfig.apiDev2,
        endpoint: `/api/${lang}/bank-feeds/get-unprocessed-transactions-rs1?${searchParams.toString()}`,
        data: {
          AccountID: accountId,
          Bnk0114: "",
          OffsetNo: 0,
          ReturnNo: 100,
          RuleFilter: ""
        },
        reAuthenticate: reAuthenticate,
      });

      return response;
    },
    enabled: !!accountId
  });
};
