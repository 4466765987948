import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import {
  EntryRow,
  UnprocessedTransactionsTableRowEntry,
} from "./process-transactions-table-row-entry";
// import { useStoreProcessTransactionsDependencies } from "../store/process-transactions-store-deps";
import { useCreateBankJournalTransactions } from "../api/process-transactions-create-bank-journal-transactions";
import { enqueueSnackbar } from "notistack";
import { useProcessTransactionsGridData } from "../api/process-transcations-grid-data";
import { useStoreAccounts } from "../store/process-transactions-store-accounts";
import { useTransactions } from "../store/process-transactions-store-transactions";
import { Contact, TaxCode, AccountWithTaxCode, Site } from "../types";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

interface ProcessTransactionsQuickAddSelectedProps {
  accNo: any;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedTransactions: any[];
  handleClearFilters: () => void;
  // dependencies
  listOfTaxCodes: TaxCode[];
  listOfAllSites: Site[];
  listOfContacts: Contact[];
  listOfAccounts: AccountWithTaxCode[]; 
}

export const ProcessTransactionsQuickAddSelected = ({
  accNo,
  isOpen,
  setIsOpen,
  selectedTransactions,
  handleClearFilters,
  // dependencies
  listOfTaxCodes,
  listOfAllSites,
  listOfContacts,
  listOfAccounts,
}: ProcessTransactionsQuickAddSelectedProps) => {
  const queryClient = useQueryClient();
  const { dbId, lang } = useParams();

  const selectedAccount = useStoreAccounts.use.selectedAccount();

  const createBankJournalTransactions = useCreateBankJournalTransactions({
    onSuccess: () => {
      enqueueSnackbar("Transactions added successfully", {
        variant: "success",
      });
      setIsOpen(false);
    },
    onError: (error) => {
      enqueueSnackbar(`Error adding transactions: ${error}`, {
        variant: "error",
      });
    },
  });

  const handleClose = () => {
    setIsOpen(false);
  };

  const onSaveAndPostEntry = async (args) => {
    await createBankJournalTransactions.createBankJournalTransactions({
      ...args,
      ListOfTransactions: selectedTransactions.map(
        (transaction) => transaction.ID,
      ),
    });

    if (!selectedAccount) {
      return;
    }

    queryClient.invalidateQueries({
      queryKey: ['process-transactions-grid-data', dbId, lang, selectedAccount.ID],
    });
  };

  const isOneSelected = selectedTransactions.length === 1;

  const initialEntryData: EntryRow[] = [
    {
      accNo: undefined,
      taxId: undefined,
      memo: "",
      contact: "",
      review: false,
      site: "",
    },
  ];

  React.useEffect(() => {
    if (
      createBankJournalTransactions.isError ||
      createBankJournalTransactions.error
    ) {
      enqueueSnackbar(
        `Error adding transactions: ${createBankJournalTransactions.error}`,
        {
          variant: "error",
        },
      );
    }
  }, [createBankJournalTransactions]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={{
        ">div>div": { maxWidth: "xl" },
      }}
    >
      <DialogTitle id="dialog-title">
        {isOneSelected
          ? "Post selected transaction"
          : "Post selected transactions"}
      </DialogTitle>

      <DialogContent id="dialog-description">
        <Box sx={{ minWidth: "800px", maxWidth: "800px" }}>
          <UnprocessedTransactionsTableRowEntry
            isLoading={createBankJournalTransactions.isLoading}
            initialData={initialEntryData}
            listOfTaxCodes={listOfTaxCodes || []}
            listOfSites={listOfAllSites || []}
            listOfContacts={listOfContacts || []}
            listOfAccounts={listOfAccounts || []}
            onClose={handleClose}
            onSaveAndPostEntry={onSaveAndPostEntry}
            hideMemo={true}
            hideReview={true}
          />
        </Box>
      </DialogContent>
      {/* <DialogActions sx={{ justifyContent: "flex-start" }}>
      </DialogActions> */}
    </Dialog>
  );
};
