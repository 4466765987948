import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import useApi from "../../../../hooks/useApi";
import envConfig from "../../../../config";
import { enqueueSnackbar } from "notistack";
import { useStoreAccounts } from "../store/process-transactions-store-accounts";
import { useProcessTransactionsGridData } from "../api/process-transcations-grid-data";
import { useTransactions } from "../store/process-transactions-store-transactions";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

interface DisregardTransactionDialogProps {
  transactionId: string;
  open: boolean;
  onClose: () => void;
  handleClearFilters: () => void;
}

const DisregardTransactionDialog: React.FC<DisregardTransactionDialogProps> = ({
  transactionId,
  open,
  onClose,
  handleClearFilters,
}) => {
  const queryClient = useQueryClient();
  const { apiCall } = useApi();
  const { dbId, lang } = useParams();

  const [reason, setReason] = React.useState<string>("duplicate");
  const [additionalInfo, setAdditionalInfo] = React.useState<string>("");

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const selectedAccount = useStoreAccounts.use.selectedAccount();

  const handleSubmit = async () => {
    if (
      !transactionId.toUpperCase().startsWith("F") &&
      !transactionId.toUpperCase().startsWith("C")
    ) {
      enqueueSnackbar(`Incorrect transaction id: ${transactionId}`, {
        variant: "error",
      });
      onClose();
      return;
    }

    setIsLoading(true);
    const response = await apiCall({
      url: `https://${envConfig.apiDev1}/api/v3/en-au/bank-feeds/disregard-existing-transaction?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      // @ts-ignore
      body: {
        TransactionId: transactionId,
        ReasonTextSelected: additionalInfo,
        ReasonTextEntered: reason,
      },
      // @ts-ignore
      onSuccess: async () => {
        enqueueSnackbar("Transaction disregarded successfully", {
          variant: "success",
        });

        if (!selectedAccount) {
          onClose();
          return;
        }


        queryClient.invalidateQueries({
          queryKey: ['process-transactions-grid-data', dbId, lang, selectedAccount?.ID],
        });

        setIsLoading(false);
        onClose();
      },
      // @ts-ignore
      onError: async (error) => {
        enqueueSnackbar(`Failed to disregard transaction!: ${error}`, {
          variant: "error",
        });
        setIsLoading(false);
        onClose();
      },
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Disregard Transaction</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          This transaction will not be posted to your ledgers, but will remain
          in bank feeds. Please provide information why are you disregarding
          this transaction:
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="disregard-reason"
            name="disregard-reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          >
            <FormControlLabel
              value="Duplicated transaction"
              control={<Radio />}
              label="Duplicated transaction"
            />
            <FormControlLabel
              value="Transaction processed differently in Exacc, e.g., split or merged with another transaction"
              control={<Radio />}
              label="Transaction processed differently in Exacc, e.g., split or merged with another transaction"
            />
            <FormControlLabel
              value="Other reason"
              control={<Radio />}
              label="Other reason"
            />
          </RadioGroup>
          <TextField
            label="Enter a note for future reference"
            multiline
            rows={4}
            variant="outlined"
            value={additionalInfo}
            onChange={(e) => setAdditionalInfo(e.target.value)}
            fullWidth
            margin="normal"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          // @ts-ignore
          variant="secondary"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          // @ts-ignore
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          {isLoading ? "Disregarding..." : "Disregard"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisregardTransactionDialog;
