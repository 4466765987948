import { Box, Button } from "@mui/material";
import React from "react";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { PageTitle } from "../../components/page-title";
import { ProcessTransactionsAccountSelection } from "../../features/books/process-transactions/components/process-transactions-account-selection";
import { SearchInput } from "../../components/ui/inputs/search-input";
import { useStoreAccounts } from "../../features/books/process-transactions/store/process-transactions-store-accounts";
import { useProcessTransactionsGridData } from "../../features/books/process-transactions/api/process-transcations-grid-data";
import { ProcessTransactionsDataGrid } from "../../features/books/process-transactions/components/process-transactions-data-grid";
import { useTransactions } from "../../features/books/process-transactions/store/process-transactions-store-transactions";
import { ProcessTransactionsFilterPanel } from "../../features/books/process-transactions/components/process-transactions-filter-panel";
import { EventInfo } from "devextreme/events";
import dxDataGrid from "devextreme/ui/data_grid";
import { useBuildJournal } from "../../features/books/process-transactions/api/process-transactions-build-all-journals";
import { useProcessTransactionsApplyRules } from "../../features/books/process-transactions/api/process-transactions-apply-rules-to-bank-feed";
import { enqueueSnackbar } from "notistack";
import { ProcessTransactionsQuickAddSelected } from "../../features/books/process-transactions/components/process-transactions-quick-add-selected";
import { useSearchParams } from "react-router-dom";
import { InitialData } from "../../features/books/process-transactions/types";
import { useProcessTransactionsDefault } from "../../features/books/process-transactions/api/process-transactions-defaults";
import { useProcessTransactionsInitialData } from "../../features/books/process-transactions/api/process-transcations-initial-data";

export function ProcessTransactionsPage() {
  const dtGridRef = React.useRef<any>();
  const [searchParams] = useSearchParams();

  const [isQuickAddOpen, setIsQuickAddOpen] = React.useState(false);

  // State/Zustand Stores
  const selectedAccount = useStoreAccounts.use.selectedAccount();
  const setSelectedAccount = useStoreAccounts.use.setSelectedAccount();

  // const transactions = useTransactions.use.transactions();
  // const setTransactions = useTransactions.use.setTransactions();
  const [transactions, setTransactions] = React.useState<any[]>([]);

  // const initialTransactions = useTransactions.use.initialTransactions();
  // const setInitialTransactions = useTransactions.use.setInitialTransactions();

  const selectedRowsKeys = useTransactions.use.selectedRowsKeys();
  const setSelectedRowsKeys = useTransactions.use.setSelectedRowsKeys();

  const isFiltered = useTransactions.use.isFiltered();
  const setIsFiltered = useTransactions.use.setIsFiltered();
  const filteredRowsKeys = useTransactions.use.filteredRowsKeys();
  const setFilteredRowsKeys = useTransactions.use.setFilteredRowsKeys();

  const appliedRule = useTransactions.use.appliedRule();
  const setAppliedRule = useTransactions.use.setAppliedRule();

  const searchTerm = useTransactions.use.searchTerm();
  const setSearchTerm = useTransactions.use.setSearchTerm();

  // setters only for "dependencies" - one api call for all of them
  // then we used them in nested grid for "quick add" functionality
  // const setListOfTaxCodes =
  //   useStoreProcessTransactionsDependencies.use.setListOfTaxCodes();
  // const setListOfSites =
  //   useStoreProcessTransactionsDependencies.use.setListOfSites();
  // const setListOfContacts =
  //   useStoreProcessTransactionsDependencies.use.setListOfContacts();
  // const setListOfAccounts =
  //   useStoreProcessTransactionsDependencies.use.setListOfAccounts();

  // INITIAL DATA
  const [initialDataResponse, setInitialDataResponse] = React.useState<
    InitialData | undefined
  >();
  const [isErrorInitialData, setIsErrorInitialData] = React.useState(false);
  const [isSuccessInitialData, setIsSuccessInitialData] = React.useState(false);

  const initialData = useProcessTransactionsInitialData();

  React.useEffect(() => {
    if (initialData.isSuccess && initialData.data) {
      setInitialDataResponse(initialData.data);
      setIsSuccessInitialData(true);
    }
  }, [initialData.data, initialData.isSuccess]);

  React.useEffect(() => {
    if (initialData.isError) {
      setIsErrorInitialData(true);
      enqueueSnackbar(`Error fetching initial data: ${initialData.error}`, {
        variant: "error",
      });
    }
  }, [initialData.isError]);

  // const gridDataMutation = useProcessTransactionsGridData({
  //   // @ts-ignore
  //   onSuccess: (data) => {
  //     console.log("DEBUGGING: gridDataMutation data: ", data);
  //     handleClearFilters();
  //     if (data?.Transactions) {
  //       setTransactions(data?.Transactions);
  //       setInitialTransactions(data?.Transactions);
  //     } else {
  //       setTransactions([]);
  //       setInitialTransactions([]);
  //     }
  //   },
  //   // @ts-ignore
  //   onError: (error) => {
  //     enqueueSnackbar(`Error fetching transactions: ${error}`, {
  //       variant: "error",
  //     });
  //   },
  // });

  const buildJournalMutation = useBuildJournal({
    onSuccess: async () => {
      enqueueSnackbar("Transactions posted successfully", {
        variant: "success",
      });
      await refetchGridData();
      handleClearFilters();
      setSelectedAccount(null);
    },
    onError: (error) => {
      enqueueSnackbar(`Error posting transactions: ${error}`, {
        variant: "error",
      });
    },
  });

  const applyRulesMutation = useProcessTransactionsApplyRules({
    onSuccess: async () => {
      enqueueSnackbar("Rules applied successfully", {
        variant: "success",
      });
      await refetchGridData();
    },
    onError: (error) => {
      enqueueSnackbar(`Error applying rules: ${error}`, {
        variant: "error",
      });
    },
  });

  console.log("DEBUGGING: Apply Rules mutation: ", applyRulesMutation);

  // const listOfTaxCodes = useNewListOfTaxCodes({
  //   onSuccess: (data) => {
  //     setListOfTaxCodes(data?.listOfTaxCodes || []);
  //   },
  // });


  const { data: defaults, isLoading: isLoadingDefaults } = useProcessTransactionsDefault();
  const { data: gridData, isLoading: isLoadingGridData, refetch: refetchGridData, isError: isErrorGridData, error: errorGridData } = useProcessTransactionsGridData(selectedAccount?.ID);
  console.log("DEBUGGING: defaults: ", defaults);

  // const listOfTaxCodes = useNewListOfTaxCodes({});
  // const listOfSites = useListOfSites();
  // const listOfContacts = useListOfContacts({});
  // const listOfAccounts = useGetAccounts();

  const handleSelectionChanged = (e: EventInfo<dxDataGrid<any, any>> & any) => {
    setSelectedRowsKeys(e.selectedRowKeys);
  };

  const resetSelectionKeys = () => setSelectedRowsKeys([]);

  const handleSelectOrUnselectAllTransactions = () => {
    if (selectedRowsKeys.length === transactions.length) {
      resetSelectionKeys();
    } else {
      if (dtGridRef?.current && !!searchTerm) {
        const gridInstance = dtGridRef.current?.instance;
        const visibleRows = gridInstance.getVisibleRows();
        const filteredRowKeys = visibleRows.map((row) => row.key);
        setSelectedRowsKeys(filteredRowKeys);
        return;
      }

      setSelectedRowsKeys(transactions.map((t) => t.ID));
    }
  };

  const handleFilterWithRules = () => {
    const filteredTransactions = transactions.filter(
      (t) => t.Suggestions && t.Suggestions.length > 0,
    );
    setTransactions(filteredTransactions);
    setFilteredRowsKeys(
      filteredTransactions.map((transaction) => transaction.ID),
    );
    resetSelectionKeys();
    setIsFiltered(true);
  };

  // passing it very deep :<
  const handleClearFilters = () => {
    resetSelectionKeys();
    setFilteredRowsKeys([]);
    setSearchTerm("");
    setIsFiltered(false);
    setAppliedRule("");
    if (dtGridRef?.current) {
      const gridInstance = dtGridRef.current?.instance;
      gridInstance.searchByText("");
    }
  };

  const handleOnSearch = (e) => {
    const filteredRowKeys = initialTransactions.filter((t) => {
      return t.Description?.toLowerCase().includes(
        e.target.value.toLowerCase(),
      );
    });
    console.log("filteredRowKeys", filteredRowKeys);

    setSearchTerm(e.target.value);
    setFilteredRowsKeys(filteredRowKeys.map((t) => t.ID));
    setTransactions(filteredRowKeys);
    resetSelectionKeys();
  };

  const handlePostTransactionWithRule = async () => {
    if (!selectedAccount) {
      return;
    }

    const response = await buildJournalMutation.buildJournal({
      // @ts-ignore
      AccountID: selectedAccount?.ID,
      Bnk0105: "",
      RuleFilter: appliedRule,
    });

    // await gridDataMutation.fetchGridData({
    //   // @ts-ignore
    //   AccountID: selectedAccount.ID,
    //   Bnk0114: "",
    //   OffsetNo: 0,
    //   ReturnNo: 100,
    //   RuleFilter: "",
    // });
  };

  const handleApplyRules = async () => {
    const response = await applyRulesMutation.applyRules({
      Bnk0102: "",
      OverridePromptsYN: "N",
    });

    console.log("DEBUGGING: Apply Rules response: ", response);

    if (!selectedAccount) {
      return;
    }

    // refetchGridData();

    // await gridDataMutation.fetchGridData({
    //   AccountID: selectedAccount.ID,
    //   Bnk0114: "",
    //   OffsetNo: 0,
    //   ReturnNo: 100,
    //   RuleFilter: "",
    // });
  };

  const handleQuickAddSelected = () => {
    setIsQuickAddOpen(true);
  };

  // // There is no better way if we will remove TanStack Query (React Query)
  // // without writing a lot of additional code
  // React.useEffect(() => {
  //   console.log("CALLED: useEffect that sets transactions");
  //   if (gridDataMutation.data?.Transactions) {
  //     setTransactions(gridDataMutation.data?.Transactions);
  //     setInitialTransactions(gridDataMutation.data?.Transactions);
  //   }
  // }, [
  //   gridDataMutation.data?.Transactions,
  //   setTransactions,
  //   setInitialTransactions,
  // ]);

  React.useEffect(() => {
    if (isErrorGridData) {
      enqueueSnackbar(`Error fetching grid data: ${errorGridData}`, {
        variant: "error",
      });
    }
  }, [isErrorGridData]);

  React.useEffect(() => {
    if (buildJournalMutation.error) {
      enqueueSnackbar(`Error building journal: ${buildJournalMutation.error}`, {
        variant: "error",
      });
    }
  }, [buildJournalMutation.error]);

  React.useEffect(() => {
    if (applyRulesMutation.error) {
      enqueueSnackbar(`Error applying rules: ${applyRulesMutation.error}`, {
        variant: "error",
      });
    }
  }, [applyRulesMutation.error]);

  React.useEffect(() => {
    if (isSuccessInitialData && searchParams.get("accNo")) {
      console.log(
        "DEBUGGING Calling the useEffect with searchParams and when initial data is success",
        searchParams,
        isSuccessInitialData,
      );

      const accountID = searchParams.get("accNo");
      const account = initialDataResponse?.AccountsList.find(
        (acc) => `${acc.AccNo}` === accountID,
      );
      if (account) {
        setSelectedAccount(account);
      } else {
        enqueueSnackbar(`Account ${accountID} not found`, {
          variant: "error",
        });
      }
    }
  }, [isSuccessInitialData]);

  React.useEffect(() => {
    console.log("DEBUGGING: called useEffect with gridData");
    if (gridData?.Transactions) {
      console.log("DEBUGGING: gridData.Transactions", gridData.Transactions);
      setTransactions(gridData.Transactions);
      handleClearFilters();
    } else {
      setTransactions([]);
    }
  }, [gridData]);


  const areAllRowsSelected = selectedRowsKeys.length === transactions.length;
  const isFiltering = filteredRowsKeys.length > 0;
  const isSearching = searchTerm.length > 0;
  const listOfTaxCodes = defaults?.ListOfTaxCodes || [];
  const listOfSites = defaults?.ListOfSites || [];
  const listOfContacts = defaults?.ListOfContacts || [];
  const listOfAccounts = defaults?.ListOfAccounts || [];
  const initialTransactions = gridData?.Transactions || [];

  console.log("DEBUGGING: transactions", transactions);
  console.log("DEBUGGING: initialDataResponse", initialDataResponse);
  console.log("DEBUGGING: initialTransactions", initialTransactions);

  return (
    <>
      <BackdropLoading
        open={
          isLoadingDefaults ||
          initialData.isLoading ||
          buildJournalMutation.isLoading ||
          applyRulesMutation.isLoading ||
          isLoadingGridData
        }
      />

      <ProcessTransactionsQuickAddSelected
        accNo={selectedAccount?.AccNo}
        isOpen={isQuickAddOpen}
        setIsOpen={setIsQuickAddOpen}
        selectedTransactions={[
          ...transactions.filter((t) => selectedRowsKeys.includes(t.ID)),
        ]}
        handleClearFilters={handleClearFilters}
        listOfTaxCodes={listOfTaxCodes}
        listOfAllSites={listOfSites}
        listOfContacts={listOfContacts}
        listOfAccounts={listOfAccounts}
      />

      <PageTitle title={"Account Transactions filters"} />
      <Box display={"flex"} flexDirection={"row"} gap={2} mt={4}>
        <ProcessTransactionsAccountSelection
          accounts={initialDataResponse?.AccountsList || []}
          selectedAccount={selectedAccount}
          onAccountChange={(acc) => {
            handleClearFilters();
            // TODO: Extract this into a function
            setSelectedAccount(acc);
            // gridDataMutation.fetchGridData({
            //   AccountID: acc.ID,
            //   Bnk0114: "",
            //   OffsetNo: 0,
            //   ReturnNo: 100,
            //   RuleFilter: "",
            // });
          }}
        />
        <SearchInput
          value={searchTerm}
          onChange={handleOnSearch}
          sx={{ flex: 1, maxWidth: undefined }}
        />
        <Button
          variant="outlined"
          color={"info"}
          disabled={true}
          onClick={() => { }}
          size={"large"}
        >
          Export
        </Button>
      </Box>

      <ProcessTransactionsFilterPanel
        isFiltering={isFiltering}
        isSearching={isSearching}
        isFiltered={isFiltered}
        isCheckboxChecked={areAllRowsSelected}
        onCheckboxChange={handleSelectOrUnselectAllTransactions}
        selectedTransactionsCount={selectedRowsKeys.length}
        onClearFilters={() => {
          handleClearFilters();
          setTransactions(initialTransactions);
        }}
        showPostTransactionWithRule={!!appliedRule}
        onPostTransactionWithRule={handlePostTransactionWithRule}
        onApplyRules={handleApplyRules}
        onQuickAddSelected={handleQuickAddSelected}
        onFilterWithRules={handleFilterWithRules}
      />

      <ProcessTransactionsDataGrid
        ref={dtGridRef}
        data={transactions}
        selectedRowKeys={selectedRowsKeys}
        onSelectionChanged={handleSelectionChanged}
        handleClearFilters={handleClearFilters}
        // dependencies
        listOfTaxCodes={listOfTaxCodes}
        listOfAllSites={listOfSites}
        listOfContacts={listOfContacts}
        listOfAccounts={listOfAccounts}
      />
    </>
  );
}
