import React from "react";
import dayjs from "dayjs";
import { Box, TextField, Autocomplete } from "@mui/material";

import { ActivityType } from "../types";
import { Contact } from "../../accounting/api/list-of-contacts";
import { TimesheetTimer } from "./timesheet-timer";
import { VirtualizedAutocomplete } from "../../../components/ui/autocomlete/virtualized-autocomplete";
import { TimesheetDescription } from "./timesheet-description";

interface TimesheetDailyFormProps {
  duration?: string;
  description: string;
  onDescriptionChange: (value: string) => void;
  clientsOptions: Contact[];
  client: Contact | null;
  onClientChange: (client: Contact | null) => void;
  activityTypesOptions: ActivityType[];
  activityType: ActivityType | null;
  onActivityTypeChange: (activityType: ActivityType | null) => void;
  disabled?: boolean;
  onStartTimer?: (startTime: dayjs.Dayjs) => void;
  onResumeTimer?: (duration: string) => void;
  onStopTimer?: (duration: string) => void;
  onFinishTimer?: (duration: string) => void;
  onTimerDeleted?: () => void;
  beforeOnStart?: () => boolean;
  beforeOnFinish?: () => boolean;
  descriptionError?: string | null;
  clientError?: string | null;
  activityTypeError?: string | null;
  durationError?: string | null;
  forceTriggerStart?: boolean;
  setForceTriggerStart?: (value: boolean) => void;
}

const ELEMENT_BORDER_RADIUS = "8px";

const TimesheetDailyForm: React.FC<TimesheetDailyFormProps> = ({
  duration,
  description,
  onDescriptionChange,
  clientsOptions,
  client,
  onClientChange,
  activityTypesOptions,
  activityType,
  onActivityTypeChange,
  disabled = false,
  onStartTimer,
  onResumeTimer,
  onStopTimer,
  onFinishTimer,
  onTimerDeleted,
  descriptionError,
  clientError,
  activityTypeError,
  durationError,
  beforeOnStart,
  beforeOnFinish,
  forceTriggerStart,
  setForceTriggerStart,
}) => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      gap={{ xs: 4, sm: 4, md: 2 }}
      alignItems={{ xs: "stretch", md: "center" }}
      padding={2}
      border={1}
      borderColor="primary.main"
      borderRadius={2}
      width="100%"
      maxWidth={{ md: "1000px" }}
    >
      <TimesheetDescription
        description={description}
        onChange={onDescriptionChange}
        error={descriptionError}
        helperText={descriptionError ?? ""}
        disabled={disabled}
      />
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        width="100%"
        flex={{ md: 2 }}
      >
        <VirtualizedAutocomplete
          options={clientsOptions}
          value={client}
          onChange={(_, newValue) => onClientChange(newValue)}
          getOptionLabel={(option) => `${option.cntCode}`}
          renderOption={(props, option) => (
            <li {...props}>
              {option.cntCode} - {option.cntName}
            </li>
          )}
          width="100%"
          label="Client"
          listWidth={400}
          itemSize={48}
          error={!!clientError}
          helperText={clientError ?? ""}
          otherAutoCompleteProps={{
            disabled: disabled,
            placeholder: "Client",
          }}
        />

        <Autocomplete
          options={activityTypesOptions}
          value={activityType}
          onChange={(_, newValue) => onActivityTypeChange(newValue)}
          getOptionLabel={(option) => option.avtCode}
          renderOption={(props, option) => (
            <li {...props}>
              {option.avtCode} - {option.avtName}
            </li>
          )}
          fullWidth
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Activity Type"
              label="Activity Type"
              size="small"
              error={!!activityTypeError}
              helperText={activityTypeError ?? ""}
              sx={{
                bgcolor: "background.paper",
                borderRadius: ELEMENT_BORDER_RADIUS,
                "& .MuiOutlinedInput-root": {
                  borderRadius: ELEMENT_BORDER_RADIUS,
                },
              }}
            />
          )}
        />
      </Box>
      <TimesheetTimer
        duration={duration}
        onStart={onStartTimer}
        onStop={onStopTimer}
        onResume={onResumeTimer}
        onFinish={onFinishTimer}
        onTimerDeleted={onTimerDeleted}
        beforeOnStart={beforeOnStart}
        beforeOnFinish={beforeOnFinish}
        error={durationError}
        forceTriggerStart={forceTriggerStart}
        setForceTriggerStart={setForceTriggerStart}
      />
    </Box>
  );
};

export default TimesheetDailyForm;
