import React, { useCallback } from "react";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { useState } from "react";
import { useSnackbar } from "notistack";

import TimesheetHeader from "../../features/timesheet/components/timesheet-header";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import TimesheetActions from "../../features/timesheet/components/timesheet-actions";
import { ActivityType } from "../../features/timesheet/types";
import {
  Contact,
  useListOfContacts,
} from "../../features/accounting/api/list-of-contacts";
import TimesheetList from "../../features/timesheet/components/timesheet-list";
import TimesheetDailyForm from "../../features/timesheet/components/timesheet-daily-form";
import {
  TimesheetInsertPayload,
  useTimesheetInsert,
} from "../../features/timesheet/api/timesheet-insert-timesheet";
import { calculateTimeUnits } from "../../utils/time";
import { useTimesheetDelete } from "../../features/timesheet/api/timesheet-delete-timesheet";
import { useTimesheetDetails } from "../../features/timesheet/api/timesheet-get-details";
import { useNewTimesheetDailyList } from "../../features/timesheet/api/timesheet-get-daily-list-new";
import { TimesheetUpdatePayload, useTimesheetUpdate } from "../../features/timesheet/api/timesheet-update-timesheet";
import { TimesheetDetailedForm } from "../../features/timesheet/components/timesheet-detailed-form";
import { getCookie } from "../../utils/api";

export type BasicTimesheetErrors = {
  client?: string;
  user?: string;
  activityType?: string;
  description?: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  duration?: string;
};

export default function Timesheet() {
  const { enqueueSnackbar } = useSnackbar();

  // Current Timesheet/Activity id - used to update the timesheet
  // if the timer is running we need to update the timesheet not insert a new one
  const [currActivityId, setCurrActivityId] = React.useState<string | null>(null);

  // Current Timesheet/Activity related states
  const [date, setDate] = React.useState<dayjs.Dayjs | null>(dayjs());
  const [description, setDescription] = React.useState("");
  const [client, setClient] = React.useState<Contact | null>(null);
  const [actType, setActType] = React.useState<ActivityType | null>(null);

  // User related states
  const [user, setUser] = React.useState<any>({
    usrID: getCookie("globalUserId"),
    usrName: "",
  });

  // Detailed Timesheet related states
  // const [startTime2, setStartTime2] = React.useState<dayjs.Dayjs | null>(null);
  const [endTime, setEndTime] = React.useState<dayjs.Dayjs | null>(null);
  const [timeUnits, setTimeUnits] = React.useState(0);
  const [isChargeable, setIsChargeable] = React.useState(false);
  const [charge, setCharge] = React.useState<number>(0);
  const [hourlyRate, setHourlyRate] = React.useState<number>(0);
  const [markForAttention, setMarkForAttention] = React.useState(false);
  const [note, setNote] = React.useState("");
  const [task, setTask] = React.useState<any>(null);
  const [department, setDepartment] = React.useState<any>(null);

  // Timer related states
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [startTime, setStartTime] = useState<dayjs.Dayjs | null>(null);
  const [duration, setDuration] = useState<string>("00:00:00");
  const [forceTriggerStart, setForceTriggerStart] = useState(false);

  // Error states
  const [errors, setErrors] = React.useState<BasicTimesheetErrors>({
    client: "",
    activityType: "",
    description: "",
    duration: "",
  });

  // UI related states
  const [isDetailedFormOpen, setIsDetailedFormOpen] = useState(false);

  const timesheetDetailsQuery = useTimesheetDetails();
  const contactsQuery = useListOfContacts();
  const dailyListQuery = useNewTimesheetDailyList({ date: date || dayjs() });

  console.log('---------------------------------------------');
  console.log('[TIMESHEET] - dailyListQuery.isError: ', dailyListQuery.isError);
  console.log('[TIMESHEET] - dailyListQuery.error: ', dailyListQuery.error);
  console.log('[TIMESHEET] - dailyListQuery.isLoading: ', dailyListQuery.isLoading);
  console.log('[TIMESHEET] - dailyListQuery.isSuccess: ', dailyListQuery.isSuccess);
  console.log('[TIMESHEET] - dailyListQuery.data: ', dailyListQuery.data);
  console.log('---------------------------------------------');

  const { mutate: insertTimesheet } = useTimesheetInsert({ date });
  const { mutate: updateTimesheet } = useTimesheetUpdate({ date });

  const { mutate: deleteTimesheet, isPending: isLoadingDelete } = useTimesheetDelete({
    onSuccess: () => {
      dailyListQuery.refetch();
    },
  });

  const handleActivityTypeChange = (newActivityType: ActivityType) => {
    setActType(newActivityType);
    setErrors(prev => ({ ...prev, activityType: "" }));
  };

  const handleClientChange = (newClient: Contact) => {
    setClient(newClient);
    setErrors(prev => ({ ...prev, client: "" }));
  };

  const handleUserChange = (value: any) => {
    setUser(value);
    setErrors((prev) => ({ ...prev, user: "" }));
  };

  const handleDepartmentChange = (value: any) => setDepartment(value);
  const handleTaskChange = (value: any) => setTask(value);


  const handleStartTimeChange = (value: dayjs.Dayjs | null) => {
    setStartTime(value);
    setErrors((prev) => ({ ...prev, startTime: "" }));
  };

  const handleEndTimeChange = (value: dayjs.Dayjs | null) => {
    setEndTime(value);
    setErrors((prev) => ({ ...prev, endTime: "" }));
  };

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
    setErrors(prev => ({ ...prev, description: "" }));
  };

  const handleChargeableChange = (value: boolean) => setIsChargeable(value);
  const handleChargeChange = (value: number) => setCharge(value);
  const handleHourlyRateChange = (value: number) => setHourlyRate(value);

  const handleMarkForAttentionChange = (value: boolean) =>
    setMarkForAttention(value);
  const handleNoteChange = (value: string) => setNote(value);

  const resetForm = useCallback(() => {
    // Reset inputs
    setDescription("");
    setClient(null);
    setActType(null);
    setIsTimerRunning(false);
    setStartTime(null);
    setDuration("00:00:00");
    setDepartment(null);
    setTask(null);
    setIsDetailedFormOpen(false);
    // setStartTime2(null);
    setStartTime(null);
    setIsChargeable(false);
    setCharge(0);
    setHourlyRate(0);
    setMarkForAttention(false);
    setNote("");

    // Clear errors
    setErrors({
      client: "",
      activityType: "",
      description: "",
      duration: "",
      user: "",
      startTime: "",
      endTime: "",
      // task: "",
      // department: "",
    });
  }, []);

  const handleDateChange = useCallback(
    (newDate: dayjs.Dayjs | null) => {
      setDate(newDate);
      resetForm();
    },
    [resetForm],
  );


  const handleStartTimer = (start: dayjs.Dayjs) => {
    setIsTimerRunning(true);
    setErrors(prev => ({ ...prev, duration: "" }));

    // it is needed?
    setStartTime(start);

    const userID = timesheetDetailsQuery.data?.CurrentLocalUserId;
    const timeUnits = Math.ceil(calculateTimeUnits("00:00:00"));
    const [hours, minutes, seconds] = duration.split(":").map(Number);

    const payloadDateTime = date?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01";

    const payloadStartTime =
      start?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

    const payloadEndTime =
      start
        ?.add(hours, "hours")
        .add(minutes, "minutes")
        .add(seconds, "seconds")
        .format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

    console.log("StartTime: ", payloadStartTime);
    console.log("EndTime: ", payloadEndTime);

    const payload: TimesheetInsertPayload = {
      acvType_avtID: actType?.avtID ?? 0,
      acvUser_usrID: Number(userID) || 0,
      acvTimeUnits: timeUnits > 0 ? timeUnits : 0.1,
      acvDescription: description,
      acvNotes: "",
      acvTaskDate: payloadDateTime,
      acvStartTime: payloadStartTime,
      acvEndTime: payloadEndTime,
      acvInvoice_invID: 0, // TODO: get invoice id
      acvChargeableYN: "Y",
      acvContact_cntID: client?.cntId ?? 0,
      acvHourlyRate: 0, // TODO: get hourly rate
      acvManualUnitsYN: "N",
      acvCharge: 0,
      acvOurCost: 0,
      acvTask_prdID: 0,
      acvSite_dpsID: 0,
      acvPayroll_pevID: 0,
      acvPaidBreakUnits: 0,
      acvUnpaidBreakUnits: 0,
      acvDocument_docID: "",
      acvTransferGUID: "",
      acvEntrySource: "",
      acvForActionYN: "Y",
      acvInvReadyYN: "N",
    };

    enqueueSnackbar("Timesheet started!", {
      variant: "success",
    });

    insertTimesheet(payload, {
      onSuccess: (data, variables, context) => {
        enqueueSnackbar("Timesheet inserted successfully!", {
          variant: "success",
        });
        setCurrActivityId(data.NewActivityId);
      },
      onError: () => {
        enqueueSnackbar("Backend error while inserting timesheet!", {
          variant: "error",
        });
      },
    });

  };

  const handleResumeTimer = (time: string) => {
    setIsTimerRunning(true);
    setDuration(time);
    setErrors(prev => ({ ...prev, duration: "" }));
    enqueueSnackbar("Timesheet resumed!", {
      variant: "info",
    });
  };

  const handleStopTimer = (time: string) => {
    setIsTimerRunning(false);
    setDuration(time);

    // get the end time on stop
    const [hours, minutes, seconds] = time.split(":").map(Number);
    const calculatedEndTime = startTime?.add(hours, "hours").add(minutes, "minutes").add(seconds, "seconds");
    setEndTime(calculatedEndTime || null);

    setErrors(prev => ({ ...prev, duration: "" }));
    enqueueSnackbar("Timesheet stopped!", {
      variant: "info",
    });
  };

  const handleFinishTimer = (time: string) => {
    const userID = timesheetDetailsQuery.data?.CurrentLocalUserId;
    const calculatedTimeUnits = Math.ceil(calculateTimeUnits(time));
    const [hours, minutes, seconds] = time.split(":").map(Number);

    const payloadDateTime = date?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01";

    const payloadStartTime =
      startTime?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

    const payloadEndTime =
      startTime
        ?.add(hours, "hours")
        .add(minutes, "minutes")
        .add(seconds, "seconds")
        .format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

    const payload: TimesheetUpdatePayload = {
      acvID: currActivityId!,
      acvType_avtID: actType?.avtID ?? 0,
      acvUser_usrID: Number(userID) || 0,
      acvTimeUnits: calculatedTimeUnits,
      acvDescription: description,
      acvNotes: "",
      acvTaskDate: payloadDateTime,
      acvStartTime: payloadStartTime,
      acvEndTime: payloadEndTime,
      acvInvoice_invID: 0, // TODO: get invoice id
      acvChargeableYN: "Y",
      acvContact_cntID: client?.cntId ?? 0,
      acvHourlyRate: 0, // TODO: get hourly rate
      acvManualUnitsYN: "N",
      acvCharge: 0,
      acvOurCost: 0,
      acvTask_prdID: 0,
      acvSite_dpsID: 0,
      acvPayroll_pevID: 0,
      acvPaidBreakUnits: 0,
      acvUnpaidBreakUnits: 0,
      acvDocument_docID: "",
      acvTransferGUID: "",
      acvEntrySource: "",
      acvForActionYN: "Y",
      acvInvReadyYN: "N",
    };

    updateTimesheet(payload, {
      onSuccess: () => {
        enqueueSnackbar("Timesheet updated successfully!", {
          variant: "success",
        });
        resetForm();
        setIsTimerRunning(false);
        setCurrActivityId(null);
      },
      onError: () => {
        enqueueSnackbar("Backend error while updating timesheet!", {
          variant: "error",
        });
      },
    });

    enqueueSnackbar("Timesheet finished!", {
      variant: "success",
    });
  };

  const handleTimerDeleted = () => {
    if (!currActivityId) {
      enqueueSnackbar("No timesheet to delete with this id! " + currActivityId, {
        variant: "warning",
      });
      return;
    };

    deleteTimesheet(currActivityId!, {
      onSuccess: () => {
        enqueueSnackbar("Timesheet deleted successfully!", {
          variant: "success",
        });
        setCurrActivityId(null);
        resetForm();
      },
      onError: () => {
        enqueueSnackbar("Backend error while deleting timesheet!", {
          variant: "error",
        });
      },
    });
  };

  const validateTimesheetForm = () => {
    const newErrors = {
      // client: !client ? "Client is required" : "",
      // activityType: !actType ? "Activity type is required" : "",
      description: !description.trim() ? "Description is required" : "",
      // duration: !duration ? "Duration is required" : "",
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error !== "");
    if (hasErrors) {
      enqueueSnackbar("Please fill in all required fields", {
        variant: "warning",
      });
      return false;
    }

    return true;
  };

  const validateDetailedTimesheetForm = () => {
    const userID = timesheetDetailsQuery.data?.CurrentLocalUserId;

    const newErrors = {
      client: !client ? "Client is required" : "",
      user: !userID ? "User is required" : "",
      activityType: !actType ? "Activity type is required" : "",
      description: !description.trim() ? "Description is required" : "",
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error !== "");
    if (hasErrors) {
      enqueueSnackbar("Please fill in all required fields", {
        variant: "warning",
      });
      return false;
    }

    return true;
  };

  const handleDeleteTimesheet = useCallback(
    (id: string) => {
      deleteTimesheet(id, {
        onSuccess: () => {
          enqueueSnackbar("Timesheet deleted successfully!", {
            variant: "success",
          });
        },
        onError: () => {
          enqueueSnackbar("Backend error while deleting timesheet!", {
            variant: "error",
          });
        },
      });
    },
    [deleteTimesheet],
  );

  const handleOnSave = () => {
    const isValid = validateDetailedTimesheetForm();

    if (isValid) {
      const userID = timesheetDetailsQuery.data?.CurrentLocalUserId;
      // const timeUnits = Math.ceil(calculateTimeUnits(duration));

      const payloadDateTime = date?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01";

      const payloadStartTime =
        startTime?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

      const payloadEndTime =
        endTime && endTime.isValid() ? endTime.format("YYYY-MM-DDTHH:mm:ss") :
          "0001-01-01T00:00:00";

      console.log("StartTime: ", payloadStartTime);
      console.log("EndTime: ", payloadEndTime);

      const payload: TimesheetInsertPayload = {
        acvType_avtID: actType!.avtID,
        acvUser_usrID: Number(userID) || 0,
        acvTimeUnits: timeUnits > 0 ? timeUnits : 0.1,
        acvDescription: description,
        acvNotes: note,
        acvTaskDate: payloadDateTime,
        acvStartTime: payloadStartTime,
        acvEndTime: payloadEndTime,
        acvInvoice_invID: 0, // TODO: get invoice id
        acvChargeableYN: isChargeable ? "Y" : "N",
        acvContact_cntID: client!.cntId,
        acvHourlyRate: hourlyRate,
        acvManualUnitsYN: "N",
        acvCharge: charge,
        acvOurCost: 0,
        acvTask_prdID: task?.prdID || 0,
        acvSite_dpsID: department?.dpsID || 0,
        acvPayroll_pevID: 0,
        acvPaidBreakUnits: 0,
        acvUnpaidBreakUnits: 0,
        acvDocument_docID: "",
        acvTransferGUID: "",
        acvEntrySource: "",
        acvForActionYN: markForAttention ? "Y" : "N",
        acvInvReadyYN: "N",
      };

      if (currActivityId) {
        const updatePayload: TimesheetUpdatePayload = {
          ...payload,
          acvID: currActivityId,
        };
        updateTimesheet(updatePayload, {
          onSuccess: (data, variables, context) => {
            enqueueSnackbar("Timesheet updated successfully!", {
              variant: "success",
            });
          }
        })
      } else {
        insertTimesheet(payload, {
          onSuccess: (data, variables, context) => {
            enqueueSnackbar("Timesheet inserted successfully!", {
              variant: "success",
            });
            setIsDetailedFormOpen(false);
            resetForm();
          },
          onError: () => {
            enqueueSnackbar("Backend error while inserting timesheet!", {
              variant: "error",
            });
          },
        });
      }
    }
  };

  const handleOnSaveAndNew = () => {
    const isValid = validateDetailedTimesheetForm();

    if (isValid) {
      const userID = timesheetDetailsQuery.data?.CurrentLocalUserId;
      // const timeUnits = Math.ceil(calculateTimeUnits(duration));
      const [hours, minutes, seconds] = duration.split(":").map(Number);

      const payloadDateTime = date?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01";

      // const payloadStartTime =
      //   startTime2?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";
      const payloadStartTime =
        startTime?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

      // const payloadEndTime =
      //   startTime2
      //     ?.add(hours, "hours")
      //     .add(minutes, "minutes")
      //     .add(seconds, "seconds")
      //     .format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

      const payloadEndTime =
        endTime && endTime.isValid() ? endTime.format("YYYY-MM-DDTHH:mm:ss") :
          "0001-01-01T00:00:00";

      console.log("StartTime: ", payloadStartTime);
      console.log("EndTime: ", payloadEndTime);

      const payload: TimesheetInsertPayload = {
        acvType_avtID: actType!.avtID,
        acvUser_usrID: Number(userID) || 0,
        acvTimeUnits: timeUnits > 0 ? timeUnits : 0.1,
        acvDescription: description,
        acvNotes: note,
        acvTaskDate: payloadDateTime,
        acvStartTime: payloadStartTime,
        acvEndTime: payloadEndTime,
        acvInvoice_invID: 0, // TODO: get invoice id
        acvChargeableYN: isChargeable ? "Y" : "N",
        acvContact_cntID: client!.cntId,
        acvHourlyRate: hourlyRate,
        acvManualUnitsYN: "N",
        acvCharge: charge,
        acvOurCost: 0,
        acvTask_prdID: task?.prdID || 0,
        acvSite_dpsID: department?.dpsID || 0,
        acvPayroll_pevID: 0,
        acvPaidBreakUnits: 0,
        acvUnpaidBreakUnits: 0,
        acvDocument_docID: "",
        acvTransferGUID: "",
        acvEntrySource: "",
        acvForActionYN: markForAttention ? "Y" : "N",
        acvInvReadyYN: "N",
      };

      if (currActivityId) {
        const updatePayload: TimesheetUpdatePayload = {
          ...payload,
          acvID: currActivityId,
        };
        updateTimesheet(updatePayload, {
          onSuccess: (data, variables, context) => {
            enqueueSnackbar("Timesheet updated successfully!", {
              variant: "success",
            });
          }
        })
      } else {
        insertTimesheet(payload, {
          onSuccess: (data, variables, context) => {
            enqueueSnackbar("Timesheet inserted successfully!", {
              variant: "success",
            });
            setIsDetailedFormOpen(true);
            resetForm();
          },
          onError: () => {
            enqueueSnackbar("Backend error while inserting timesheet!", {
              variant: "error",
            });
          },
        });
      }

    }
  };

  const handleOnSaveAndCreateInvoice = () => {
    const isValid = validateDetailedTimesheetForm();

    if (isValid) {
      const userID = timesheetDetailsQuery.data?.CurrentLocalUserId;
      // const timeUnits = Math.ceil(calculateTimeUnits(duration));
      const [hours, minutes, seconds] = duration.split(":").map(Number);

      const payloadDateTime = date?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01";

      // const payloadStartTime =
      //   startTime2?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";
      const payloadStartTime =
        startTime?.format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

      // const payloadEndTime =
      //   startTime2
      //     ?.add(hours, "hours")
      //     .add(minutes, "minutes")
      //     .add(seconds, "seconds")
      //     .format("YYYY-MM-DDTHH:mm:ss") || "0001-01-01T00:00:00";

      const payloadEndTime =
        endTime && endTime.isValid() ? endTime.format("YYYY-MM-DDTHH:mm:ss") :
          "0001-01-01T00:00:00";

      console.log("StartTime: ", payloadStartTime);
      console.log("EndTime: ", payloadEndTime);

      const payload: TimesheetInsertPayload = {
        acvType_avtID: actType!.avtID,
        acvUser_usrID: Number(userID) || 0,
        acvTimeUnits: timeUnits > 0 ? timeUnits : 0.1,
        acvDescription: description,
        acvNotes: note,
        acvTaskDate: payloadDateTime,
        acvStartTime: payloadStartTime,
        acvEndTime: payloadEndTime,
        acvInvoice_invID: 0, // TODO: get invoice id
        acvChargeableYN: isChargeable ? "Y" : "N",
        acvContact_cntID: client!.cntId,
        acvHourlyRate: hourlyRate,
        acvManualUnitsYN: "N",
        acvCharge: charge,
        acvOurCost: 0,
        acvTask_prdID: task?.prdID || 0,
        acvSite_dpsID: department?.dpsID || 0,
        acvPayroll_pevID: 0,
        acvPaidBreakUnits: 0,
        acvUnpaidBreakUnits: 0,
        acvDocument_docID: "",
        acvTransferGUID: "",
        acvEntrySource: "",
        acvForActionYN: markForAttention ? "Y" : "N",
        acvInvReadyYN: "Y",
      };

      if (currActivityId) {
        const updatePayload: TimesheetUpdatePayload = {
          ...payload,
          acvID: currActivityId,
        };
        updateTimesheet(updatePayload, {
          onSuccess: (data, variables, context) => {
            enqueueSnackbar("Timesheet updated successfully!", {
              variant: "success",
            });
          }
        })
      } else {
        insertTimesheet(payload, {
          onSuccess: (data, variables, context) => {
            enqueueSnackbar("Timesheet inserted successfully!", {
              variant: "success",
            });
            setIsDetailedFormOpen(false);
            resetForm();
          },
          onError: () => {
            enqueueSnackbar("Backend error while inserting timesheet!", {
              variant: "error",
            });
          },
        });
      }
    }
  };

  const handleOnCancel = () => {
    setDepartment(null);
    setTask(null);
    setIsDetailedFormOpen(false);
    // setStartTime2(null);
    // setStartTime(null);
    setIsChargeable(false);
    setCharge(0);
    setHourlyRate(0);
    setMarkForAttention(false);
    setNote("");

    if (timesheetDetailsQuery.isSuccess && timesheetDetailsQuery.data) {
      const userFromDetails = timesheetDetailsQuery.data.ListOfLocalUsers.find(
        (user) => timesheetDetailsQuery.data.CurrentLocalUserId === user.usrID,
      );

      if (userFromDetails) {
        setUser(userFromDetails);
      }
    }
  };

  const handleOnClearAll = () => {
    resetForm();
    setIsDetailedFormOpen(false);
    setCurrActivityId(null);
    // setStartTime2(null);
    // setStartTime(null);
  }

  const handleStartNewEntry = useCallback((timesheetRow: any) => {
    console.log("timesheetRow", timesheetRow);
    setIsTimerRunning(false);
    setDuration("00:00:00");
    // setCurrActivityId(timesheetRow.acvID);

    setDescription(timesheetRow.acvDescription);

    const client = contactsQuery.data?.ListOfContacts.find(
      (contact) => contact.cntCode === timesheetRow.cntCode,
    );

    setClient(client || null);

    const actType = timesheetDetailsQuery.data?.ListOfActivityTypes.find(
      (type) => type.avtCode === timesheetRow.avtCode,
    );

    setActType(actType || null);

    // Keep the same description, client, and activity type
    // but trigger the timer start
    setForceTriggerStart(true);

    // Reset the trigger after a short delay
    // setTimeout(() => {
    //   setForceTriggerStart(false);
    // }, 100);
  }, []);

  // Time calculations
  React.useEffect(() => {
    if (startTime && endTime && timesheetDetailsQuery.data?.TimeUnit) {
      const diffInMinutes = endTime.diff(startTime, "minute");
      if (diffInMinutes > 0) {
        // Calculate time units based on backend TimeUnit value
        // TimeUnit represents how many units are in 1 hour
        const timeUnitsPerHour = timesheetDetailsQuery.data.TimeUnit;
        setTimeUnits((diffInMinutes * 60) / timeUnitsPerHour);
      }
    }
  }, [startTime, endTime, timesheetDetailsQuery.data?.TimeUnit]);

  React.useEffect(() => {
    if (timesheetDetailsQuery.isSuccess && timesheetDetailsQuery.data) {
      const userFromDetails = timesheetDetailsQuery.data.ListOfLocalUsers.find(
        (user) => timesheetDetailsQuery.data.CurrentLocalUserId === user.usrID,
      );

      if (userFromDetails) {
        setUser(userFromDetails);
      }
    }
  }, [timesheetDetailsQuery.data, timesheetDetailsQuery.isSuccess]);

  const getActivityTypeNameById = useCallback(
    (id: number) => {
      const actTypesList = timesheetDetailsQuery.data?.ListOfActivityTypes || [];
      const activityType = actTypesList.find((type) => type.avtID === id);
      return activityType?.avtCode || `${id}`;
    },
    [timesheetDetailsQuery.data?.ListOfActivityTypes],
  );

  const contactsOptions = contactsQuery.data?.ListOfContacts || [];
  const actTypesOptions = timesheetDetailsQuery.data?.ListOfActivityTypes || [];
  const departmentsOptions = timesheetDetailsQuery.data?.ListOfSites || [];
  const usersOptions = timesheetDetailsQuery.data?.ListOfLocalUsers || [];
  const tasksOptions = [];

  console.log("--------------------------------");
  console.log("isTimerRunning", isTimerRunning);
  console.log("startTime", startTime);
  console.log("endTime", endTime);
  console.log("duration", duration);
  console.log("--------------------------------");
  console.log("timesheetDetailsQuery.data", timesheetDetailsQuery.data);
  console.log("user", user);
  console.log("description", description);
  console.log("--------------------------------");

  const sortedTimesheetList = React.useMemo(() => {
    return dailyListQuery.data?.ListOfDailyTimesheet.sort((a, b) => {
      return dayjs(a.acvStartTime).isBefore(dayjs(b.acvStartTime)) ? -1 : 1;
    });
  }, [dailyListQuery.data?.ListOfDailyTimesheet]);

  return (
    <>
      <BackdropLoading
        open={
          contactsQuery.isLoading ||
          isLoadingDelete ||
          dailyListQuery.isLoading
        }
      />
      <TimesheetHeader
        date={date}
        onChangeDate={handleDateChange}
        totalUnits={100}
        missingUnits={10}
        databaseName="EXACC"
      />
      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={{ xs: 0, md: 4 }}
        boxShadow={{
          xs: "none",
          md: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        }}
        borderRadius={2}
        marginY={4}
      >
        {!isDetailedFormOpen ? (
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexDirection={{ xs: "column", sm: "row" }}
            gap={2}
          >
            <TimesheetActions
              onClickNewTimesheet={() => setIsDetailedFormOpen(true)}
              onClickActivityTypeSettings={() => { }}
              disableNewTimesheet={isTimerRunning}
              currActivityId={currActivityId}
            // disableNewTimesheet={false}
            />
            <TimesheetDailyForm
              activityType={actType}
              activityTypesOptions={actTypesOptions}
              onActivityTypeChange={handleActivityTypeChange}
              description={description}
              onDescriptionChange={handleDescriptionChange}
              clientsOptions={contactsOptions}
              client={client}
              onClientChange={handleClientChange}
              disabled={false}
              onStartTimer={handleStartTimer}
              onResumeTimer={handleResumeTimer}
              onStopTimer={handleStopTimer}
              onFinishTimer={handleFinishTimer}
              onTimerDeleted={handleTimerDeleted}
              beforeOnStart={validateTimesheetForm}
              beforeOnFinish={validateTimesheetForm}
              descriptionError={errors.description}
              clientError={errors.client}
              activityTypeError={errors.activityType}
              durationError={errors.duration}
              duration={duration}
              forceTriggerStart={forceTriggerStart}
              setForceTriggerStart={setForceTriggerStart}
            />
          </Box>
        ) : (
          <TimesheetDetailedForm
            client={client}
            contacts={contactsOptions}
            onClientChange={handleClientChange}
            user={user}
            users={usersOptions}
            onUserChange={handleUserChange}
            department={department}
            departments={departmentsOptions}
            onDepartmentChange={handleDepartmentChange}
            activityType={actType}
            activityTypes={actTypesOptions}
            onActivityTypeChange={handleActivityTypeChange}
            description={description}
            onDescriptionChange={handleDescriptionChange}
            task={task}
            onTaskChange={handleTaskChange}
            tasks={tasksOptions}
            charge={charge}
            onChargeChange={handleChargeChange}
            hourlyRate={hourlyRate}
            onHourlyRateChange={handleHourlyRateChange}
            isChargeable={isChargeable}
            onChargeableChange={handleChargeableChange}
            note={note}
            onNoteChange={handleNoteChange}
            markForAttention={markForAttention}
            onMarkForAttentionChange={handleMarkForAttentionChange}
            date={date}
            onDateChange={handleDateChange}
            startTime={startTime}
            onStartTimeChange={handleStartTimeChange}
            endTime={endTime}
            onEndTimeChange={handleEndTimeChange}
            timeUnits={timeUnits}
            setTimeUnits={setTimeUnits}
            onSave={handleOnSave}
            onSaveAndNew={handleOnSaveAndNew}
            onSaveAndCreateInvoice={handleOnSaveAndCreateInvoice}
            onCancel={handleOnCancel}
            onClearAll={handleOnClearAll}
            errors={errors}
            isTimerRunning={isTimerRunning}
          />
        )}
        {dailyListQuery.data && (
          <Box marginY={4}>
            <TimesheetList
              timesheetList={sortedTimesheetList}
              onDeleteTimesheet={handleDeleteTimesheet}
              getActivityTypeNameById={getActivityTypeNameById}
              onStartNewEntry={handleStartNewEntry}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
