import React, { forwardRef, useRef } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Calendar } from 'lucide-react';
import { DateInputProps } from './types';

export const DateInput = forwardRef<HTMLDivElement, DateInputProps>(
  ({ value, onChange, label, onOpenCalendar, error, disabled }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputClick = (e: React.MouseEvent) => {
      if (!disabled) {
        e.stopPropagation(); // Prevent event bubbling
        onOpenCalendar();
        setTimeout(() => {
          inputRef.current?.focus();
        }, 1);
      }
    };

    return (
      <TextField
        size="small"
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onClick={handleInputClick}
        error={error}
        disabled={disabled}
        placeholder="DD/MM/YYYY"
        inputRef={inputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleInputClick(e);
                }}
                edge="end"
                disabled={disabled}
              >
                <Calendar size={18} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        ref={ref}
      />
    );
  }
);

export default DateInput;
