import React from "react";
import { ItemClickEvent } from "devextreme/ui/context_menu";
import { Box } from "@mui/material";
import { DataGrid } from "devextreme-react";
import { useInterfacePreference } from "../../../../hooks/use-interface-preference";
import { Column } from "devextreme-react/cjs/data-grid";
import { ActionMenu } from "../../../../components/ui/table/action-menu";
import { YNToBool } from "../../../../utils/format-bool";
import { getAlignment } from "../../../../helpers/table";
import { renderGeneralTableCell } from "../../review-transactions/pages/review-transactions-page";
import { BankFeedsHistoryColumn, BankFeedsHistoryTransaction } from "../types";
import { MarkTransactionAsEodPayload } from "../api/bank-feeds-history-mark-transactions-as-eod";
import { useSnackbar } from "notistack";

interface BankFeedsHistoryGridProps {
  columns: BankFeedsHistoryColumn[];
  data: BankFeedsHistoryTransaction[];
  tooltips: any[];
  actions: any[];
  markTransactionAsEod: any;
  unmarkTransactionAsEod: any;
}

const BankFeedsHistoryGrid = ({ columns, data, tooltips, actions, markTransactionAsEod, unmarkTransactionAsEod }: BankFeedsHistoryGridProps) => {
  const interfacePreference = useInterfacePreference();
  const { enqueueSnackbar } = useSnackbar(); 

  const onActionMenuItemClick = async (e: ItemClickEvent<any>, data: any) => {
    // @ts-ignore - they have fuckup types
    const action = e.itemData?.actionType;
    // @ts-ignore - they have fuckup types


    if (action === "MARK_AS_EOD_BALANCE") {
      markTransactionAsEod({ FeedID: data.bftID }, {
        onSuccess: (data, variables, context) => {
          enqueueSnackbar("Transaction marked as EOD balance successfully!", {
            variant: "success",
          });
        },
        onError: (error) => {
          console.log("error", error);
          enqueueSnackbar("Error: " + error?.message, {
            variant: "error",
          });
        },
      });
    }

    if (action === "REMOVE_EOD_BALANCE") {
      unmarkTransactionAsEod({ FeedID: data.bftID }, {
        onSuccess: (data, variables, context) => {
          enqueueSnackbar("Transaction unmarked as EOD balance successfully!", {
            variant: "success",
          });
        },
        onError: (error) => {
          console.log("error", error);
          enqueueSnackbar("Error: " + error?.message, {
            variant: "error",
          });
        },
      });
    } 

    console.log("e", e);
  }

  const actionsList = [...actions].map((action) => {
    return {
      text: action?.DisplayText,
      actionType: action?.ActionType,
      ...action,
    };
  });

  const createActionsList = React.useCallback((currentActions: any[] , data: any) => {
    const createdActions = {
      text: data?.EOD === "Yes" ? "Remove EOD Balance" : "Mark as End of Day Balance",
      actionType: data?.EOD === "Yes" ? "REMOVE_EOD_BALANCE" : "MARK_AS_EOD_BALANCE",
    } as any;

    return [...currentActions, createdActions];
  }, [])

  const displayActions = actionsList.length >= 0;

  return (
    <Box mt={4} width={"100%"}>
      <DataGrid
        dataSource={data}
        showBorders
        allowColumnResizing
        columnAutoWidth
        rowAlternationEnabled
      // onRowClick={handleRowClick}
      >

        {displayActions && interfacePreference === "left" && (
          <Column
            width={65}
            caption=""
            fixed={true}
            alignment="left"
            cellRender={({ data }) => (
              <ActionMenu
                dataSource={createActionsList(actionsList, data)}
                onItemClick={(e) => onActionMenuItemClick(e, data)}
              />
            )}
          />
        )}

        {columns.map((column) => (
          <Column
            key={column.RpcID}
            dataField={column.RpcDataSource}
            caption={column.RpcHeader}
            allowResizing={YNToBool(column.RpcAllowResizing)}
            allowFiltering={YNToBool(column.RpcFilter)}
            alignment={getAlignment(column.RpcAlign)}
            allowSearch={YNToBool(column.RpcSearchable)}
            allowSorting={YNToBool(column.RpcSort)}
            groupCellRender={(cellInfo) => cellInfo?.displayValue}
            groupIndex={
              column.RpcGroupBy === "Y" ||
                // @ts-ignore
                column.RpcGroupBy === "y"
                ? 0
                : undefined
            }
            cellRender={(cellInfo) =>
              renderGeneralTableCell(cellInfo, column)
            }
            visible={YNToBool(column.RpcVisible)}
            fixed={YNToBool(column.RpcFreeze)}
            minWidth={column.RpcMinWidth}
            width={column.RpcMaxWidth}
            allowGrouping={YNToBool(column.RpcGroupBy)}
          />


        ))}

        {displayActions && interfacePreference !== "left" && (
          <Column width={undefined} />
        )}

        {displayActions && interfacePreference !== "left" && (
          <Column
            width={65}
            caption=""
            alignment="right"
            cellRender={({ data }) => {
              return (
                <ActionMenu
                  dataSource={createActionsList(actionsList, data)}
                  onItemClick={(e) => onActionMenuItemClick(e, data)}
                />
              );
            }}
          />
        )}

        {!displayActions && <Column caption="" width={undefined} />}
        {displayActions && interfacePreference === "left" && (
          <Column caption="" width={undefined} />
        )}
      </DataGrid>
    </Box>
  )

}

export default BankFeedsHistoryGrid;