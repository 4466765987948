import React from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import BackdropLoading from "../../../components/BackdropLoading/BackdropLoading";
import { PageTitle } from "../../../components/page-title";
import { useInvoicePaymentDetails } from "../api/invoice-payment-details";
import { NewPaymentDetailsForm } from "../components/new-payment-details-form";
import {
  ContactDebtor,
  PaymentDetail,
  PaymentMethod,
  PostableAccount,
} from "../types/invoice-payment";
import {
  Contact,
  useListOfContacts,
} from "../../accounting/api/list-of-contacts";
import { Box, Button, Typography } from "@mui/material";
import { NewPaymentToolbar } from "../components/new-payment-toolbar";
import { NewPaymentDataGrid } from "../components/new-payment-data-grid";
import DataGrid from "devextreme-react/cjs/data-grid";
import { useSnackbar } from "notistack";
import { useSaveNewPayment, useSaveNewPayment2 } from "../api/save-new-payment";
import dayjs from "dayjs";
import { formatDateToISO } from "../../../utils/format-date";
import { useBankFeedsByFeedId } from "../api/bank-feeds-by-id";

export function SalesNewPaymentPage() {
  const navigate = useNavigate();
  const { dbId, lang } = useParams();

  const dataGridRef = React.useRef<DataGrid<PaymentDetail>>(null);

  const [amount, setAmount] = React.useState<number>(0);
  const [hasInitializedClient, setHasInitializedClient] = React.useState(false);

  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const clientId = searchParams.get("clientId") || undefined;
  const invoiceId = searchParams.get("invoiceId") || undefined;
  const paymentId = searchParams.get("paymentId") || undefined;
  const feedId = searchParams.get("feedId") || undefined;

  const [selectedClient, setSelectedClient] =
    React.useState<ContactDebtor | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    React.useState<PaymentMethod | null>(null);
  const [selectedAccount, setSelectedAccount] =
    React.useState<PostableAccount | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    dayjs().toDate(),
  );
  const [note, setNote] = React.useState<string>("");
  const [disableAfterFeedId, setDisableAfterFeedId] = React.useState(false);


  const [clientError, setClientError] = React.useState<string | null>(null);
  const [dateError, setDateError] = React.useState<string | null>(null);
  const [paymentMethodError, setPaymentMethodError] = React.useState<
    string | null
  >(null);
  const [accountError, setAccountError] = React.useState<string | null>(null);
  const [amountError, setAmountError] = React.useState<string | null>(null);

  const [paymentDetailsWithAllocations, setPaymentDetailsWithAllocations] =
    React.useState<(PaymentDetail & { allocated: number })[]>([]);

  // Memoize the clientId to prevent unnecessary refetches
  const effectiveClientId = React.useMemo(
    () => selectedClient?.cntID || clientId,
    [selectedClient?.cntID, clientId],
  );

  const { isLoading, data, refetch } = useInvoicePaymentDetails({
    invoiceId,
    clientId: effectiveClientId,
    paymentId,
    onSuccess: React.useCallback((data) => {
      setPaymentDetailsWithAllocations(
        data.PaymentDetails.map((detail) => ({
          ...detail,
          allocated: 0,
        })),
      );
    }, []), // Empty dependency array since we don't use any external values
  });

  console.log("data: ", data);

  const { isLoading: isLoadingBankFeed, data: bankFeedData, isSuccess: isSuccessBankFeed, isError: isErrorBankFeed, error: bankFeedError } = useBankFeedsByFeedId({
    feedId: feedId,
  });


  React.useEffect(() => {
    if (isSuccessBankFeed) {
      setDisableAfterFeedId(true);
      setAmount(bankFeedData?.Amount || 0);
      setSelectedDate(dayjs(bankFeedData?.TrDate).toDate());
      setSelectedAccount(data?.ListOfPostableAccounts.find(acc => acc.accNo === bankFeedData?.AccNo) || null);
      setNote(bankFeedData?.Description || "");
    }
  }, [isSuccessBankFeed]);

  React.useEffect(() => {
    if (isErrorBankFeed) {
      enqueueSnackbar(bankFeedError?.message || "An error occurred", { variant: "error" });
      setDisableAfterFeedId(false);
    }
  }, [isErrorBankFeed]);

  // const { isLoading: isLoadingContacts, data: contactsData } =
  //   useListOfContacts({});

  const { saveNewPayment, isLoading: isLoadingSave } = useSaveNewPayment({
    onSuccess: (response) => {
      enqueueSnackbar("Payment saved successfully!", { variant: "success" });
      if (response?.PaymentId) {
        clearForm();
        navigate(`/${dbId}/${lang}/sales/payment/${response.PaymentId}`);
      }
    },
  });

  const { saveNewPayment: saveNewPayment2, isLoading: isLoadingSave2 } =
    useSaveNewPayment2({
      onSuccess(response) {
        clearForm();
        enqueueSnackbar("Payment saved successfully!", { variant: "success" });
        // Clear search params after successful save
        searchParams.delete("clientId");
        searchParams.delete("invoiceId");
        searchParams.delete("feedId");

        setPaymentDetailsWithAllocations([]);
        refetch();

      },
    });

  const handleClientChange = (client: ContactDebtor | null) => {
    console.log("client", client);
    setClientError(null);
    if (client && !disableAfterFeedId) {
      setSelectedClient(client);
      setSelectedPaymentMethod(null);
      setSelectedAccount(null);
      setAmount(0);
    } else if (client && disableAfterFeedId) {
      setSelectedClient(client);
    }
  };

  const handleOnPaymentMethodChange = (method: PaymentMethod | null) => {
    setPaymentMethodError(null);
    setSelectedPaymentMethod(method);

    if (method && method.pmm_accNo > 0 && !disableAfterFeedId) {
      const account = data?.ListOfPostableAccounts.find(
        (acc) => acc.accNo === method.pmm_accNo,
      );
      setAccountError(null);
      setSelectedAccount(account || null);
    } else {
      if (!disableAfterFeedId) {
        setSelectedAccount(null);
      }
    }
  };

  const handleOnAccountChange = (account: PostableAccount | null) => {
    setAccountError(null);
    setSelectedAccount(account);
  };

  const handleOnDateChange = (date: Date | null) => {
    setDateError(null);
    setSelectedDate(date);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dataGridRef.current) {
      dataGridRef.current?.instance.searchByText(e.target.value);
    }
  };

  const handleSearchClear = () => {
    if (dataGridRef.current) {
      // @ts-ignore
      dataGridRef.current?.instance.clearSearch();
    }
  };

  const handleExport = () => {
    enqueueSnackbar("Not implemented yet", {
      variant: "info",
    });
  };

  const handlePaidAllInFull = () => {
    if (data?.PaymentDetails) {
      const totalAmount = data.PaymentDetails.reduce(
        (sum, detail) => sum + (detail.documentDueAmt || 0),
        0,
      );
      setAmount(totalAmount);
      setAmountError(null);
      setPaymentDetailsWithAllocations((prev) =>
        prev.map((detail) => ({
          ...detail,
          allocated: detail.documentDueAmt || 0,
        })),
      );
    }
  };

  const handleClearAll = () => {
    setAmount(0);
    setAmountError(null);
    setPaymentDetailsWithAllocations((prev) =>
      prev.map((detail) => ({ ...detail, allocated: 0 })),
    );
  };

  const handleAllocateInOrder = () => {
    if (amount <= 0) {
      enqueueSnackbar("Please enter payable amount first", {
        variant: "warning",
      });
      setAmountError("Please enter payable amount first");
      return;
    }

    let remainingAmount = amount;
    setPaymentDetailsWithAllocations((prev) =>
      prev.map((detail) => {
        if (remainingAmount <= 0) {
          return { ...detail, allocated: 0 };
        }
        const dueAmount = detail.documentDueAmt || 0;
        const allocation = Math.min(dueAmount, remainingAmount);
        remainingAmount -= allocation;
        return { ...detail, allocated: allocation };
      }),
    );
  };

  const handleAllocationChange = React.useCallback(
    (rowIndex: number, newValue: number) => {
      if (amount <= 0) {
        enqueueSnackbar("Please enter payable amount first", {
          variant: "warning",
        });
        setAmountError("Please enter payable amount first");
        return;
      }

      setPaymentDetailsWithAllocations((prev) => {
        const totalAllocatedExcludingCurrent = prev.reduce(
          (sum, detail, index) =>
            index === rowIndex ? sum : sum + detail.allocated,
          0,
        );
        const remainingAmount = amount - totalAllocatedExcludingCurrent;

        if (remainingAmount <= 0) {
          enqueueSnackbar("All payments have been allocated successfully!", {
            variant: "warning",
          });
          return prev;
        }

        const newAllocated = Math.min(Math.max(0, newValue), remainingAmount);
        return prev.map((detail, index) =>
          index === rowIndex ? { ...detail, allocated: newAllocated } : detail,
        );
      });

      setAmountError(null);
    },
    [enqueueSnackbar, amount],
  );

  const handleAmountChange = (newAmount: number) => {
    setAmount(newAmount);
    setAmountError(null);
  };

  const clearForm = () => {
    setSelectedClient(null);
    setSelectedDate(dayjs().toDate());
    setSelectedPaymentMethod(null);
    setSelectedAccount(null);
    setAmount(0);
    setNote("");
    setClientError(null);
    setDateError(null);
    setPaymentMethodError(null);
    setAccountError(null);
    setAmountError(null);
    handleClearAll();
    refetch();
    setDisableAfterFeedId(false);
  };

  const handleSave = async (shouldClear: boolean = false) => {
    let hasError = false;

    if (!selectedClient) {
      setClientError("Client is required!");
      hasError = true;
    }

    if (!selectedDate) {
      setDateError("Date is required!");
      hasError = true;
    }

    if (!selectedPaymentMethod) {
      setPaymentMethodError("Payment method is required!");
      hasError = true;
    }

    if (!selectedAccount) {
      setAccountError("Account is required!");
      hasError = true;
    }

    if (amount <= 0) {
      setAmountError("Amount must be greater than 0!");
      hasError = true;
    }

    if (hasError) {
      enqueueSnackbar("Please fill in all required fields!", {
        variant: "warning",
      });
      return;
    }

    const payload = {
      PaymentHeader: {
        // @ts-ignore
        pmtContact_cntID: selectedClient.cntID,
        pmtDate: formatDateToISO(selectedDate) || "",
        pmtAmount: amount,
        // @ts-ignore
        pmtMethod_pmmID: selectedPaymentMethod.pmmID,
        // @ts-ignore
        pmtAccount_accNo: selectedAccount.accNo,
        pmtNotes: note,
        pmtReconciled_rcnID: 0,
        pmtBankFeed_bftID: "",
      },
      ListOfPaymentDetails: paymentDetailsWithAllocations
        .filter((detail) => detail.allocated > 0)
        .map((detail) => ({
          documentId: detail.documentId,
          documentPayableAmt: detail.allocated,
        })),
    };

    if (shouldClear) {
      saveNewPayment2(payload);
    } else {
      saveNewPayment(payload);
    }
  };

  const totalDueAmount = React.useMemo(() => {
    return (
      data?.PaymentDetails.reduce(
        (sum, detail) => sum + (detail.documentDueAmt || 0),
        0,
      ) || 0
    );
  }, [data?.PaymentDetails]);

  React.useEffect(() => {
    const allowedParams = ["clientId", "invoiceId", "feedId"];
    const searchParamsObj = Object.fromEntries(searchParams.entries());
    const invalidParams = Object.keys(searchParamsObj).filter(
      (param) => !allowedParams.includes(param),
    );

    if (invalidParams.length > 0) {
      enqueueSnackbar(
        `Invalid search parameters: ${invalidParams.join(", ")}`,
        { variant: "error" },
      );
    }
  }, [searchParams, enqueueSnackbar]);

  // Initialize client only once when data is first loaded
  React.useEffect(() => {
    console.log("USE EFFECT FOR SETTING A CLIENT CALLED: ", clientId, data);
    if (!data?.ListOfContactDebtors || hasInitializedClient) return;

    let clientToSet: ContactDebtor | null | undefined = null;
    if (clientId) {
      clientToSet = data.ListOfContactDebtors.find(
        (contact) => contact.cntID === Number(clientId),
      );
      if (!clientToSet) {
        setClientError(`Client with id ${clientId} not found from clientId`);
      }
    } else if (
      data.PaymentHeader?.pmtContact_cntID &&
      data.PaymentHeader.pmtContact_cntID !== 0
    ) {
      clientToSet = data.ListOfContactDebtors.find(
        (contact) => contact.cntID === data.PaymentHeader.pmtContact_cntID,
      );
      if (!clientToSet) {
        setClientError(
          `Client with id ${data.PaymentHeader.pmtContact_cntID} not found from payment header`,
        );
      }
    }
    if (clientToSet) {
      setSelectedClient(clientToSet);
    }
    setHasInitializedClient(true);
  }, [data?.ListOfContactDebtors, clientId, hasInitializedClient]);

  console.log("selectedClient", selectedClient);

  return (
    <>
      <BackdropLoading open={isLoading || isLoadingSave || isLoadingSave2 || isLoadingBankFeed} />
      <PageTitle title="New Payment" />

      <NewPaymentDetailsForm
        clients={data?.ListOfContactDebtors || []}
        paymentMethods={data?.ListOfPaymentMethods || []}
        postableAccounts={data?.ListOfPostableAccounts || []}
        selectedClient={selectedClient}
        selectedPaymentMethod={selectedPaymentMethod}
        selectedAccount={selectedAccount}
        selectedDate={selectedDate}
        amount={amount}
        amountError={amountError}
        note={note}
        onClientChange={handleClientChange}
        onPaymentMethodChange={handleOnPaymentMethodChange}
        onAccountChange={handleOnAccountChange}
        onDateChange={handleOnDateChange}
        onAmountChange={handleAmountChange}
        onNoteChange={setNote}
        onDetailedSearch={() => { }}
        onPaidAllInFull={handlePaidAllInFull}
        onAllocateInOrder={handleAllocateInOrder}
        onClearAll={handleClearAll}
        clientError={clientError}
        dateError={dateError}
        paymentMethodError={paymentMethodError}
        accountError={accountError}
        totalDueAmount={totalDueAmount}
        disableFeedIdRelatedFields={disableAfterFeedId}
      />

      <Box
        display="flex"
        flexDirection="column"
        padding={4}
        boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
        borderRadius={2}
        marginY={4}
      >
        <Typography variant="subtitle1" color="secondary" marginBottom={3.5}>
          Invoice Payment List
        </Typography>
        <NewPaymentToolbar
          onSearchChange={handleSearchChange}
          onSearchClear={handleSearchClear}
          onExport={handleExport}
        />

        <NewPaymentDataGrid
          ref={dataGridRef}
          paymentDetails={paymentDetailsWithAllocations}
          onAllocationChange={handleAllocationChange}
          amount={amount}
        />
      </Box>

      <Box display="flex" gap={2} marginBottom={4}>
        <Button
          variant="contained"
          onClick={() => handleSave(false)}
          size="large"
        >
          Save
        </Button>
        <Button
          variant="contained"
          onClick={() => handleSave(true)}
          size="large"
        >
          Save & New
        </Button>
      </Box>
    </>
  );
}
