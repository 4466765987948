import { Autocomplete, Popper, TextField } from "@mui/material";
import React from "react";
import { List, ListRowProps } from "react-virtualized";

interface ListboxComponentProps {
  children: React.ReactNode;
  role?: string;
  listWidth?: number | string;
  [key: string]: any;
}

const PopperComponent = function (props: React.ComponentProps<typeof Popper>) {
  return <Popper {...props} style={{ width: "fit-content" }} placement="bottom-start" />;
};

const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  ListboxComponentProps
>(function ListboxComponent(props, ref) {
  const { children, role, width, listWidth, itemSize = 48, ...other } = props;
  const itemCount = Array.isArray(children) ? children.length : 0;


  // Add ref to outer container to calculate actual width
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [calculatedWidth, setCalculatedWidth] = React.useState(0);

  React.useEffect(() => {
    if (containerRef.current) {
      setCalculatedWidth(containerRef.current.offsetWidth);
    }
  }, [width]);

  const finalWidth = listWidth || (typeof width === "number" ? width : calculatedWidth);

  return (
    <div ref={ref} className="w-full">
      <div {...other} ref={containerRef} style={{ width: finalWidth }}>
        <List
          height={250}
          width={finalWidth}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={(listRowProps: ListRowProps) => {
            if (!children) {
              return null;
            }
            return React.cloneElement(children[listRowProps.index], {
              style: listRowProps.style,
            });
          }}
          role={role}
        />
      </div>
    </div>
  );
});

interface VirtualizedAutocompleteProps {
  options: any[];
  getOptionLabel: (option: any) => string;
  onChange: (event: any, value: any) => void;
  value?: any;
  label?: string;
  width?: number | string;
  listWidth?: number | string;
  otherAutoCompleteProps?: any;
  error?: boolean;
  helperText?: string;
  selectFirst?: boolean;
  itemSize?: number;
  renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: any) => React.ReactNode;
}

export function VirtualizedAutocomplete({
  options,
  getOptionLabel,
  onChange,
  value,
  label,
  width = 300,
  listWidth,
  otherAutoCompleteProps = {},
  error,
  helperText,
  selectFirst = true,
  itemSize = 48,
  renderOption,
}: VirtualizedAutocompleteProps) {
  return (
    <Autocomplete
      id="virtualize-demo"
      style={{ width }}
      disableListWrap
      // open={true}
      PopperComponent={PopperComponent}
      ListboxComponent={ListboxComponent}
      ListboxProps={{ width, listWidth, itemSize }}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      value={value}
      autoHighlight={selectFirst}
      autoSelect={selectFirst}
      renderOption={renderOption}
      isOptionEqualToValue={(option, value) => 
        option && value && option === value
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          fullWidth
          error={!!error}
          helperText={helperText}
        />
      )}
      {...otherAutoCompleteProps}
    />
  );
}
