import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { axiosService } from "../../../../api/axios";
import envConfig from "../../../../config";
import { useLoginPopup } from "../../../../context/LoginPopupContext";
import { AccountWithTaxCode, Contact, Site, TaxCode } from "../types";

export interface ProcessTransactionsDefaultResponse {
  ErrorMessage: string;
  SuccessYN: string;
  ListOfAccounts: AccountWithTaxCode[];
  ListOfSites: Site[];
  ListOfContacts: Contact[];
  ListOfTaxCodes: TaxCode[];
}

const EXAMPLE_RESPONSE: ProcessTransactionsDefaultResponse = {
  ErrorMessage: "",
  SuccessYN: "Y",
  ListOfAccounts: [
    {
      accNo: 1000,
      accName: "Savings Account",
      accDefTxc_txcID: 4,
      accDefPriv: 0,
      accPostableYN: "Y",
      accActiveYN: "Y",
      accType: "Current Assets"
    }
  ],
  ListOfSites: [
    {
      dpsID: 1,
      dpsCode: "AA",
      dpsName: "Australian Army"
    }
  ],
  ListOfContacts: [
    {
      cntCode: "BTMH",
      cntName: "Business Tax Money House",
      cntId: 1
    }
  ],
  ListOfTaxCodes: [
    {
      txcID: 4,
      txcName: "NON-Taxable",
      txcRate: 0,
      txcCode: "NONTX"
    }
  ]
};

export const useProcessTransactionsDefault = () => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();

  return useQuery({
    queryKey: ['process-transactions-defaults', dbId, lang],
    retry: 2,
    queryFn: async () => {
      if (process.env.NODE_ENV === 'development') {
        return Promise.resolve(EXAMPLE_RESPONSE);
      }

      const searchParams = new URLSearchParams();
      searchParams.append("BaseHostURL", envConfig.mainServiceUrl);

      const response = await axiosService.request<ProcessTransactionsDefaultResponse>({
        dbId: dbId,
        method: 'GET',
        baseURL: envConfig.apiDev1Exacc,
        endpoint: `/api/v1/${lang}/bank-feeds/process-defaults-async?${searchParams.toString()}`,
        reAuthenticate: reAuthenticate,
      });

      return response;
    }
  });
};
