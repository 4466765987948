import { Box, Stack } from "@mui/material";
import DataGrid, { Column, Selection } from "devextreme-react/cjs/data-grid";
import React, { forwardRef } from "react";
import { AccountWithTaxCode, Contact, Site, TaxCode, UnprocessedTransactions } from "../types";
import { UnprocessedTransactionsTableRow } from "./process-transactions-table-row";

interface ProcessTransactionsDataGridProps {
  data: UnprocessedTransactions[];
  selectedRowKeys: String[];
  onSelectionChanged?: (e: any) => void;
  handleClearFilters: () => void;
  // dependencies
  listOfTaxCodes: TaxCode[];
  listOfAllSites: Site[];
  listOfContacts: Contact[];
  listOfAccounts: AccountWithTaxCode[];
}

export const ProcessTransactionsDataGrid = forwardRef<
  DataGrid,
  ProcessTransactionsDataGridProps
>(({ data, onSelectionChanged, selectedRowKeys, handleClearFilters, listOfTaxCodes, listOfAllSites, listOfContacts, listOfAccounts }) => {
  console.log(data, selectedRowKeys);

  if (!data.length) {
    return (
      <Stack
        py={2}
        alignItems="center"
        justifyContent="center"
        boxShadow="0px 0px 10px 0px rgba(69, 90, 100, 0.10)"
        marginTop={3}
        height={"calc(100vh - 300px)"}
        paddingTop={2}
        sx={{
          transition: "all 1s ease",
        }}
      >
        <span>No Transactions found</span>
      </Stack>
    );
  }

  return (
    <Box>
      {data.map((transaction) => (
        <UnprocessedTransactionsTableRow
          key={transaction.ID}
          data={transaction}
          transactions={data}
          handleClearFilters={handleClearFilters}
          selectedRowKeys={selectedRowKeys}
          onSelectionChanged={
            onSelectionChanged ? onSelectionChanged : () => {}
          }
          // dependencies
          listOfTaxCodes={listOfTaxCodes}
          listOfAllSites={listOfAllSites}
          listOfContacts={listOfContacts}
          listOfAccounts={listOfAccounts}
        />
      ))}
    </Box>
  );
});
