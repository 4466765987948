import React, { useEffect, useState, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import DataGrid, {
  Column,
  Editing,
  Summary,
  TotalItem,
  Selection,
  Pager,
  Paging,
  Scrolling,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DOMPurify from "dompurify";
import Popup from "devextreme-react/popup";
import Button from "devextreme-react/button";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { Box, Typography, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ContextMenu from "devextreme-react/context-menu";
import { useSnackbar } from "notistack";
import envConfig from "../../config";
import FilesUploadedPopup from "./FilesUploadedPopup";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import BackdropLoading from "../BackdropLoading/BackdropLoading";
import { useGetIP } from "../../hooks/get-ip";
import useApi from "../../hooks/useApi";
import ReportDatesContext from "../../context/ReportDatesContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../utils/api";
import AddAccountPopup from "../GeneralJournal/AddAccountPopup";
import JournalSavedPopup from "./JournalSavedPopup";
import {
  createSessionForExternal,
  parseQueryParams,
} from "../../utils/reusable";
import { UploadFile } from "@mui/icons-material";
import { FileUploader } from "devextreme-react";

const BankJournalGrid = ({
  formState,
  headerData,
  initialData,
  listOfAccounts,
  listOfSites,
  listOfTaxCodes,
  showPrivatePortion,
  showVaryManualTax,
  isReceiveMoney,
  isSpendMoney,
  isAmountNet,
  reloadPage,
  setShowPrivatePortion,
  loadingData,
}) => {
  const largeScreen = useMediaQuery("(min-width: 1160px)");
  const breakpoint = useMediaQuery("(min-width: 1320px)");
  const { journalId, dbId } = useParams();
  const navigate = useNavigate();
  const { apiCall } = useApi();
  const ip = useGetIP();
  const { enqueueSnackbar } = useSnackbar();
  const [gridData, setGridData] = useState(initialData);
  const [isDeleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [isImportPopupVisible, setIsImportPopupVisible] = useState(false);
  const fileInputRef = useRef(null);
  const dataGridRef = useRef(null);
  const [openFilesPopup, setOpenFilesPopup] = useState(false);
  const [openAddAccountPopup, setOpenAddAccountPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const { interfacePreference, pageMainData } = useContext(ReportDatesContext);
  const [selectedFileName, setSelectedFileName] = useState("");

  const [accounts, setAccounts] = useState([]);

  const [popupOpen, setPopupOpen] = useState(false);
  const [savedJournalId, setSavedJournalId] = useState(null);
  const location = useLocation();
  const queryParams = parseQueryParams(location.search);

  const targetAmount =
    formState.feedAmount || parseFloat(queryParams.amount) || 0;
  const [totalAmount, setTotalAmount] = useState(0);
  const [amountDifference, setAmountDifference] = useState(0);

  // useEffect(() => {
  //   const total = gridData.reduce(
  //     (sum, item) => sum + (item.jnsGrossAmt || 0),
  //     0,
  //   );
  //   if (total) {
  //     setGridData([
  //       ...Array(5)
  //         .fill({})
  //         .map((_, index) => ({
  //           jnsID: index + 1,
  //           jnsAccount_accNo: "",
  //           jnsGrossAmt: null,
  //           jnsTaxCode_txcID: "",
  //           jnsPrivPc: null,
  //           jnsReviewYN: "N",
  //           jnsVaryTaxYN: "N",
  //           jnsTaxAmt: null,
  //           jnsPrivAmt: null,
  //           jnsNetBusAmt: null,
  //           jnsSite_dpsID: "",
  //         })),
  //     ]);
  //     handleAddRows();
  //   }
  // }, [location.key]);

  const setValue = (value, field, key) => {
    setGridData((prevData) =>
      prevData.map((item) => {
        if (item.jnsID === key) {
          const updatedItem = { ...item, [field]: value };

          if (field === "jnsGrossAmt" || field === "jnsTaxCode_txcID") {
            updatedItem.jnsTaxAmt = calculateTaxAmount(updatedItem);
          }
          if (field === "jnsPrivPc" || field === "jnsGrossAmt") {
            updatedItem.jnsPrivAmt = calculatePrivateAmount(updatedItem);
          }
          updatedItem.jnsNetBusAmt = calculateNetBusAmt(updatedItem);

          if (field === "jnsVaryTaxYN") {
            updatedItem.jnsVaryTaxYN = value ? "Y" : "N";
          }
          if (field === "jnsReviewYN" && value !== null) {
            updatedItem.jnsReviewYN = value ? "Y" : "N";
          }
          return updatedItem;
        }
        return item;
      }),
    );
  };

  const calculateTaxAmount = (rowData) => {
    const PrivatePortion =
      rowData.jnsPrivPc != null && rowData.jnsPrivPc >= 0
        ? rowData.jnsPrivPc / 100
        : 0;

    let TaxAmount = 0;
    let Amount = rowData.jnsGrossAmt || 0;

    const TaxRateID =
      rowData.jnsTaxCode_txcID != null && rowData.jnsTaxCode_txcID > 0
        ? rowData.jnsTaxCode_txcID
        : 0;
    let TaxRate = 0;
    if (TaxRateID > 0) {
      const taxCode = listOfTaxCodes?.find((tc) => tc.txcID === TaxRateID);
      if (taxCode) {
        TaxRate = parseFloat(taxCode.txcRate);
      }
    }

    const taxVar =
      rowData != null && rowData.jnsVaryTaxYN != null
        ? rowData.jnsVaryTaxYN
        : "N";

    if (isAmountNet) {
      // Gross = Amount * (1 + {TaxRate from Tax Code selection}) (round)
      const GrossAmount = Math.round(Amount * (1 + TaxRate));
      // Priv = Gross * Priv% (round)
      const PrivateAmount = Math.round(GrossAmount * PrivatePortion);
      // Tax = (Gross - Priv) * {TaxRate from Tax Code selection} (round)
      TaxAmount = Math.round((GrossAmount - PrivateAmount) * TaxRate);
    } else {
      if (taxVar === "Y") {
        TaxAmount =
          rowData != null && rowData.jnsTaxAmt != null ? rowData.jnsTaxAmt : 0;
      } else {
        TaxAmount = (Amount - Amount / (1 + TaxRate)) * (1 - PrivatePortion);
      }
    }

    rowData.jnsTaxAmt = TaxAmount;

    return TaxAmount; // Return just the TaxAmount
  };

  const calculatePrivateAmount = (item) => {
    const baseAmount = item.jnsGrossAmt || 0;
    const privatePortion = (item.jnsPrivPc || 0) / 100;

    if (isAmountNet) {
      // Gross = Amount * (1 + {TaxRate from Tax Code selection}) (round to 2 decimals)
      const GrossAmount = parseFloat(
        (
          baseAmount *
          (1 +
            parseFloat(
              listOfTaxCodes?.find((tc) => tc.txcID === item.jnsTaxCode_txcID)
                ?.txcRate || 0,
            ))
        ).toFixed(2),
      );
      // Priv = Gross * Priv% (round to 2 decimals)
      if (GrossAmount * privatePortion > 0) {
        setShowPrivatePortion(true);
      }
      return parseFloat((GrossAmount * privatePortion).toFixed(2));
    } else {
      if (baseAmount * privatePortion > 0) {
        setShowPrivatePortion(true);
      }
      return parseFloat((baseAmount * privatePortion).toFixed(2));
    }
  };

  const calculateNetBusAmt = (item) => {
    const baseAmount = item.jnsGrossAmt || 0;
    const taxAmount = calculateTaxAmount(item);
    const privateAmount = calculatePrivateAmount(item);

    let netBusAmt = 0;

    if (isAmountNet) {
      const GrossAmount = Math.round(
        baseAmount *
          (1 +
            parseFloat(
              listOfTaxCodes?.find((tc) => tc.txcID === item.jnsTaxCode_txcID)
                ?.txcRate || 0,
            )),
      );
      netBusAmt = GrossAmount - privateAmount - taxAmount;
    } else {
      netBusAmt = baseAmount - taxAmount - privateAmount;
    }

    return parseFloat(netBusAmt.toFixed(2));
  };

  // const recalculateAmounts = (journalType) => {
  //   if (journalType != null) {
  //     const newGridData = gridData?.map((item) => {
  //       const jnsTaxAmt = item.jnsTaxAmt || 0;
  //       const txtPrivAmt = item.jnsPrivAmt || 0;
  //       const txtNetBusAmt = item.jnsNetBusAmt || 0;
  //       const jnsGrossAmt = item.jnsGrossAmt || 0;

  //       if (jnsGrossAmt !== null && jnsGrossAmt !== 0) {
  //         if (journalType === "G") {
  //         } else if (journalType === "N") {
  //         }
  //         return { ...item, jnsGrossAmt: NewGrossAmt };
  //       }
  //       return item;
  //     });

  //     setGridData(newGridData);
  //   }
  // };

  const recalculateAmounts = (journalType) => {
    if (journalType != null) {
      const newGridData = gridData?.map((item) => {
        const updatedItem = { ...item };

        // Recalculate Tax Amount
        updatedItem.jnsTaxAmt = calculateTaxAmount(updatedItem);

        // Recalculate Private Amount
        updatedItem.jnsPrivAmt = calculatePrivateAmount(updatedItem);

        // Recalculate Net Business Amount
        updatedItem.jnsNetBusAmt = calculateNetBusAmt(updatedItem);

        return updatedItem;
      });

      setGridData(newGridData);
    }
  };

  useEffect(() => {
    recalculateAmounts(isAmountNet ? "N" : "G");
  }, [isAmountNet]);

  useEffect(() => {
    const total = gridData.reduce(
      (sum, item) => sum + (item.jnsGrossAmt || 0),
      0,
    );
    setTotalAmount(total);
    setAmountDifference(total - targetAmount);
  }, [gridData, targetAmount]);

  // useEffect(() => {
  //   if (gridData?.length < 5) {
  //     const additionalRows = 5 - Number(gridData?.length);
  //     const newRows = Array.from({ length: additionalRows }, (_, i) => ({
  //       jnsID: `empty-${gridData.length + i + 1}`,
  //       jnsAccount_accNo: "",
  //       jnsGrossAmt: null,
  //       jnsTaxCode_txcID: "",
  //       jnsPrivPc: null,
  //       jnsReviewYN: "N",
  //       jnsVaryTaxYN: "N",
  //       jnsTaxAmt: null,
  //       jnsPrivAmt: null,
  //       jnsNetBusAmt: null,
  //       jnsSite_dpsID: "",
  //       jnsOrder: Number(gridData?.length) + i + 1,
  //     }));
  //     setGridData((prevData) => [...prevData, ...newRows]);
  //   }
  // }, [gridData]);
  useEffect(() => {
    if (loadingData) return;
    if (gridData?.length) return;
    if (initialData) {
      const sortedInitialData = initialData.sort(
        (a, b) => a.jnsOrder - b.jnsOrder,
      );

      let updatedData = [...sortedInitialData];

      if (updatedData.length < 5) {
        const additionalRows = 5 - updatedData.length;
        const emptyRows = Array.from({ length: additionalRows }, (_, i) => ({
          jnsID: `empty-${updatedData.length + i + 1}`,
          jnsAccount_accNo: "",
          jnsGrossAmt: null,
          jnsTaxCode_txcID: "",
          jnsPrivPc: null,
          jnsReviewYN: "N",
          jnsVaryTaxYN: "N",
          jnsTaxAmt: null,
          jnsPrivAmt: null,
          jnsNetBusAmt: null,
          jnsSite_dpsID: "",
          jnsOrder: updatedData.length + i + 1,
        }));
        updatedData = [...updatedData, ...emptyRows];
      }

      setGridData(updatedData);
    }
  }, [initialData]);

  const handleDelete = (rowId) => {
    setGridData((prevData) => prevData.filter((item) => item.jnsID !== rowId));
  };

  const handleAddRows = () => {
    const maxId = Math.max(
      ...gridData.map((item) => parseInt(item.jnsID, 10) || 0),
      0,
    );
    setGridData((prevData) => [
      ...prevData,
      ...Array(5)
        .fill({})
        .map((_, index) => ({
          jnsID: maxId + index + 1,
          jnsAccount_accNo: "",
          jnsGrossAmt: null,
          jnsTaxCode_txcID: "",
          jnsPrivPc: null,
          jnsReviewYN: "N",
          jnsVaryTaxYN: "N",
          jnsTaxAmt: null,
          jnsPrivAmt: null,
          jnsNetBusAmt: null,
          jnsSite_dpsID: "",
        })),
    ]);
  };

  const getTaxCodeDisplayValue = (rowData) => {
    const taxCode = listOfTaxCodes?.find(
      (taxCode) => taxCode.txcID === rowData.jnsTaxCode_txcID,
    );
    return taxCode ? taxCode.txcCode : "";
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "n") {
        event.preventDefault();
        handleAddRows();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const renderAddButton = () => {
    return (
      <Button
        icon="add"
        text="Add (ALT + N)"
        onClick={handleAddRows}
        style={{
          fontSize: "11px",
          marginLeft: "2px",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      />
    );
  };

  const handleCellPrepared = (e) => {
    if (
      e.rowType === "totalFooter" &&
      e.column.dataField === "jnsAccount_accNo"
    ) {
      e.cellElement.innerHTML = "";
      const button = document.createElement("div");
      button.style.display = "inline-block";
      e.cellElement.appendChild(button);

      ReactDOM.render(renderAddButton(), button);
    }
  };

  const openImportPopup = () => {
    setIsImportPopupVisible(true);
  };

  const closeImportPopup = () => {
    setIsImportPopupVisible(false);
  };

  const handleImportFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const csv = e.target.result;
        parseCSV(csv);
      };
      reader.readAsText(file);
    } else {
      setSelectedFileName("");
    }
  };

  const parseCSV = (csv) => {
    const rows = csv.split("\n").map((row) => row.split(","));
    const header = rows[0];
    const data = rows
      .slice(1)
      .map((row, index) => {
        const taxCode = row[header.indexOf("Tax")] || "";
        const siteCode = row[header.indexOf("Site")] || "";
        const taxCodeObj = listOfTaxCodes.find((tc) => tc.txcCode === taxCode);
        const siteCodeObj = listOfSites.find(
          (site) => site.dpsCode === siteCode,
        );

        const privPc = parseFloat(row[header.indexOf("Priv %")]) || null;
        const taxAmt =
          taxCodeObj && row[header.indexOf("Tax Var")] !== "Y"
            ? calculateTaxAmount({
                jnsGrossAmt: parseFloat(row[header.indexOf("Amount")]) || 0,
                jnsTaxCode_txcID: taxCodeObj.txcID,
                jnsPrivAmt:
                  parseFloat(row[header.indexOf("Private Amount")]) || 0,
                jnsVaryTaxYN:
                  row[header.indexOf("Tax Var")] === "Y" ? "Y" : "N",
                jnsPrivPc: parseFloat(row[header.indexOf("Priv %")]) || 0,
              })
            : parseFloat(row[header.indexOf("Tax Amount")]) || 0;

        const privAmt =
          privPc !== null && privPc > 0
            ? calculatePrivateAmount({
                jnsGrossAmt: parseFloat(row[header.indexOf("Amount")]) || null,
                jnsPrivPc: privPc,
              })
            : null;

        return {
          jnsID: `imported-${index + 1}`,
          jnsAccount_accNo: row[header.indexOf("Account")] || "",
          jnsGrossAmt: parseFloat(row[header.indexOf("Amount")]) || null,
          jnsTaxCode_txcID: taxCodeObj ? taxCodeObj.txcID : "",
          jnsPrivPc: privPc,
          jnsReference: row[header.indexOf("Memo")],
          jnsReviewYN: row[header.indexOf("Review")] === "Y" ? "Y" : "N",
          jnsVaryTaxYN: row[header.indexOf("Tax Var")] === "Y" ? "Y" : "N",
          jnsTaxAmt: taxAmt,
          jnsPrivAmt: privAmt,
          jnsSite_dpsID: siteCodeObj ? siteCodeObj.dpsID : "",
        };
      })
      .filter((row) => row !== null);

    const rowsToAdd = 5 - (data.length % 5);
    if (rowsToAdd !== 5) {
      for (let i = 0; i < rowsToAdd; i++) {
        data.push({
          jnsID: `empty-${data.length + 1}`,
          jnsAccount_accNo: "",
          jnsGrossAmt: null,
          jnsTaxCode_txcID: "",
          jnsPrivPc: null,
          jnsReviewYN: "N",
          jnsVaryTaxYN: "N",
          jnsTaxAmt: null,
          jnsPrivAmt: null,
          jnsNetBusAmt: null,
          jnsSite_dpsID: "",
        });
      }
    }

    setGridData(data);
    fileInputRef.current.value = "";
    closeImportPopup();
  };

  const handleExport = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("JournalBank");
    worksheet.columns = [
      { header: "Account", key: "jnsAccount_accNo" },
      { header: "Amount", key: "jnsGrossAmt" },
      { header: "Tax", key: "jnsTaxCode_txcID" },
      { header: "Priv %", key: "jnsPrivPc" },
      { header: "Memo", key: "jnsReference" },
      { header: "Review", key: "jnsReviewYN" },
      { header: "Tax Var", key: "jnsVaryTaxYN" },
      { header: "Tax Amount", key: "jnsTaxAmt" },
      { header: "Site", key: "jnsSite_dpsID" },
    ];

    const transformedData = gridData
      .filter((row) => row.jnsAccount_accNo || row.jnsGrossAmt)
      .map((row) => {
        const taxCodeObj = listOfTaxCodes.find(
          (tc) => tc.txcID === row.jnsTaxCode_txcID,
        );
        const siteCodeObj = listOfSites.find(
          (site) => site.dpsID === row.jnsSite_dpsID,
        );
        return {
          ...row,
          jnsTaxCode_txcID: taxCodeObj
            ? taxCodeObj.txcCode
            : row.jnsTaxCode_txcID,
          jnsSite_dpsID: siteCodeObj ? siteCodeObj.dpsCode : row.jnsSite_dpsID,
        };
      });

    worksheet.addRows(transformedData);

    workbook.csv.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "JournalBank.csv",
      );
    });
  };

  const handleNavigateBack = () => {
    enqueueSnackbar("Delete successful", { variant: "success" });
    if (queryParams.get("backURL")) {
      if (queryParams.get("backURL").includes(envConfig.correspondingService)) {
        createSessionForExternal(
          queryParams.get("backURL"),
          "127.0.0.2",
          false,
          apiCall,
          dbId,
        );
      } else if (queryParams.get("backURL").includes("http")) {
        window.location = queryParams.get("backURL");
      } else {
        navigate(queryParams.get("backURL"));
      }
    } else {
      if (pageMainData.HomeUrl.includes("http")) {
        if (pageMainData.HomeUrl.includes(envConfig.correspondingService)) {
          createSessionForExternal(
            pageMainData.HomeUrl,
            "127.0.0.2",
            false,
            apiCall,
            dbId,
          );
        } else {
          window.location = pageMainData.HomeUrl;
        }
      } else {
        navigate(pageMainData.HomeUrl);
      }
    }
  };

  const handleDeleteBankJournal = async () => {
    setLoading(true);
    if (!headerData?.jnlID) {
      enqueueSnackbar(`This journal is not saved, so you can't delete it`, {
        variant: "warning",
      });
      setLoading(false);
      return;
    }

    const url = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/bank-journal/delete-journal-data?journalId=${headerData.jnlID}&BaseHostURL=${envConfig.mainServiceUrl}`;

    await apiCall({
      url,
      method: "POST",
      ip: ip,
      onSuccess: (data) => {
        handleNavigateBack();
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
        setLoading(false);
      },
    });
  };

  const handleSave = async (isDraft) => {
    if (targetAmount && totalAmount !== targetAmount) {
      enqueueSnackbar("The total amount must match the required amount.", {
        variant: "warning",
      });
      return;
    }
    const totalGrossAmt = gridData.reduce(
      (sum, detail) => sum + (detail.jnsGrossAmt || 0),
      0,
    );

    if (totalGrossAmt <= 0) {
      enqueueSnackbar("Total amount must be above zero.", {
        variant: "warning",
      });
      return;
    }
    setLoading(true);

    const formattedDate =
      queryParams?.date || formState?.jnlDate?.format("YYYY-MM-DD");

    const sanitizedMemo = DOMPurify.sanitize(formState.jnlMemo || "");

    let finalFeedId = null;
    let feedIdParam = queryParams.feedId;
    if (feedIdParam) {
      if (feedIdParam.charAt(0) === "C" && feedIdParam?.length === 33) {
        finalFeedId = feedIdParam.substring(1);
      } else if (
        feedIdParam?.length === 36 ||
        (feedIdParam.charAt(0) === "F" && feedIdParam.length === 37)
      ) {
        if (feedIdParam.charAt(0) === "F" && feedIdParam.length === 37) {
          finalFeedId = feedIdParam.substring(1);
        } else {
          finalFeedId = feedIdParam;
        }
      }
    }
    const journalData = {
      JournalHeader: {
        jnlID: headerData?.jnlID || 0,
        jnlCode: formState.jnlCode,
        jnlDate: formattedDate,
        jnlAccount_accNo: formState.jnlAccount_accNo,
        jnlAmount: headerData.jnlAmount,
        jnlMemo: sanitizedMemo,
        jnlReference: headerData.jnlReference,
        jnlContact_cntID: formState.jnlContact_cntID,
        jnlProject_prjID: headerData.jnlProject_prjID,
        jnlReviewYN: "N",
        jnlReconciled_rcnID: headerData.jnlReconciled_rcnID,
        jnlStatus: isDraft ? "D" : "F",
        jnlBankFeed_bftID: headerData.jnlBankFeed_bftID,
        jnlCreatedBy_usrID: headerData.jnlCreatedBy_usrID,
        jnlCreatedDateTime: headerData.jnlCreatedDateTime,
        jnlUpdatedBy_usrID: headerData.jnlUpdatedBy_usrID,
        jnlUpdatedDateTime: new Date().toISOString(),
        jnlImportID: headerData.jnlImportID,
        jnlFilesCounter: headerData.jnlFilesCounter,
        jnsBankFeed_bftID: finalFeedId,
      },
      JournalDetails: gridData
        .filter((detail) => detail.jnsGrossAmt !== null)
        .map((detail, index) => {
          const taxCodeObj = listOfTaxCodes.find(
            (tc) => tc.txcID === detail.jnsTaxCode_txcID,
          );
          return {
            jnsID: Number(detail.jnsID) || 0,
            jnsAccount_accNo: Number(detail.jnsAccount_accNo),
            jnsGrossAmt: Number(parseFloat(detail.jnsGrossAmt).toFixed(2)),
            jnsVaryTaxYN: detail.jnsVaryTaxYN,
            jnsTaxCode_txcID: taxCodeObj
              ? taxCodeObj.txcID
              : detail.jnsTaxCode_txcID,
            jnsTaxAmt: Number(parseFloat(detail.jnsTaxAmt).toFixed(2)),
            jnsPrivPc: detail.jnsPrivPc || 0,
            jnsPrivAmt: Number(parseFloat(detail.jnsPrivAmt).toFixed(2)),
            jnsNetBusAmt: Number(
              parseFloat(calculateNetBusAmt(detail)).toFixed(2),
            ),
            jnsReference: DOMPurify.sanitize(detail.jnsReference) || "",
            jnsReviewYN: detail.jnsReviewYN,
            jnsReconcile_rcnID: detail.jnsReconcile_rcnID || 0,
            jnsSite_dpsID: detail.jnsSite_dpsID || 0,
            jnsImportID: detail.jnsImportID || "",
            jnsTask_prdID: detail.jnsTask_prdID || 0,
            jnsOrder: Number(index) + 1,
          };
        }),
    };

    await apiCall({
      url: `https://${envConfig.apiDev1Exacc}/api/v1/en-au/bank-journal/save-journal-data?BaseHostURL=${envConfig.mainServiceUrl}`,
      method: "POST",
      ip: ip,
      body: journalData,
      onSuccess: (data) => {
        setSavedJournalId(data?.JournalHeader.jnlID);
        setPopupOpen(true);
        setLoading(false);
      },
      onError: (error) => {
        if (error?.toLowerCase()?.includes("select journal type")) {
          enqueueSnackbar("Please select either Receive Money or Spend Money", {
            variant: "error",
          });
        } else {
          enqueueSnackbar(error, { variant: "error" });
        }
        setLoading(false);
      },
    });
  };

  const handleSaveAsDraft = () => {
    handleSave(true);
  };

  const handleAddAccount = () => {
    setOpenAddAccountPopup(true);
  };

  const enterNewCellAndEdit = (e) => {
    setTimeout(() => {
      const dataGrid = e.component;
      const visibleColumns = dataGrid.getVisibleColumns();
      const currentRowIndex = dataGrid.option("focusedRowIndex");
      const currentColumnIndex = visibleColumns.findIndex(
        (col) => col.dataField === e.dataField,
      );

      let nextColumnIndex = currentColumnIndex + 1;
      if (nextColumnIndex >= visibleColumns.length) {
        nextColumnIndex = 0;
      }

      dataGrid.focus(dataGrid.getCellElement(currentRowIndex, nextColumnIndex));
      dataGrid.editCell(currentRowIndex, nextColumnIndex);
    }, 100);
  };

  const onEditorPreparing = (e) => {
    if (e.parentType === "dataRow" && e.dataField === "jnsReference") {
      e.handleKey = function (args) {
        if (args.keyName === "leftArrow" || args.keyName === "rightArrow") {
          args.handled = true;
        }
      };
    }
    if (e.parentType === "dataRow") {
      e.editorOptions.onValueChanged = (args) => {
        setValue(args.value, e.dataField, e.row.data.jnsID);

        if (e.dataField === "jnsAccount_accNo") {
          const account = accounts.find((acc) => acc.accNo === args.value);
          setValue(
            pageMainData.GstRegisteredYN === "N"
              ? 4
              : account?.accDefTxc_txcID || "",
            "jnsTaxCode_txcID",
            e.row.data.jnsID,
          );
          setValue(account?.accDefPriv || 0, "jnsPrivPc", e.row.data.jnsID);
        }

        if (
          ["jnsGrossAmt", "jnsTaxCode_txcID", "jnsPrivPc"].includes(e.dataField)
        ) {
          setValue(
            calculatePrivateAmount({
              ...e.row.data,
              [e.dataField]: args.value,
            }),
            "jnsPrivAmt",
            e.row.data.jnsID,
          );
          setValue(
            calculateTaxAmount({
              ...e.row.data,
              [e.dataField]: args.value,
            }),
            "jnsTaxAmt",
            e.row.data.jnsID,
          );
          setValue(
            calculateNetBusAmt({
              ...e.row.data,
              [e.dataField]: args.value,
            }),
            "jnsNetBusAmt",
            e.row.data.jnsID,
          );
        }

        if (e.dataField === "jnsTaxAmt") {
          const totalAmount = e.row.data.jnsGrossAmt || 0;
          if (args.value > totalAmount) {
            setValue(totalAmount, "jnsTaxAmt", e.row.data.jnsID);
          }
        }
      };

      // Specific logic to handle comma removal in input
      if (["jnsGrossAmt"].includes(e.dataField)) {
        e.editorOptions.onInput = ({ event }) => {
          const valueWithoutCommas = event.currentTarget.value.replace(
            /,/g,
            "",
          );
          event.currentTarget.value = valueWithoutCommas;
        };
      }

      e.editorOptions.onKeyDown = (args) => {
        const arrowKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
        if (
          arrowKeys.includes(args.event?.key) &&
          e.dataField !== "jnsReference"
        ) {
          args.event.preventDefault();
          args.event.stopPropagation();
        } else if (args.event?.key === "Enter") {
          args.event.preventDefault();
          enterNewCellAndEdit(e);
        }
      };

      if (e.dataField === "jnsTaxAmt") {
        e.editorOptions.disabled = e.row.data.jnsVaryTaxYN !== "Y";
      }
      if (e.dataField === "jnsPrivAmt") {
        e.editorOptions.disabled = true;
      }
    }
  };

  const onEditorPrepared = (e) => {
    if (e.parentType === "dataRow") {
      if (e.dataField === "jnsTaxAmt" && e.row.data.jnsVaryTaxYN !== "Y") {
        e.editorOptions.disabled = true;
      }
    }
  };

  const handleAddFilesButtonClick = () => {
    if (!headerData?.jnlID) {
      enqueueSnackbar(
        "You need to save the journal before you can upload files.",
        { variant: "warning" },
      );
      return;
    }
    setOpenFilesPopup(true);
  };

  const handleDuplicate = () => {
    const duplicatedData = gridData.map((item) => ({
      ...item,
      jnsID: `duplicated-${Math.random()}`,
    }));

    setGridData(duplicatedData);
    navigate(`/${dbId}/en-au/books/bank-journal/`);
  };

  const actionItems = [
    {
      icon: "save",
      name: "save-post",
      text: "Save & Post",
      sx: {
        backgroundColor: theme.palette.primary.main,
        borderColor: "transparent",
        color: isDarkMode ? "#000" : "#FFF",
      },
      onClick: () => handleSave(false),
    },
    {
      icon: "save",
      name: "save-draft",
      text: "Save as Draft",
      sx: {
        backgroundColor: isDarkMode ? "#353E51" : "#FFF",
        borderColor: isDarkMode && "transparent",
        color: isDarkMode ? theme.palette.primary.main : "#000",
      },
      onClick: handleSaveAsDraft,
      hidden: targetAmount,
    },
    {
      icon: "trash",
      name: "save-delete",
      text: "Delete",
      sx: {
        backgroundColor: isDarkMode ? "#465572" : theme.typography.linkColor,
        borderColor: "transparent",
        color: "#FFF",
      },
      hidden: !headerData?.jnlID,
      onClick: () => setDeleteDialogVisible(true),
    },
    {
      icon: "pdffile",
      name: "save-report",
      text: "Report",
      sx: {
        backgroundColor: isDarkMode ? "#353E51" : "#FFF",
        borderColor: isDarkMode && "transparent",
        color: isDarkMode ? theme.palette.primary.main : "#000",
      },
      onClick: () => {},
    },
    {
      icon: "file",
      name: "save-files",
      text: "Files",
      sx: {
        backgroundColor: isDarkMode ? "#353E51" : "#FFF",
        borderColor: isDarkMode && "transparent",
        color: isDarkMode ? theme.palette.primary.main : "#000",
      },
      onClick: handleAddFilesButtonClick,
    },
    {
      icon: "plus",
      name: "save-account",
      text: "Add Account",
      sx: {
        backgroundColor: isDarkMode ? "#353E51" : "#FFF",
        borderColor: isDarkMode && "transparent",
        color: isDarkMode ? theme.palette.primary.main : "#000",
      },
      onClick: handleAddAccount,
    },
    {
      icon: "copy",
      name: "duplicate",
      text: "Duplicate",
      sx: {
        backgroundColor: isDarkMode ? "#353E51" : "#FFF",
        borderColor: isDarkMode && "transparent",
        color: isDarkMode ? theme.palette.primary.main : "#000",
      },
      onClick: handleDuplicate,
      hidden: !headerData?.jnlID,
    },
  ];

  const dataSource = new CustomStore({
    key: "jnsID",
    load: () => Promise.resolve(gridData),
    update: (key, values) => {
      setGridData((prevData) =>
        prevData.map((item) =>
          item.jnsID === key ? { ...item, ...values } : item,
        ),
      );
    },
  });

  useEffect(() => {
    const styles = `
    .dx-button i {
        color: ${isDarkMode ? theme.palette.primary.main : "#000"} !important;
        }
    .dx-button-save-post i {
        color: ${isDarkMode ? "#000" : "#FFF"} !important;
      }
    .dx-button-save i {
        color: ${isDarkMode ? "#000" : "#FFF"} !important;
      }
    .dx-button.dx-button-save-delete i {
        color: #FFF !important;
      }
    .dx-button-import-export {
      background-color: ${isDarkMode ? "#353E51" : "#FFF"};
      border-color: ${isDarkMode && "transparent"};
      color: ${isDarkMode ? theme.palette.primary.main : "#000"} !important;
    }
    .dx-button.dx-button-import-export i {
      color: ${isDarkMode ? theme.palette.primary.main : "#000"} !important;
    }
    `;
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [isDarkMode, theme]);

  useEffect(() => {
    setAccounts(listOfAccounts);
  }, [listOfAccounts]);

  const actionsColumn = (
    <Column
      width={58}
      caption=""
      cellRender={({ data }) => (
        <ActionMenu rowId={data.jnsID} handleDelete={handleDelete} />
      )}
    />
  );

  return (
    <>
      <BackdropLoading open={loading} />
      {openAddAccountPopup && (
        <AddAccountPopup
          taxCodes={listOfTaxCodes}
          setOpenAddAccountPopup={setOpenAddAccountPopup}
          setListOfAccounts={setAccounts}
          accounts={accounts}
        />
      )}

      <JournalSavedPopup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        journalId={savedJournalId}
        jnlDate={formState?.jnlDate}
        feedId={queryParams.feedId}
        formState={formState}
        reloadPage={() => {
          setGridData([]);
          reloadPage();
        }}
      />

      <Box
        sx={{
          ".dx-datagrid-total-footer": {
            "td:first-of-type": {
              padding: 0,
            },
            td: {
              minWidth: "125px",
            },
          },
          ".dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td": {
            backgroundColor: isDarkMode ? "#232939" : "#FAFAFA",
          },
          ".dx-texteditor.dx-editor-filled.dx-state-disabled .dx-texteditor-input":
            {
              color: isDarkMode ? "#FFF !important" : "#000",
            },
        }}
      >
        {openFilesPopup && (
          <FilesUploadedPopup
            journalId={headerData?.jnlID}
            setOpenFilesPopup={setOpenFilesPopup}
          />
        )}
        <div
          style={{
            marginBottom: largeScreen ? "-46px" : "10px",
            zIndex: 1,
            position: "relative",
            marginLeft: "10px",
            maxWidth: breakpoint ? "1012px" : "600px",
          }}
          className="action-buttons"
        >
          {actionItems
            .filter((item) => !item.hidden)
            .map((item, index) => (
              <Button
                className={`dx-button dx-button-${item.name}`}
                style={{
                  fontWeight: "500",
                  fontSize: "13px",
                  color: isDarkMode ? theme.palette.primary.main : "#000",
                  marginRight: 16,
                  marginBottom: breakpoint ? 0 : "15px",
                  ...item.sx,
                }}
                key={index}
                icon={item.icon}
                text={item.text}
                onClick={item.onClick}
              />
            ))}
        </div>
        <DataGrid
          className={
            isReceiveMoney ? "receive-money" : isSpendMoney ? "spend-money" : ""
          }
          // style={{
          //   border: isDarkMode ? "1px solid #40404A" : "1px solid #ddd",
          //   borderRadius: "5px",
          // }}
          showColumnLines={true}
          loadPanel={{ enabled: false, showIndicator: false, showPane: false }}
          refreshMode="repaint"
          repaintChangesOnly={true}
          ref={dataGridRef}
          onCellPrepared={handleCellPrepared}
          onEditorPreparing={onEditorPreparing}
          onEditorPrepared={onEditorPrepared}
          dataSource={dataSource}
          keyExpr="jnsID"
          showBorders={true}
          wordWrapEnabled={true}
          columnHidingEnabled={false}
          allowColumnReordering={false}
          hoverStateEnabled={true}
          cacheEnabled={true}
          errorRowEnabled={true}
        >
          <Scrolling showScrollbar="always" />
          <Paging enabled={true} pageSize={20} />
          <Pager
            allowedPageSizes={[10, 20, 50]}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Selection mode="single" />
          <Editing
            refreshMode="reshape"
            mode="cell"
            allowUpdating={true}
            allowDeleting={false}
            allowAdding={false}
            confirmDelete={false}
            selectTextOnEditStart={true}
            startEditAction="click"
          />
          {interfacePreference === "left" && actionsColumn}

          <Column
            alignment="left"
            width={136}
            dataField="jnsAccount_accNo"
            caption="Account"
            lookup={{
              dataSource: {
                store: accounts,
                searchExpr: ["accNo", "accName", "accType"],
              },
              valueExpr: "accNo",
              displayExpr: "accNo",
            }}
            editorOptions={{
              dropDownOptions: {
                width: 200,
              },
              searchExpr: ["accNo", "accName"],

              searchTimeout: 0,
              itemTemplate: (data) => `
                <div style="white-space: normal; display: flex; flex-direction: column;">
                  <div><b>${data.accNo}</b></div>
                  <div>${data.accName}</div>
                  <div><i>(${data.accType})</i></div>
                </div>
              `,
            }}
          />
          <Column
            dataField="jnsGrossAmt"
            caption="Amount"
            width={150}
            dataType="number"
            format={{ type: "fixedPoint", precision: 2.0 }}
            calculateDisplayValue={(rowData) =>
              rowData.jnsGrossAmt !== 0 ? rowData.jnsGrossAmt : 0
            }
          />

          <Column
            alignment="left"
            dataField="jnsTaxCode_txcID"
            caption="Tax"
            width={150}
            calculateDisplayValue={getTaxCodeDisplayValue}
            lookup={{
              dataSource: listOfTaxCodes,
              valueExpr: "txcID",
              displayExpr: "txcCode",
              searchExpr: ["txcCode", "txcID"],
            }}
            editorOptions={{
              searchExpr: ["txcCode", "txcName"],
              dropDownOptions: {
                width: 300,
              },
              searchTimeout: 0,
              itemTemplate: (data) => `
                <div style="white-space: normal; display: flex; flex-direction: column;">
                  <div><b>${data.txcCode}</b></div>
                  <div>${data.txcName}</div>
                  <div><i>${(data.txcRate * 100).toFixed(2)}%</i></div>
                </div>
              `,
            }}
          />
          {showPrivatePortion && (
            <Column
              alignment="right"
              dataField="jnsPrivPc"
              caption="Priv %"
              width={80}
              dataType="number"
              format={{ type: "fixedPoint", precision: 2.0 }}
              editorOptions={{
                min: 0,
                max: 99,
              }}
              validationRules={[
                {
                  type: "range",
                  min: 0,
                  max: 99,
                  message: "Priv % must be between 0 and 99",
                },
              ]}
            />
          )}
          <Column
            dataField="jnsReference"
            minWidth={250}
            alignment="left"
            caption="Memo"
            dataType="text"
          />
          <Column
            dataField="jnsReviewYN"
            caption="Review"
            width={85}
            dataType="boolean"
            calculateCellValue={(rowData) => {
              return rowData.jnsReviewYN === "Y";
            }}
            setCellValue={(newData, value) => {
              newData.jnsReviewYN = value ? "Y" : "N";
            }}
          />
          {showVaryManualTax && (
            <Column
              width={85}
              dataField="jnsVaryTaxYN"
              caption="Tax Var"
              dataType="boolean"
              calculateCellValue={(rowData) => rowData.jnsVaryTaxYN === "Y"}
              setCellValue={(newData, value) => {
                newData.jnsVaryTaxYN = value ? "Y" : "N";
              }}
            />
          )}
          <Column
            alignment="right"
            width={120}
            dataField="jnsTaxAmt"
            caption="Tax Amount"
            dataType="number"
            format={{ type: "fixedPoint", precision: 2.0 }}
            calculateDisplayValue={(rowData) =>
              rowData.jnsTaxAmt !== 0 ? rowData.jnsTaxAmt : ""
            }
            editorOptions={(cellInfo) => ({
              disabled: cellInfo.row.data.jnsVaryTaxYN !== "Y",
            })}
          />
          {showPrivatePortion && (
            <Column
              width={120}
              alignment="right"
              dataField="jnsPrivAmt"
              caption="Private Amount"
              dataType="number"
              format={{ type: "fixedPoint", precision: 2.0 }}
              calculateDisplayValue={(rowData) =>
                rowData.jnsPrivAmt !== 0 ? rowData.jnsPrivAmt : ""
              }
            />
          )}
          {/* <Column
            dataField="jnsNetBusAmt"
            caption="Net Bus Amt"
            dataType="number"
            format={{ type: "fixedPoint", precision: 2.0 }}
            calculateDisplayValue={(rowData) =>
              rowData.jnsNetBusAmt !== 0 ? rowData.jnsNetBusAmt : ""
            }
          /> */}
          <Column
            dataField="jnsSite_dpsID"
            alignment="left"
            caption="Site"
            width={150}
            lookup={{
              dataSource: listOfSites,
              valueExpr: "dpsID",
              displayExpr: "dpsCode",
            }}
            editorOptions={{
              dropDownOptions: {
                width: 300,
              },
              searchTimeout: 0,
              itemTemplate: (data) =>
                `<div
                  style="white-space: normal; display: flex; flex-direction: column;">
                  <div>${data.dpsCode}</div>
                  <div>${data.dpsName}</div>
                </div>`,
            }}
          />
          <Column dataField="MobileColumn" caption="Mobile" visible={false} />
          {interfacePreference !== "left" && actionsColumn}

          <Summary calculateCustomSummary={() => {}}>
            <TotalItem column="jnsAccount_accNo" summaryType="custom" />
            <TotalItem
              column="dpsCode"
              summaryType="count"
              displayFormat="Total rows: {0}"
            />
            <TotalItem
              name="jnsTaxAmt"
              column="jnsTaxAmt"
              summaryType="sum"
              valueFormat={{ type: "fixedPoint", precision: 2.0 }}
              displayFormat="Total: {0}"
            />
            {targetAmount > 0 && (
              <TotalItem
                column="jnsReference"
                name="amountDifference"
                showInColumn="jnsReference"
                summaryType="custom"
                value={amountDifference}
                displayFormat={`Out: ${Math.abs(amountDifference).toFixed(2)}`}
              />
            )}
            <TotalItem
              name="jnsGrossAmt"
              column="jnsGrossAmt"
              summaryType="sum"
              valueFormat={{ type: "fixedPoint", precision: 2.0 }}
              displayFormat="Total: {0}"
            />
          </Summary>

          <Toolbar>
            <Item name="columnChooserButton" />
            <Item name="exportButton" />
            <Item name="searchPanel" />
            <Item
              widget="dxButton"
              location="after"
              options={{
                icon: "export",
                text: "Export",
                onClick: handleExport,
                elementAttr: { class: "dx-button dx-button-import-export" },
              }}
            />
            <Item
              widget="dxButton"
              location="after"
              options={{
                icon: "import",
                text: "Import",
                onClick: openImportPopup,
                elementAttr: { class: "dx-button dx-button-import-export" },
              }}
            />
          </Toolbar>
        </DataGrid>

        <Popup
          visible={isDeleteDialogVisible}
          onHiding={() => setDeleteDialogVisible(false)}
          dragEnabled={false}
          closeOnOutsideClick={false}
          showTitle={true}
          title="Confirm Deletion"
          width={400}
          height={250}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Do you want to DELETE this entry?
            </Typography>
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Button
                text="Delete"
                stylingMode="contained"
                type="danger"
                onClick={() => {
                  handleDeleteBankJournal(); // Execute delete action
                  setDeleteDialogVisible(false); // Close the dialog
                }}
              />
              <Button
                text="Cancel"
                stylingMode="text"
                onClick={() => setDeleteDialogVisible(false)} // Close the dialog
              />
            </Box>
          </Box>
        </Popup>

        <Popup
          visible={isImportPopupVisible}
          onHiding={closeImportPopup}
          dragEnabled={true}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Import CSV"
          width={400}
          height={400}
        >
          <Box p={2}>
            <Typography variant="body1">
              Import in-line entries from CSV and replace any existing entries
              in this record.
            </Typography>
            <Typography variant="body1" mb={2}>
              To see file format, use Export.
            </Typography>
            <FileUploader
              selectButtonText="Choose CSV File"
              labelText=""
              accept=".csv"
              uploadMode="useForm"
              onValueChanged={(e) => {
                if (e.value?.[0]) {
                  handleImportFileChange({ target: { files: e.value } });
                }
              }}
              width="100%"
            />
            <Box mt={2}>
              <Button variant="outlined" fullWidth onClick={closeImportPopup}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Popup>
      </Box>
      <Box
        sx={{
          mt: 2,
          mb: 2,
          width: "100%",
        }}
      >
        <Typography fontSize="12px" sx={{ textAlign: "right" }}>
          {formState.footerText}
        </Typography>
      </Box>
    </>
  );
};

const ActionMenu = ({ rowId, handleDelete }) => {
  const [target, setTarget] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setTarget(event.currentTarget);
    setShowMenu(true);
  };

  const handleMenuClose = () => {
    setTarget(null);
    setShowMenu(false);
  };

  return (
    <>
      <IconButton sx={{ padding: 0 }} onClick={handleMenuClick}>
        <MoreHorizIcon />
      </IconButton>
      <ContextMenu
        dataSource={[{ text: "Delete", onClick: () => handleDelete(rowId) }]}
        target={target}
        onItemClick={(e) => {
          e.itemData.onClick();
          handleMenuClose();
        }}
        visible={showMenu}
        onHiding={handleMenuClose}
        showEvent={null}
      />
    </>
  );
};

export default BankJournalGrid;
