import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { createDefaultHeaders } from "./headers";
import { enqueueSnackbar } from 'notistack';
import { createSessionToLogin } from '../utils/reusable';
import envConfig from '../config';

// types to be moved to a shared file
export interface BaseResponseInterface {
  SuccessYN?: 'Y' | 'N';
  successYN?: 'Y' | 'N';
  SmsSentYN?: 'Y' | 'N';
  Secret2FAKey?: string;
  User2FADisabledYN?: boolean;
  User2FAEnabledYN?: boolean;
  ErrorMessage?: string;
  errorMessage?: string;
  message?: string;
  data?: any;
};


export interface RequestConfig extends axios.AxiosRequestConfig {
  endpoint: string;
  baseURL?: string;

  dbId?: string;
  skipAuth?: boolean;

  onError?: (error: string) => void;
  reAuthenticate?: () => Promise<void>;
}

export interface InternalRequestConfig extends axios.InternalAxiosRequestConfig {
  endpoint: string;
  baseURL?: string;

  dbId?: string;
  skipAuth?: boolean;

  onError?: (error: string) => void;
  reAuthenticate?: () => Promise<void>;
}

class AxiosService {
  private instance: axios.AxiosInstance;

  constructor() {
    this.instance = axios.create({
      timeout: 10000,
    });

    this.setupInterceptors();
  }

  private setupInterceptors(): void {
    this.instance.interceptors.request.use(async (config: InternalRequestConfig) => {
      console.log('[AXIOS] - request interceptor - config', config);

      const sessionId = Cookies.get('sessionId');
      const databaseId = config.dbId || Cookies.get('databaseId');
      const apiKey = Cookies.get('apiKey');
      const authApiKey = apiKey && databaseId ? `${apiKey}-${databaseId}` : undefined;

      // If the request is not skipped and the apiKey is not set, call the reAuthenticate function
      // [IN THE FUTURE]: Remove the process.env.NODE_ENV !== 'development' check once we have a way to re-auth
      if (!config.skipAuth && (!apiKey || apiKey === 'undefined') && process.env.NODE_ENV !== 'development') {
        await config?.reAuthenticate?.();
      }

      // Combine endpoint with baseURL if provided
      if (config.baseURL) {
        config.url = `https://${config.baseURL}${config.url}`;
      }

      // Setting default headers for the request
      // [LATER]: Add ability to override the headers passed via config
      config.headers = createDefaultHeaders(authApiKey, sessionId);

      return config;
    },
      (error) => Promise.reject(error)
    );

    this.instance.interceptors.response.use(async (response: AxiosResponse<BaseResponseInterface>) => {
      const { data, headers } = response;

      console.log('[AXIOS] - response interceptor - response: ', response);
      console.log('[AXIOS] - response interceptor - data: ', data, typeof data);
      console.log('[AXIOS] - response interceptor - headers: ', headers);

      // @ts-ignore
      if (data === 'Unauthorized Database Access!' && process.env.NODE_ENV !== 'development') {
        enqueueSnackbar("You don't have access to this business file. Redirecting...", {
          variant: 'error',
        });

        await createSessionToLogin(`https://${envConfig.loginUrl}/user-dashboard`, '127.0.0.2');
        throw new Error('Unauthorized Database Access');
      }

      // @ts-ignore
      if (data === "API Key is missing!") {
        enqueueSnackbar("API Key is missing!", {
          variant: 'error',
        });
        throw new Error('API Key is missing!');
      }

      const isSuccessful =
        data.SuccessYN === 'Y' ||
        data.successYN === 'Y' ||
        data.SmsSentYN === 'Y' ||
        // TODO: remove this once Tom fixes his API
        data.SuccessYN === null ||
        data.Secret2FAKey ||
        data.User2FADisabledYN ||
        data.User2FAEnabledYN;

      if (isSuccessful) {
        // @ts-ignore
        this.updateAuthenticationDates(headers);
        return response;
    }

    throw new Error(data.ErrorMessage || data.errorMessage || 'Request failed');
    },
    async (error: AxiosError<BaseResponseInterface>) => {
      console.log('[AXIOS] - response interceptor - error: ', error);

      if (
        [401, 403].includes(error.response?.status || 0) &&
        !(error.config as InternalRequestConfig)?.skipAuth &&
        (error.config as InternalRequestConfig)?.reAuthenticate
      ) {
        console.log('[AXIOS] - response interceptor - error occurred status: ', error.response?.status);
        if (process.env.NODE_ENV !== 'development') {
          console.log('[AXIOS] - response interceptor - calling reAuthenticate');
          await (error.config as InternalRequestConfig)?.reAuthenticate?.();
          return this.instance(error.config as AxiosRequestConfig);
        }
      }

      const errorMessage =
        error.response?.data?.ErrorMessage ||
        error.response?.data?.errorMessage ||
        error.response?.data?.message ||
        error.message ||
        'An error occurred';

      throw new Error(errorMessage);
      }
    );
  }

  private updateAuthenticationDates(headers: Record<string, string>): void {
    const { apikeyexpirationdate, lastpwddatetime, last2fadatetime } = headers;
    console.log('[AXIOS] - response interceptor - headers inside updateAuthenticationDates: ', headers);
    
    if (apikeyexpirationdate) {
      const formattedDate = apikeyexpirationdate.replace(' ', 'T');
      Cookies.set('apiKeyExpirationUTCDate', formattedDate, { path: '/' });
    }

    if (lastpwddatetime) {
      const formattedDate = lastpwddatetime.replace(' ', 'T');
      Cookies.set('loginLastPwdUTCDate', formattedDate, { path: '/' });
    }

    if (last2fadatetime) {
      const formattedDate = last2fadatetime.replace(' ', 'T');
      Cookies.set('login2FaCodeLastUsedUTCDate', formattedDate, { path: '/' });
    }
  }

  // if used outside of the TanStack Query, you have to wrap the response in a try catch
  // use data: body to pass the body of the request for POST, PUT, PATCH, DELETE requests
  public async request<T>(config: RequestConfig): Promise<T> {
    const response = await this.instance({
      ...config,
      url: config.endpoint,
      baseURL: config.baseURL,
    });
    return response.data as T;
  }

}

export const axiosService = new AxiosService();
