import React from "react";
import { Box, Button } from "@mui/material";

interface BankFeedsHistoryActionsProps {
  onShowClick: () => void;
  onBackClick: () => void;
  onGetCsvClick: () => void;
  onOpenRulesClick: () => void;
  onApplyRulesClick: () => void;
}

const BankFeedsHistoryActions = ({
  onShowClick,
  onBackClick,
  onGetCsvClick,
  onOpenRulesClick,
  onApplyRulesClick
}: BankFeedsHistoryActionsProps) => {
  return (
    <Box display="flex" gap={2} mt={2}>
      <Button
        variant="contained"
        color="primary"
        onClick={onShowClick}
      >
        Show
      </Button>
      <Button
        variant="outlined"
        onClick={onBackClick}
      >
        Back
      </Button>
      <Button
        variant="outlined"
        onClick={onGetCsvClick}
      >
        Get CSV
      </Button>
      <Button
        variant="outlined"
        onClick={onOpenRulesClick}
      >
        Open Rules
      </Button>
      <Button
        variant="outlined"
        onClick={onApplyRulesClick}
      >
        Apply Rules
      </Button>
    </Box>
  );
};

export default BankFeedsHistoryActions;
