import React from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { Autocomplete, Box, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


interface BankFeedsHistoryFormProps {
  // account
  accountsOptions: any[];
  selectedAccount: any;
  onAccountChange: (account: any) => void;
  // date
  startDate: dayjs.Dayjs;
  onStartDateChange: (date: dayjs.Dayjs) => void;
  endDate: dayjs.Dayjs;
  onEndDateChange: (date: dayjs.Dayjs) => void;
  // errors
  errors: any;
};

const BankFeedsHistoryForm = ({ accountsOptions, selectedAccount, onAccountChange, startDate, onStartDateChange, endDate, onEndDateChange, errors }: BankFeedsHistoryFormProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      width="100%"
      mt={4}
      alignItems={{ xs: "stretch", md: "center" }}
    >
      <Autocomplete
        value={selectedAccount}
        options={accountsOptions}
        onChange={(_, newValue) => onAccountChange(newValue)}
        getOptionLabel={(option) => option.AccName}
        fullWidth
        sx={{ maxWidth: 400 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Account *"
            size="small"
            error={!!errors.account}
            helperText={errors.account}
          />
        )}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={startDate}
          onChange={onStartDateChange}
          slotProps={{
            textField: {
              size: "small",
              label: "Start Date *",
              error: !!errors.date,
              helperText: errors.date,
            },
          }}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={endDate}
          onChange={onEndDateChange}
          slotProps={{
            textField: {
              size: "small",
              label: "End Date *",
              error: !!errors.date,
              helperText: errors.date,
            },
          }}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>
    </Box>
  )
}

export default BankFeedsHistoryForm;