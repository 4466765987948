import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { axiosService } from "../../../api/axios";
import envConfig from "../../../config";
import { useLoginPopup } from "../../../context/LoginPopupContext";
import { DashboardResponse } from "../types";
import { DASHBOARD_DATA_EXAMPLE_RESPONSE } from "./example-response";

export const useDashboardData = () => {
  const { dbId, lang } = useParams();
  const { reAuthenticate } = useLoginPopup();

  return useQuery({
    queryKey: ['dashboard-data', dbId, lang],
    staleTime: 1000 * 60,
    queryFn: async () => {

      if (process.env.NODE_ENV === 'development') {
        return DASHBOARD_DATA_EXAMPLE_RESPONSE;
      }

      const searchParams = new URLSearchParams();
      searchParams.append("BaseHostURL", envConfig.mainServiceUrl);

      const response = await axiosService.request<DashboardResponse>({
        dbId: dbId,
        method: 'GET',
        baseURL: envConfig.apiDev2,
        endpoint: `/api/${lang}/dashboard/get-main-dashboard-data?${searchParams.toString()}`,
        reAuthenticate: reAuthenticate,
      });

      return response;
    }
  });
};
