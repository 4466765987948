import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Grid as MuiGrid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Menu,
  MenuItem,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Column, DataGrid, Editing } from "devextreme-react/data-grid";
import { useParams, useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import envConfig from "../../config";
import { enqueueSnackbar } from "notistack";
import { useLoginPopup } from "../../context/LoginPopupContext";
import {
  ArrowBack,
  Save,
  AccessTime,
  Description,
  AttachMoney,
  Delete as DeleteIcon,
  MoreVert,
} from "@mui/icons-material";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import AddPayItemDialog from "./components/AddPayItemDialog";

const sitesUrl = `https://${envConfig.apiDev1Exacc}/api/v1/en-au/sites/get-selection-list?activeYN=Y&BaseHostURL=${envConfig.mainServiceUrl}`;

const mapUnlockToPedManualCalcYN = (items) =>
  items.map(({ unlock, ...rest }) => ({
    ...rest,
    PedManualCalcYN: unlock ? "Y" : "N",
  }));

const PayEventEmployeePage = () => {
  const { PevID, EmpID, dbId, lang } = useParams();
  const navigate = useNavigate();
  const { isReAuthCompleted } = useLoginPopup();
  const { apiCall } = useApi();
  const [rawData, setRawData] = useState({});
  const [initialLoading, setInitialLoading] = useState(true); // For initial load
  const [processing, setProcessing] = useState(false); // For saving and recalculating
  const [empData, setEmpData] = useState(null);
  const [grossPayments, setGrossPayments] = useState([]);
  const [superAccruals, setSuperAccruals] = useState([]);
  const [taxesDeductions, setTaxesDeductions] = useState([]);
  const [otherItems, setOtherItems] = useState([]);
  const [managementNote, setManagementNote] = useState("");
  const [payslipNote, setPayslipNote] = useState("");
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] =
    useState(false);
  const [isFinalPayEvent, setIsFinalPayEvent] = useState(false);
  const [listOfSites, setListOfSites] = useState(null);
  const [listOfPayItems, setListOfPayItems] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(0);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] =
    useState(false);

  useEffect(() => {
    if (isReAuthCompleted) {
      const loadData = async () => {
        try {
          await Promise.all([fetchData(), fetchSites(), fetchListOfPayItems()]);
        } catch (error) {
          console.error("Error loading data:", error);
        }
      };
      loadData();
    }
  }, [isReAuthCompleted]);

  const fetchListOfPayItems = async () => {
    await apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/get-list-of-pay-items-add-to-pay-event`,
      method: "GET",
      onSuccess: (data) => {
        setListOfPayItems(data);
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
      },
    });
  };

  const fetchSites = async () => {
    await apiCall({
      url: sitesUrl,
      method: "GET",
      onSuccess: (data) => {
        setListOfSites(data.ListOfSites);
      },
      onError: (error) => {
        enqueueSnackbar(error, { variant: "error" });
      },
    });
  };

  const fetchData = useCallback(() => {
    apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/get-data-for-pay-event-employee-page-v2`,
      method: "POST",
      body: { EmpID, PevID },
      onSuccess: (data) => {
        setEmpData(data.Emp);
        setGrossPayments(data.Data.GrossPay);
        setSuperAccruals(
          data.Data.Benefits.map((row) => ({
            ...row,
            unlock: row.PedManualCalcYN === "Y",
          })),
        );
        setTaxesDeductions(
          data.Data.Charges.map((row) => ({
            ...row,
            unlock: row.PedManualCalcYN === "Y",
          })),
        );
        setRawData(data);
        setOtherItems(data.Data.Others);
        setManagementNote(data.Emp.ManagementNote || "");
        setPayslipNote(data.Emp.PayslipNote || "");
        setInitialLoading(false);
        setProcessing(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
        setInitialLoading(false);
        setProcessing(false);
      },
    });
  }, [EmpID, PevID, apiCall]);

  const handleCellChange = (e) => {
    setIsDataChanged(true);
    const changedFields = e.newData;
    const key = e.key;

    const updateDataArray = (items) => {
      const index = items.findIndex((item) => item.PedID === key);
      if (index !== -1) {
        return items.map((item, idx) =>
          idx === index ? { ...item, ...changedFields } : item,
        );
      }
      return items;
    };

    let updatedGrossPayments = [...grossPayments];
    let updatedSuperAccruals = [...superAccruals];
    let updatedTaxesDeductions = [...taxesDeductions];
    let updatedOtherItems = [...otherItems];
    let updated = false;

    if (changedFields.hasOwnProperty("unlock")) {
      // Handle unlock changes
      const newSuperAccruals = updateDataArray(superAccruals);
      if (newSuperAccruals !== superAccruals) {
        setSuperAccruals(newSuperAccruals);
        updatedSuperAccruals = newSuperAccruals;
        updated = true;
      }

      const newTaxesDeductions = updateDataArray(taxesDeductions);
      if (newTaxesDeductions !== taxesDeductions) {
        setTaxesDeductions(newTaxesDeductions);
        updatedTaxesDeductions = newTaxesDeductions;
        updated = true;
      }
    } else {
      // Handle other field changes
      const newGrossPayments = updateDataArray(grossPayments);
      if (newGrossPayments !== grossPayments) {
        setGrossPayments(newGrossPayments);
        updatedGrossPayments = newGrossPayments;
        updated = true;
      }

      const newSuperAccruals = updateDataArray(superAccruals);
      if (newSuperAccruals !== superAccruals) {
        setSuperAccruals(newSuperAccruals);
        updatedSuperAccruals = newSuperAccruals;
        updated = true;
      }

      const newTaxesDeductions = updateDataArray(taxesDeductions);
      if (newTaxesDeductions !== taxesDeductions) {
        setTaxesDeductions(newTaxesDeductions);
        updatedTaxesDeductions = newTaxesDeductions;
        updated = true;
      }

      const newOtherItems = updateDataArray(otherItems);
      if (newOtherItems !== otherItems) {
        setOtherItems(newOtherItems);
        updatedOtherItems = newOtherItems;
        updated = true;
      }
    }

    if (updated && !changedFields.hasOwnProperty("unlock")) {
      // Store the final updated values in a closure to ensure we send the latest data
      const finalData = {
        grossPayments: updatedGrossPayments,
        superAccruals: updatedSuperAccruals,
        taxesDeductions: updatedTaxesDeductions,
        otherItems: updatedOtherItems,
      };

      setTimeout(() => {
        recalculateData(
          null,
          null,
          finalData.grossPayments,
          finalData.superAccruals,
          finalData.taxesDeductions,
          finalData.otherItems,
        );
      }, 250);
    }
  };

  const handleEditorPreparing = (e) => {
    if (e.parentType === "dataRow") {
      if (["PedUnits", "PedRate", "PedSite_dpsID"].includes(e.dataField)) {
        if (e.row.data.unlock === false) {
          e.editorOptions.readOnly = true;
          e.editorOptions.disabled = true;
        }
      }
    }
  };

  const recalculateData = useCallback(
    async (
      newRow,
      tableNumber,
      grossTable,
      benefitsTable,
      chargesTable,
      othersTable,
    ) => {
      setProcessing(true);
      console.log("Table Number:", tableNumber);
      console.log("New Row:", newRow);

      console.log("grossTable: ", grossTable);
      // Prepare the payload based on the current state
      const payload = {
        Emp: { ...rawData.Emp, ...empData },
        PayDetails: {
          ...rawData.Data,
          GrossPay: mapUnlockToPedManualCalcYN(grossTable || grossPayments),
          Benefits: mapUnlockToPedManualCalcYN(benefitsTable || superAccruals),
          Charges: mapUnlockToPedManualCalcYN(chargesTable || taxesDeductions),
          Others: mapUnlockToPedManualCalcYN(othersTable || otherItems),
        },
        PevID: PevID,
        EmpID: EmpID,
      };

      // If a new row is being added, handle it based on tableNumber
      if (newRow && tableNumber) {
        switch (tableNumber) {
          case 1:
            payload.PayDetails.GrossPay = [
              ...payload.PayDetails.GrossPay,
              newRow,
            ];
            break;
          case 2:
            payload.PayDetails.Benefits = [
              ...payload.PayDetails.Benefits,
              newRow,
            ];
            break;
          case 3:
            payload.PayDetails.Charges = [
              ...payload.PayDetails.Charges,
              newRow,
            ];
            break;
          case 4:
            payload.PayDetails.Others = [...payload.PayDetails.Others, newRow];
            break;
          default:
            break;
        }
      }

      try {
        const data = await apiCall({
          url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/recalculate-pay-event-employee-data`,
          method: "POST",
          body: payload,
        });

        // Update state with recalculated data
        setGrossPayments(data.Data.GrossPay);
        setSuperAccruals(
          data.Data.Benefits.map((row) => ({
            ...row,
            unlock: row.PedManualCalcYN === "Y",
          })),
        );
        setTaxesDeductions(
          data.Data.Charges.map((row) => ({
            ...row,
            unlock: row.PedManualCalcYN === "Y",
          })),
        );
        setOtherItems(data.Data.Others);
        setDialogOpen(0);
        setProcessing(false);
        enqueueSnackbar("Data recalculated successfully", {
          variant: "success",
        });
      } catch (errorMessage) {
        enqueueSnackbar(errorMessage, { variant: "error" });
        setProcessing(false);
      }
    },
    [
      EmpID,
      PevID,
      grossPayments,
      superAccruals,
      taxesDeductions,
      otherItems,
      apiCall,
      rawData,
      empData,
    ],
  );

  // Save API call
  const handleSave = () => {
    setProcessing(true);
    apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/save-pay-event-employee-data`,
      method: "POST",
      body: {
        EmpID,
        PevID,
        FinalYN: isFinalPayEvent ? "Y" : "N",
        ManagementNote: managementNote,
        PayslipNote: payslipNote,
        TaxScale: empData?.TaxScale || 0,
        ETPCode: "",
        GrossPay: mapUnlockToPedManualCalcYN(grossPayments),
        Benefits: mapUnlockToPedManualCalcYN(superAccruals),
        Charges: mapUnlockToPedManualCalcYN(taxesDeductions),
        Others: mapUnlockToPedManualCalcYN(otherItems),
      },
      onSuccess: () => {
        enqueueSnackbar("Data saved successfully", { variant: "success" });
        setIsDataChanged(false);
        setProcessing(false);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
        setProcessing(false);
      },
    });
  };

  const handleDelete = () => {
    setDeleteConfirmationDialogOpen(true);
  };

  // Add this new function to handle the actual deletion
  const confirmDelete = () => {
    apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/payroll-au/delete-employee-from-pay-event`,
      method: "POST",
      body: { PevID, EmpID },
      onSuccess: () => {
        enqueueSnackbar("Employee deleted from pay event", {
          variant: "success",
        });
        setDeleteConfirmationDialogOpen(false);
        navigate(`/${dbId}/${lang}/payroll/pay-event-open/${PevID}`);
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
        setDeleteConfirmationDialogOpen(false);
      },
    });
  };

  // Payslip API call
  const handlePayslip = () => {
    apiCall({
      url: `https://${envConfig.apiDev2}/api/en-au/reports/get-pdf-report-pack-rs1`,
      method: "POST",
      body: {
        ID: 8,
        Pars: [PevID, EmpID],
      },
      onSuccess: (data) => {
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${data.ReportBase64}`;
        link.download = data.FileName;
        link.click();
      },
      onError: (errorMessage) => {
        enqueueSnackbar(errorMessage, { variant: "error" });
      },
    });
  };

  // Handle Back button click
  const handleBack = () => {
    if (isDataChanged) {
      setUnsavedChangesDialogOpen(true);
    } else {
      navigate(`/${dbId}/${lang}/payroll/pay-event-open/${PevID}`);
    }
  };

  const confirmDiscardChanges = () => {
    setUnsavedChangesDialogOpen(false);
    navigate(-1);
  };

  const cancelDiscardChanges = () => {
    setUnsavedChangesDialogOpen(false);
  };

  // Context menu handling
  const handleActionClick = (event, rowData) => {
    event.preventDefault();
    setAnchorPosition({ top: event.clientY, left: event.clientX });
    setSelectedRow(rowData);
  };

  const handleMenuClose = () => {
    setAnchorPosition(null);
    setSelectedRow(null);
  };

  const handleRowDelete = () => {
    // Define data sources with associated table numbers
    const dataSources = [
      { data: grossPayments, setData: setGrossPayments },
      { data: superAccruals, setData: setSuperAccruals },
      { data: taxesDeductions, setData: setTaxesDeductions },
      { data: otherItems, setData: setOtherItems },
    ];

    let found = false;
    let tableNumber = null;
    let updatedGrossPayments = [...grossPayments];
    let updatedSuperAccruals = [...superAccruals];
    let updatedTaxesDeductions = [...taxesDeductions];
    let updatedOtherItems = [...otherItems];

    // Iterate through data sources to find and mark the row for deletion
    dataSources.forEach(({ data, setData }, index) => {
      if (!found) {
        const indexToDelete = data.findIndex(
          (row) => row.PedID === selectedRow.PedID,
        );
        if (indexToDelete !== -1) {
          const updatedData = [...data];
          updatedData[indexToDelete] = {
            ...updatedData[indexToDelete],
            PedRecordStatus: "D", // Mark as deleted
          };
          setData(updatedData);
          found = true;
          tableNumber = 3 + index; // Assign tableNumber as 3,4,5,6 respectively

          // Update local variables to reflect the deletion
          switch (index) {
            case 0:
              updatedGrossPayments = updatedData;
              break;
            case 1:
              updatedSuperAccruals = updatedData;
              break;
            case 2:
              updatedTaxesDeductions = updatedData;
              break;
            case 3:
              updatedOtherItems = updatedData;
              break;
            default:
              break;
          }
        }
      }
    });

    if (found) {
      setIsDataChanged(true);
      // Call recalculateData with all four tables
      recalculateData(
        null,
        tableNumber,
        updatedGrossPayments,
        updatedSuperAccruals,
        updatedTaxesDeductions,
        updatedOtherItems,
      );
    }

    handleMenuClose(); // Close the context menu
  };

  if (initialLoading || !empData || !listOfSites || !listOfPayItems) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box padding={2}>
      <Dialog
        open={deleteConfirmationDialogOpen}
        onClose={() => setDeleteConfirmationDialogOpen(false)}
      >
        <DialogTitle>Delete Pay Event</DialogTitle>
        <DialogContent>
          <Typography>
            Do you want to delete this entire employee record?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDelete} variant="contained" color="primary">
            Yes
          </Button>
          <Button
            onClick={() => setDeleteConfirmationDialogOpen(false)}
            color="primary"
            variant="outlined"
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <AddPayItemDialog
        dialogOpen={dialogOpen}
        onClose={() => setDialogOpen(0)}
        onSelect={async (newRow, tableNumber) => {
          setProcessing(true);
          setTimeout(async () => {
            await recalculateData(newRow, tableNumber);
          }, 250);
        }}
        listOfPayItems={{
          ...listOfPayItems,
          DataDT: listOfPayItems?.DataDT?.filter(
            (item) => item.pttGroup === dialogOpen,
          ),
        }}
        payRate={rawData?.Data?.NetPayable || 0}
      />

      {/* Information Section */}
      <BackdropLoading open={processing} />
      <Box display="flex" justifyContent="space-between" marginBottom={2}>
        <Box>
          <Typography variant="subtitle1">
            Id: <b>{empData.EmpID}</b> | Name: <b>{empData.EmpName}</b> |
            Status: <b>{empData.EmpStatus}</b>
          </Typography>
          <Typography variant="subtitle1">
            Net Payable: $<b>{empData.NetPayable.toFixed(2)}</b>
          </Typography>
        </Box>
        <Box textAlign="right">
          <Typography variant="subtitle1">
            Pay Event Id: <b>{empData.PevID}</b> | Status:{" "}
            <b>{empData.PayEventStatus}</b>
          </Typography>
          <Typography variant="subtitle1">
            Period: <b>{empData.PayEventDates}</b>
          </Typography>
        </Box>
      </Box>

      {/* Buttons Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            startIcon={<Save />}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={handleBack}
            style={{ marginLeft: 8 }}
            startIcon={<ArrowBack />}
          >
            Back
          </Button>
          {/* Timesheet Button (Does nothing) */}
          <Button
            variant="outlined"
            disabled
            style={{ marginLeft: 8 }}
            startIcon={<AccessTime />}
          >
            Timesheet
          </Button>
          <Button
            variant="outlined"
            onClick={handlePayslip}
            style={{ marginLeft: 8 }}
            startIcon={<Description />}
          >
            Payslip
          </Button>
          <Button
            variant="outlined"
            onClick={recalculateData}
            style={{ marginLeft: 8 }}
            startIcon={<AttachMoney />}
          >
            Recalculate
          </Button>
          <Button
            variant="outlined"
            onClick={handleDelete}
            style={{ marginLeft: 8, color: "red", borderColor: "red" }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={isFinalPayEvent}
              onChange={(e) => setIsFinalPayEvent(e.target.checked)}
              color="primary"
            />
          }
          label="Final Pay Event"
        />
      </Box>

      {/* Grids */}
      <Box marginBottom={2}>
        {/* Gross Payments Grid */}
        <Typography variant="h6">Gross Payments</Typography>
        <DataGrid
          dataSource={grossPayments
            .filter((item) => item.PedRecordStatus !== "D")
            .map((row) => {
              return {
                ...row,
                PedSite_dpsID:
                  row?.PedSite_dpsID === 0 ? null : row?.PedSite_dpsID,
              };
            })}
          keyExpr="PedID"
          showBorders
          onRowUpdating={handleCellChange}
          onEditorPreparing={handleEditorPreparing}
          onRowContextMenu={(e) => {
            e.event.preventDefault();
            handleActionClick(e.event, e.data);
          }}
        >
          <Editing
            mode="cell"
            allowUpdating={true}
            allowDeleting={false}
            allowAdding={false}
            confirmDelete={false}
            selectTextOnEditStart={true}
            startEditAction="click"
          />
          <Column
            dataField="PtiName"
            caption="Pay Item"
            allowEditing={false}
            width={300}
          />
          <Column
            width={130}
            dataField="PedUnits"
            caption="Units"
            allowEditing={true}
            dataType="number"
            format={{ type: "fixedPoint", precision: 4 }}
            editorOptions={{
              format: "#,##0.####",
              step: 0.0001,
            }}
          />
          <Column
            dataField="PedRate"
            caption="Rate"
            dataType="number"
            format={{ type: "fixedPoint", precision: 4 }}
            editorOptions={{
              format: "#,##0.####",
              step: 0.0001,
            }}
            allowEditing={true}
          />
          <Column
            dataField="PedValue"
            caption="Amount"
            format={{ type: "fixedPoint", precision: 2 }}
            editorOptions={{
              format: "#,##0.##",
              step: 0.01,
            }}
            allowEditing={false}
          />
          <Column
            dataField="PedSite_dpsID"
            caption="Site"
            allowEditing={true}
            lookup={{
              dataSource: listOfSites || [],
              valueExpr: "dpsID",
              displayExpr: "dpsCode",
              calculateCellValue: (rowData) => {
                if (!rowData?.PedSite_dpsID) return null;
                const site = listOfSites?.find(
                  (s) => s.dpsID === rowData.PedSite_dpsID,
                );
                return site?.dpsCode || "";
              },
            }}
            editorOptions={{
              dropDownOptions: {
                width: 300,
              },
              searchTimeout: 0,
              itemTemplate: (data) =>
                `<div style="white-space: normal; display: flex; flex-direction: column;">
                  <div>${data.dpsCode}</div>
                  <div>${data.dpsName}</div>
                </div>`,
            }}
          />
          <Column
            width={60}
            cellRender={(cellInfo) => (
              <MoreVert
                onClick={(e) => handleActionClick(e, cellInfo.data)}
                style={{ cursor: "pointer" }}
              />
            )}
          />
        </DataGrid>
        <Button
          onClick={() => setDialogOpen(1)}
          color="primary"
          variant="outlined"
          sx={{ mt: 1 }}
        >
          + Add
        </Button>
      </Box>

      {/* Super and Accruals Grid */}
      <Box marginBottom={2}>
        <Typography variant="h6">Super and Accruals</Typography>
        <DataGrid
          dataSource={superAccruals
            .filter((item) => item.PedRecordStatus !== "D")
            .map((row) => {
              return {
                ...row,
                PedSite_dpsID:
                  row?.PedSite_dpsID === 0 ? null : row?.PedSite_dpsID,
              };
            })}
          keyExpr="PedID"
          showBorders
          onRowUpdating={handleCellChange}
          onEditorPreparing={handleEditorPreparing}
          onRowContextMenu={(e) => {
            e.event.preventDefault();
            handleActionClick(e.event, e.data);
          }}
        >
          <Editing
            mode="cell"
            allowUpdating={true}
            allowDeleting={false}
            allowAdding={false}
            confirmDelete={false}
            selectTextOnEditStart={true}
            startEditAction="click"
          />
          <Column
            dataField="PtiName"
            caption="Name"
            allowEditing={false}
            width={220}
          />
          <Column
            dataField="unlock"
            caption="Unlock"
            dataType="boolean"
            width={80}
            editorOptions={{
              displayExpr: "unlock",
            }}
          />

          <Column
            width={130}
            dataField="PedUnits"
            caption="Units"
            allowEditing={({ row }) => row.data.unlock}
            dataType="number"
            format={{ type: "fixedPoint", precision: 4 }}
            editorOptions={{
              format: "#,##0.####",
              step: 0.0001,
            }}
          />
          <Column
            dataField="PedRate"
            dataType="number"
            format={{ type: "fixedPoint", precision: 4 }}
            editorOptions={{
              format: "#,##0.####",
              step: 0.0001,
            }}
            caption="Rate"
            allowEditing={({ row }) => row.data.unlock}
          />
          <Column
            dataField="PedValue"
            caption="Amount"
            format={{ type: "fixedPoint", precision: 2 }}
            editorOptions={{
              format: "#,##0.##",
              step: 0.01,
            }}
            allowEditing={false}
          />
          <Column
            dataField="PedSite_dpsID"
            caption="Site"
            allowEditing={({ row }) => row.data.unlock}
            lookup={{
              dataSource: listOfSites,
              valueExpr: "dpsID",
              displayExpr: "dpsCode",
            }}
            editorOptions={{
              dropDownOptions: {
                width: 300,
              },
              searchTimeout: 0,
              itemTemplate: (data) =>
                `<div style="white-space: normal; display: flex; flex-direction: column;">
                  <div>${data.dpsCode}</div>
                  <div>${data.dpsName}</div>
                </div>`,
            }}
          />
          <Column
            width={60}
            cellRender={(cellInfo) => (
              <MoreVert
                onClick={(e) => handleActionClick(e, cellInfo.data)}
                style={{ cursor: "pointer" }}
              />
            )}
          />
        </DataGrid>
        <Button
          onClick={() => setDialogOpen(2)}
          color="primary"
          variant="outlined"
          sx={{ mt: 1 }}
        >
          + Add
        </Button>
      </Box>

      {/* Taxes and Deductions Grid */}
      <Box marginBottom={2}>
        <Typography variant="h6">Taxes and Deductions</Typography>
        <DataGrid
          dataSource={taxesDeductions
            .filter((item) => item.PedRecordStatus !== "D")
            .map((row) => {
              return {
                ...row,
                PedSite_dpsID:
                  row?.PedSite_dpsID === 0 ? null : row?.PedSite_dpsID,
              };
            })}
          keyExpr="PedID"
          showBorders
          onRowUpdating={handleCellChange}
          onEditorPreparing={handleEditorPreparing}
          onRowContextMenu={(e) => {
            e.event.preventDefault();
            handleActionClick(e.event, e.data);
          }}
        >
          <Editing
            mode="cell"
            allowUpdating={true}
            allowDeleting={false}
            allowAdding={false}
            confirmDelete={false}
            selectTextOnEditStart={true}
            startEditAction="click"
          />
          <Column
            dataField="PtiName"
            caption="Name"
            allowEditing={false}
            width={220}
          />
          <Column
            dataField="unlock"
            caption="Unlock"
            dataType="boolean"
            width={80}
            editorOptions={{
              displayExpr: "unlock",
            }}
          />

          <Column
            width={130}
            dataField="PedUnits"
            caption="Units"
            allowEditing={({ row }) => row.data.unlock}
            dataType="number"
            format={{ type: "fixedPoint", precision: 4 }}
            editorOptions={{
              format: "#,##0.####",
              step: 0.0001,
            }}
          />
          <Column
            dataField="PedRate"
            caption="Rate"
            dataType="number"
            format={{ type: "fixedPoint", precision: 4 }}
            editorOptions={{
              format: "#,##0.####",
              step: 0.0001,
            }}
            allowEditing={({ row }) => row.data.unlock}
          />
          <Column
            dataField="PedValue"
            caption="Amount"
            format={{ type: "fixedPoint", precision: 2 }}
            editorOptions={{
              format: "#,##0.##",
              step: 0.01,
            }}
            allowEditing={false}
          />
          <Column
            dataField="PedSite_dpsID"
            caption="Site"
            allowEditing={({ row }) => row.data.unlock}
            lookup={{
              dataSource: listOfSites,
              valueExpr: "dpsID",
              displayExpr: "dpsCode",
            }}
            editorOptions={{
              dropDownOptions: {
                width: 300,
              },
              searchTimeout: 0,
              itemTemplate: (data) =>
                `<div style="white-space: normal; display: flex; flex-direction: column;">
                  <div>${data.dpsCode}</div>
                  <div>${data.dpsName}</div>
                </div>`,
            }}
          />
          <Column
            width={60}
            cellRender={(cellInfo) => (
              <MoreVert
                onClick={(e) => handleActionClick(e, cellInfo.data)}
                style={{ cursor: "pointer" }}
              />
            )}
          />
        </DataGrid>
        <Button
          onClick={() => setDialogOpen(3)}
          color="primary"
          variant="outlined"
          sx={{ mt: 1 }}
        >
          + Add
        </Button>
      </Box>

      {/* Other Items Grid */}
      <Box marginBottom={2}>
        <Typography variant="h6">Other Items</Typography>
        <DataGrid
          dataSource={otherItems
            .filter((item) => item.PedRecordStatus !== "D")
            .map((row) => {
              return {
                ...row,
                PedSite_dpsID:
                  row?.PedSite_dpsID === 0 ? null : row?.PedSite_dpsID,
              };
            })}
          keyExpr="PedID"
          showBorders
          onRowUpdating={handleCellChange}
          onEditorPreparing={handleEditorPreparing}
          onRowContextMenu={(e) => {
            e.event.preventDefault();
            handleActionClick(e.event, e.data);
          }}
        >
          <Editing
            mode="cell"
            allowUpdating={true}
            allowDeleting={false}
            allowAdding={false}
            confirmDelete={false}
            selectTextOnEditStart={true}
            startEditAction="click"
          />
          <Column
            dataField="PtiName"
            caption="Pay Item"
            allowEditing={false}
            width={300}
          />
          <Column
            width={130}
            dataField="PedUnits"
            caption="Units"
            allowEditing={true}
            dataType="number"
            format={{ type: "fixedPoint", precision: 4 }}
            editorOptions={{
              format: "#,##0.####",
              step: 0.0001,
            }}
          />
          <Column
            dataField="PedRate"
            dataType="number"
            format={{ type: "fixedPoint", precision: 4 }}
            editorOptions={{
              format: "#,##0.####",
              step: 0.0001,
            }}
            caption="Rate"
            allowEditing={true}
          />
          <Column
            dataField="PedValue"
            caption="Amount"
            format={{ type: "fixedPoint", precision: 2 }}
            editorOptions={{
              format: "#,##0.##",
              step: 0.01,
            }}
            allowEditing={false}
          />
          <Column
            dataField="PedSite_dpsID"
            caption="Site"
            allowEditing={true}
            lookup={{
              dataSource: listOfSites,
              valueExpr: "dpsID",
              displayExpr: "dpsCode",
            }}
            editorOptions={{
              dropDownOptions: {
                width: 300,
              },
              searchTimeout: 0,
              itemTemplate: (data) =>
                `<div style="white-space: normal; display: flex; flex-direction: column;">
                  <div>${data.dpsCode}</div>
                  <div>${data.dpsName}</div>
                </div>`,
            }}
          />
          <Column
            width={60}
            cellRender={(cellInfo) => (
              <MoreVert
                onClick={(e) => handleActionClick(e, cellInfo.data)}
                style={{ cursor: "pointer" }}
              />
            )}
          />
        </DataGrid>
        <Button
          onClick={() => setDialogOpen(4)}
          color="primary"
          variant="outlined"
          sx={{ mt: 1 }}
        >
          + Add
        </Button>
      </Box>

      {/* Notes Section */}
      <MuiGrid container spacing={2}>
        <MuiGrid item xs={6}>
          <TextField
            label="Management Notes"
            fullWidth
            multiline
            rows={4}
            value={managementNote}
            onChange={(e) => {
              setManagementNote(e.target.value);
              setIsDataChanged(true);
            }}
          />
        </MuiGrid>
        <MuiGrid item xs={6}>
          <TextField
            label="Payslip Note"
            multiline
            rows={4}
            fullWidth
            value={payslipNote}
            onChange={(e) => {
              setPayslipNote(e.target.value);
              setIsDataChanged(true);
            }}
          />
        </MuiGrid>
      </MuiGrid>

      {/* Unsaved Changes Dialog */}
      <Dialog open={unsavedChangesDialogOpen} onClose={cancelDiscardChanges}>
        <DialogTitle>You have unsaved changes</DialogTitle>
        <DialogContent>
          Click Yes to discard changes or No to stay on the page.
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmDiscardChanges} color="primary">
            Yes
          </Button>
          <Button onClick={cancelDiscardChanges} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Actions Context Menu */}
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={
          anchorPosition !== null
            ? { top: anchorPosition.top, left: anchorPosition.left }
            : undefined
        }
        open={Boolean(anchorPosition)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleRowDelete}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

export default PayEventEmployeePage;
